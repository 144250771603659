import { useMemo } from "react";
import * as ReactTable from "react-table";
import { Cell } from "react-table";
import { IExtendedRow, TYPE_OF_APPROVAL_DELEGATE } from "../constant";
import { COLUMN_FOR_VALUE } from "../constant";
import { IApprovalDelegateInfoWithCheck } from "../constant";
import StyledInput from "../../../../../../components/shared/styled_input";
import { CheckBoxContainer } from "../../../../../../components/main-view/settings/MenuCheckBoxList/styled";
import { StyledTooltip } from "../../styles";
import React from "react";

interface ApprovalDelegateColumnsProps {
  sFixedColumnNumber: number;
}

export const useApprovalDelegateColumns = ({
  sFixedColumnNumber
}: ApprovalDelegateColumnsProps) => {
  const columns: ReactTable.Column<IApprovalDelegateInfoWithCheck>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(
        COLUMN_FOR_VALUE
      ) as TYPE_OF_APPROVAL_DELEGATE[];

      return listOfColumn.map((item, index) => {
        const isCheckColumn =
          COLUMN_FOR_VALUE[item as TYPE_OF_APPROVAL_DELEGATE] ===
          COLUMN_FOR_VALUE.isCheck;
        const width = isCheckColumn ? 60 : 160;

        let sticky = "";
        if (sFixedColumnNumber) {
          if (index + 1 <= sFixedColumnNumber) {
            sticky = "left";
          }
        }

        return {
          Header(header) {
            if (isCheckColumn) {
              return (
                <MemoizedCheckboxHeader
                  header={header}
                  columnName={
                    COLUMN_FOR_VALUE[item as TYPE_OF_APPROVAL_DELEGATE]
                  }
                />
              );
            }
            return COLUMN_FOR_VALUE[item as TYPE_OF_APPROVAL_DELEGATE];
          },
          Cell(cell: Cell<IApprovalDelegateInfoWithCheck>) {
            if (isCheckColumn) {
              return <MemoizedCheckboxCell cell={cell} />;
            }

            return <MemoizedTextCell cell={cell} />;
          },
          accessor: item,
          width,
          sticky
        };
      });
    }, [sFixedColumnNumber]);

  return columns;
};

// 체크박스 헤더 컴포넌트
const CheckboxHeader = ({
  header,
  columnName
}: {
  header: any;
  columnName: string;
}) => (
  <CheckBoxContainer>
    <label
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      htmlFor="checkbox-header"
    >
      <StyledInput
        id="checkbox-header"
        name="checkbox-header"
        type="checkbox"
        style={{ marginTop: "2px" }}
        checked={header.isAllRowsSelected}
        onChange={() => {
          header.toggleAllRowsSelected(!header.isAllRowsSelected);
        }}
      />
      <span style={{ marginTop: "2px", marginLeft: "4px" }}>{columnName}</span>
    </label>
  </CheckBoxContainer>
);

// 체크박스 셀 컴포넌트
const CheckboxCell = ({
  cell
}: {
  cell: Cell<IApprovalDelegateInfoWithCheck>;
}) => {
  const row = cell.row as IExtendedRow<IApprovalDelegateInfoWithCheck>;
  const id = `checkbox-cell-${row.original.approvalAuthorityIdx}`;

  return (
    <label htmlFor={id} style={{ display: "flex" }}>
      <StyledInput
        id={id}
        name={id}
        type="checkbox"
        checked={row.isSelected}
        onChange={event => {
          event.stopPropagation();
          row.toggleRowSelected?.(!row.isSelected);
        }}
      />
      <span className="sr-only">Select row</span>
    </label>
  );
};

// 텍스트 셀 컴포넌트
const TextCell = ({ cell }: { cell: Cell<IApprovalDelegateInfoWithCheck> }) => {
  const textRef = React.useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (textRef.current && cell.value && typeof cell.value === "string") {
      // 요소의 스크롤 너비가 실제 너비보다 크면 텍스트가 잘린 것
      const isTextTruncated =
        textRef.current.scrollWidth > textRef.current.clientWidth;
      setShowTooltip(isTextTruncated);
    }
  }, [cell.value]);

  if (cell.value && typeof cell.value === "string") {
    const cellId = `cell-${cell.row.id}-${cell.column.id}`;

    return (
      <>
        {showTooltip && (
          <StyledTooltip
            id={cellId}
            type="light"
            effect="solid"
            className="custom-tooltip-class"
            delayShow={300}
          />
        )}
        <div
          ref={textRef}
          data-tip={showTooltip ? cell.value : ""}
          data-for={cellId}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%"
          }}
        >
          {cell.value}
        </div>
      </>
    );
  }

  if (cell.column.id === "useFlag") {
    const USE = 1;
    const UNUSE = 2;

    if (cell.value === USE) return <span style={{ color: "blue" }}>사용</span>;
    if (cell.value === UNUSE)
      return <span style={{ color: "red", fontWeight: "bold" }}>미사용</span>;
  }

  return cell.value ?? "";
};

// 메모이제이션된 텍스트 셀 컴포넌트로 변경
const MemoizedTextCell = React.memo(TextCell);

const MemoizedCheckboxCell = React.memo(CheckboxCell);
const MemoizedCheckboxHeader = React.memo(CheckboxHeader);
