import styled from "styled-components";
import { colors } from "../../../../../components/GlobalStyle/GlobalStyle";

export const ApprovalInfoRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApprovalSelect = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  background-color: white;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: ${colors.skyBlue};
  }
`;

export const ApprovalOption = styled.option`
  padding: 8px;
`;

export const ApprovalTextArea = styled.textarea`
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  min-height: 80px;
  height: 100px;
  background-color: white;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: ${colors.skyBlue};
  }
`;
