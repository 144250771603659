import { createContext } from "react";
import {
  IReceiveAndReferenceForDepartment,
  IReceiveAndReferenceForUser
} from "../../generated/graphql";
import { IEmployeeInformation } from "./approval-process/list-of-selected-approval-user";

interface IApprovalDetailPopupStore {
  listOfUser: IEmployeeInformation[];
  listOfReceiveUser: IReceiveAndReferenceForUser[];
  listOfReceiveDepartment: IReceiveAndReferenceForDepartment[];
  listOfReferenceUser: IReceiveAndReferenceForUser[];
  listOfReferenceDepartment: IReceiveAndReferenceForDepartment[];
  startDate?: string;
  endDate?: string;
  formTemplateIdx?: number;
  startTime?: string;
  endTime?: string;
  description?: string;
  replacementRequestStartDate?: string;
  replacementRequestEndDate?: string;
  replacementRequestStartTime?: string;
  replacementRequestEndTime?: string;
  approvalRequestIdx?: number;
  workingTemplateIdx: number;
  setWorkingTemplateIdx: (workingTemplateIdx: number) => void;
  isTemporaryStorage?: boolean;
}

const ApprovalDetailPopupStore = createContext<
  IApprovalDetailPopupStore | undefined
>(undefined);

export default ApprovalDetailPopupStore;
