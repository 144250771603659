import React from "react";
import styled from "styled-components";
import { colors } from "../../../components/GlobalStyle/GlobalStyle";
import Button from "../../../components/globalComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExclamationTriangle,
  faTimesCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

// 다이얼로그 타입 정의
export type DialogType = "info" | "warning" | "error" | "success";

interface ConfirmDialogProps {
  isOpen: boolean;
  title: string;
  message: string;
  type?: DialogType;
  confirmText?: string;
  cancelText?: string;
  showCancelButton?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

// 타입별 색상 정의
const typeColors = {
  info: colors.darkBlue,
  warning: colors.darkYellow, // 경고 색상 (주황색)
  error: colors.darkRed,
  success: colors.darkGreen // 성공 색상 (녹색)
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const DialogContainer = styled.div<{ type: DialogType }>`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 400px;
  max-width: 90%;
  padding: 24px;
  border-top: 4px solid ${props => typeColors[props.type]};
`;

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const IconContainer = styled.div<{ type: DialogType }>`
  color: ${props => typeColors[props.type]};
  font-size: 24px;
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

const DialogTitle = styled.h3<{ type: DialogType }>`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${props => typeColors[props.type]};
  flex: 1;
`;

const DialogMessage = styled.p`
  margin: 16px 0;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
`;

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  title,
  message,
  type = "info",
  confirmText = "확인",
  cancelText = "취소",
  showCancelButton = true,
  onConfirm,
  onCancel
}) => {
  if (!isOpen) return null;

  // 타입에 따른 아이콘 선택
  const getIcon = () => {
    switch (type) {
      case "info":
        return faInfoCircle;
      case "warning":
        return faExclamationTriangle;
      case "error":
        return faTimesCircle;
      case "success":
        return faCheckCircle;
      default:
        return faInfoCircle;
    }
  };

  // 타입에 따른 확인 버튼 색상
  const getConfirmButtonColor = () => {
    switch (type) {
      case "info":
        return colors.darkBlue;
      case "warning":
        return colors.darkYellow;
      case "error":
        return colors.darkRed;
      case "success":
        return colors.darkGreen;
      default:
        return colors.darkBlue;
    }
  };

  return (
    <Overlay onClick={onCancel}>
      <DialogContainer type={type} onClick={e => e.stopPropagation()}>
        <DialogHeader>
          <IconContainer type={type}>
            <FontAwesomeIcon icon={getIcon()} size="lg" />
          </IconContainer>
          <DialogTitle type={type}>{title}</DialogTitle>
        </DialogHeader>
        <DialogMessage>{message}</DialogMessage>
        <ButtonGroup>
          {showCancelButton && (
            <Button
              backgroundColor={colors.darkGrey}
              hoverBackgroundColor={colors.grey}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          )}
          <Button
            backgroundColor={getConfirmButtonColor()}
            hoverBackgroundColor={type === "info" ? colors.skyBlue : undefined}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </ButtonGroup>
      </DialogContainer>
    </Overlay>
  );
};

export default ConfirmDialog;
