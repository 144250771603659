import { useCallback, useContext, useEffect, useMemo } from "react";
import {
  useCountApprovalDelegateInfoLazyQuery,
  useDelApprovalDelegateInfoMutation,
  useGetApprovalDelegateInfoLazyQuery
} from "../../../../../../generated/graphql";
import { IApprovalDelegateInfoSearchType } from "../../../../../../generated/graphql";
import { MessageTypes } from "../../../../../../components/toast-message/toast-message";
import MainViewContext from "../../../../../../components/main-view/store";

function useFetchApprovalDelegateInfo(
  startDate: string,
  endDate: string,
  searchType: IApprovalDelegateInfoSearchType,
  searchKeyword: string,
  currentPage: number,
  handleToast: (message: string, type: MessageTypes) => void,
  take: number
) {
  const rootStore = useContext(MainViewContext);
  const {
    signInReducer: { employee_id: callEmployeeId }
  } = rootStore;

  const [fetchApprovalDelegateInfo, { data, loading, error }] =
    useGetApprovalDelegateInfoLazyQuery({
      fetchPolicy: "network-only",
      onError(error) {
        console.error("Failed to fetch approval delegate info:", error);
        handleToast("대결자 정보를 조회하지 못했습니다.", MessageTypes.ERROR);
      },
      onCompleted(data) {
        if (data.getApprovalDelegateInfo.error) {
          console.error(
            "API returned error:",
            data.getApprovalDelegateInfo.error
          );
          handleToast("대결자 정보를 조회하지 못했습니다.", MessageTypes.ERROR);
        }
      }
    });

  const [
    countApprovalDelegateInfo,
    { data: countData, loading: countLoading, error: countError }
  ] = useCountApprovalDelegateInfoLazyQuery({
    fetchPolicy: "network-only",
    onError(error) {
      console.error("Failed to fetch approval delegate info:", error);
    },
    onCompleted(data) {
      if (data.countApprovalDelegateInfo.error) {
        console.error(
          "API returned error:",
          data.countApprovalDelegateInfo.error
        );
      }
    }
  });

  const [delApprovalDelegateInfo, { loading: delLoading, error: delError }] =
    useDelApprovalDelegateInfoMutation({
      fetchPolicy: "network-only",
      onError(error) {
        console.error("Failed to delete approval delegate info:", error);
        handleToast("대결자 정보를 삭제하지 못했습니다.", MessageTypes.ERROR);
      },
      onCompleted(data) {
        refetch(startDate, endDate, searchType, searchKeyword, 1, take);
        handleToast(
          "성공적으로 대결자 정보를 삭제하였습니다.",
          MessageTypes.SUCCESS
        );
      }
    });

  const approvalDelegateInfo = useMemo(() => {
    return data?.getApprovalDelegateInfo.list ?? [];
  }, [data]);

  const count: number = useMemo(() => {
    return countData?.countApprovalDelegateInfo.count ?? 0;
  }, [countData]);

  useEffect(() => {
    fetchApprovalDelegateInfo({
      variables: {
        callEmployeeId,
        startDate,
        endDate,
        searchType,
        searchValue: searchKeyword,
        page: currentPage,
        take
      }
    });

    countApprovalDelegateInfo({
      variables: {
        callEmployeeId,
        startDate,
        endDate,
        searchType,
        searchValue: searchKeyword
      }
    });
  }, [
    fetchApprovalDelegateInfo,
    countApprovalDelegateInfo,
    callEmployeeId,
    currentPage,
    take
  ]);

  const refetch = useCallback(
    (
      startDate: string,
      endDate: string,
      searchType: IApprovalDelegateInfoSearchType,
      searchValue: string,
      currentPage: number,
      take: number
    ) => {
      fetchApprovalDelegateInfo({
        variables: {
          callEmployeeId,
          startDate,
          endDate,
          searchType,
          searchValue,
          page: currentPage,
          take
        }
      });

      countApprovalDelegateInfo({
        variables: {
          callEmployeeId,
          startDate,
          endDate,
          searchType,
          searchValue
        }
      });
    },
    [
      fetchApprovalDelegateInfo,
      countApprovalDelegateInfo,
      callEmployeeId,
      startDate,
      endDate
    ]
  );

  const deleteApprovalDelegateInfo = useCallback(
    (approvalAuthorityIdx: number[]) => {
      delApprovalDelegateInfo({
        variables: {
          approvalAuthorityIdx,
          callEmployeeId
        }
      });
    },
    [delApprovalDelegateInfo, callEmployeeId]
  );

  const handleSearch = useCallback(() => {
    refetch(startDate, endDate, searchType, searchKeyword, 1, take);
  }, [startDate, endDate, searchType, searchKeyword, take, refetch]);

  return {
    approvalDelegateInfo,
    isLoading: loading,
    error: error || data?.getApprovalDelegateInfo.error,
    count,
    refetch,
    deleteApprovalDelegateInfo,
    handleSearch,
    delError,
    delLoading
  };
}

export default useFetchApprovalDelegateInfo;
