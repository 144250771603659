import styled from "styled-components";

export const HorizontalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 10px;
`;

export const ApprovalDelegateLeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  min-width: 480px;
`;

export const DelegateContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  padding: 8px;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 16px;
`;

export const MoveButtonContainer = styled.div`
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-weight: bold;
  svg {
    font-size: 18px;
  }
  button {
    display: flex;
    gap: 10px;
    padding-left: 18px;
    align-items: center;
  }
`;

export const ApprovalDelegateRightContainer = styled.div`
  display: flex;
  padding: 8px;
  flex: 1;
  gap: 10px;
  flex-direction: column;
`;

export const ApprovalInfoContainer = styled.div`
  flex: 20;
  border: 1px solid lightgrey;
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;
