import ReactTooltip from "react-tooltip";
import styled from "styled-components";

// 컴포넌트 상단에 스타일 추가
export const StyledTooltip = styled(ReactTooltip)`
  &.custom-tooltip-class {
    opacity: 0.95 !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 10px 14px;
    font-size: 13px;
    max-width: 300px;
    word-break: break-word;
    line-height: 1.4;
    transition: opacity 0.2s;
  }
`;
