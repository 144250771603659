import {
  SignType,
  useGetApprovalPasswordAndSignSettingInfoLazyQuery
} from "../../../../generated/graphql";
import { useState, useEffect, useCallback } from "react";

export function useSignatureInfo(employeeId?: string) {
  const [signImage, setSignImage] = useState<string | null>();
  const [signText, setSignText] = useState<string | null>();
  const [signType, setSignType] = useState<SignType | null>();

  const [
    getApprovalPasswordAndSignSettingInfo,
    { data: approvalPasswordAndSignSettingInfo }
  ] = useGetApprovalPasswordAndSignSettingInfoLazyQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
    }
  });

  useEffect(() => {
    if (
      approvalPasswordAndSignSettingInfo?.getApprovalPasswordAndSignSettingInfo
        .info
    ) {
      const { signType, signImage, signText } =
        approvalPasswordAndSignSettingInfo
          ?.getApprovalPasswordAndSignSettingInfo.info;
      setSignType(signType);
      if (signType === SignType.Image) {
        setSignImage(signImage);
      } else {
        setSignText(signText);
      }
    }
  }, [approvalPasswordAndSignSettingInfo]);

  const fetchSignatureInfo = useCallback(
    (employeeId: string) => {
      getApprovalPasswordAndSignSettingInfo({
        variables: {
          employeeId
        }
      });
    },
    [getApprovalPasswordAndSignSettingInfo]
  );

  return {
    signImage,
    signText,
    signType,
    fetchSignatureInfo
  };
}
