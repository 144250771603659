import styled from "styled-components";
import StyledButton from "../../../shared/styled_button";
import { colors } from "../../../GlobalStyle/GlobalStyle";
import mColors from "../../../../style-sheet/m_colors";
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import mRAStore from "./m_r_a_store";
import {
  ApplicationApprovalMutationVariables,
  Approval_User_Type,
  IListOfApprovalLineUser,
  useApplicationApprovalMutation
} from "../../../../generated/graphql";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";

interface IProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setIsAdd: (value: React.SetStateAction<boolean>) => void;
  isAddApproval: boolean;
  setIsAddApproval: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 10%;
  align-items: center;
`;

const SBtn = styled(StyledButton)`
  border-radius: 20px;
  box-shadow: 1px 8px 4px -1px rgba(194, 194, 194, 0.79);
  -webkit-box-shadow: 1px 8px 4px -1px rgba(194, 194, 194, 0.79);
  -moz-box-shadow: 1px 8px 4px -1px rgba(194, 194, 194, 0.79);
`;

function MRequestApprovalFooter({
  page,
  setPage,
  setIsAdd,
  isAddApproval,
  setIsAddApproval
}: IProps) {
  const {
    user,
    workingTemplateIdx,
    template,
    startDate,
    endDate,
    startTime,
    endTime,
    title,
    description,
    approvalLineUser
  } = useRecoilValue(mRAStore);

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const isNext = useMemo(() => {
    if (template) {
      if (page === 2) {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${endDate}T${endTime}`);
        if (endDateTime < startDateTime) {
          return true;
        }

        if (
          !title ||
          !description ||
          title.length < 3 ||
          description.length < 3
        ) {
          return true;
        }
      }
      if (page === 3 && approvalLineUser.length < 1) {
        return true;
      }
      return false;
    }
    return true;
  }, [
    page,
    template,
    startDate,
    endDate,
    startTime,
    endTime,
    title,
    description,
    approvalLineUser
  ]);
  const [applicationApproval, { client }] = useApplicationApprovalMutation({
    onError(error) {
      handleToast(error.message, MessageTypes.ERROR);
      console.log(error);
    },
    update(_, { data }) {
      if (data?.applicationApproval.ok) {
        client.resetStore();
        setIsAddApproval(true);
      } else if (
        !data?.applicationApproval.ok &&
        data?.applicationApproval.error
      ) {
        handleToast(data?.applicationApproval.error, MessageTypes.ERROR);
      }
    }
  });

  const handleApplicationApproval = useCallback(() => {
    if (template && user?.employeeId) {
      const listOfApprovalLineUser: IListOfApprovalLineUser[] = [];
      // 기안자 추가
      listOfApprovalLineUser.push({
        employee_id: user.employeeId,
        order: 1,
        type: Approval_User_Type.Drafter
      });

      approvalLineUser.forEach((item, index) => {
        if (item.approvalType !== Approval_User_Type.Drafter) {
          if (item.employeeId && item.approvalType) {
            const user = {
              employee_id: item.employeeId,
              order: index + 2,
              type: item.approvalType
            };
            listOfApprovalLineUser.push(user);
          }
        }
      });

      const payload: ApplicationApprovalMutationVariables = {
        employeeId: user?.employeeId,
        workingTemplateIdx,
        startDate,
        endDate,
        title,
        formTemplateIdx: template.formTemplateIdx,
        startTime,
        endTime,
        description,
        listOfApprovalLineUser
      };
      applicationApproval({
        variables: payload
      });
    }
  }, [
    user?.employeeId,
    approvalLineUser,
    applicationApproval,
    template,
    startDate,
    endDate,
    startTime,
    endTime,
    description
  ]);

  return (
    <Container>
      {!isAddApproval && (
        <SBtn
          $backgroundColor={colors.darkRed}
          $hoverBackgroundColor={colors.lightRed}
          onClick={() => setIsAdd(false)}
          $maxHeight="36px"
        >{`닫기`}</SBtn>
      )}

      {!isAddApproval && (
        <SBtn
          $backgroundColor={mColors.darkYellow}
          $hoverBackgroundColor={mColors.yellow}
          disabled={page < 2}
          onClick={() => setPage(pre => pre - 1)}
          $maxHeight="36px"
        >{`뒤로가기`}</SBtn>
      )}
      {!isAddApproval && (
        <SBtn
          disabled={isNext}
          onClick={() => {
            if (page !== 3) {
              setPage(pre => pre + 1);
            } else {
              handleApplicationApproval();
            }
          }}
          $maxHeight="36px"
        >
          {page === 3 ? "상신" : `다음`}
        </SBtn>
      )}
      {isAddApproval && (
        <SBtn
          onClick={() => {
            setPage(1);
            setIsAddApproval(false);
            setIsAdd(false);
          }}
        >{`확인`}</SBtn>
      )}
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
        widget
      />
    </Container>
  );
}

export default MRequestApprovalFooter;
