import styled from "styled-components";
import Button from "../../globalComponents/Button";
import { Icon } from "@iconify/react";
import ArrowLeftIcon from "@iconify-icons/cil/arrow-circle-left";
import ArrowRightIcon from "@iconify-icons/cil/arrow-circle-right";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import {
  Approval_User_Type,
  EmployeeInformation
} from "../../../generated/graphql";
import { colors } from "../../GlobalStyle/GlobalStyle";

interface IProps {
  deleteControl: () => void;
  isSelectedUser: boolean;
  list: string[];
  firstControl: () => void;
  secondControl: () => void;
  firstButtonName: string;
  secondButtonName: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-weight: bold;
  svg {
    font-size: 18px;
  }
  button {
    display: flex;
    gap: 10px;
    padding-left: 18px;
    align-items: center;
    :last-child {
      gap: 15px;
    }
  }
`;

const Name = styled.span`
  display: flex;
  flex: 1;
  min-width: max-content;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

function ApprovalButtonController({
  deleteControl,
  isSelectedUser,
  list,
  firstControl,
  secondControl,
  firstButtonName,
  secondButtonName
}: IProps) {
  return (
    <Container>
      <Button
        backgroundColor={colors.darkBlue}
        hoverBackgroundColor={colors.skyBlue}
        disabled={!isSelectedUser}
        onClick={firstControl}
      >
        <Icon icon={ArrowRightIcon} />
        <Name>{firstButtonName}</Name>
      </Button>
      <Button disabled={!isSelectedUser} onClick={secondControl}>
        <Icon icon={ArrowRightIcon} />
        <Name>{secondButtonName}</Name>
      </Button>
      <Button
        backgroundColor={colors.darkRed}
        hoverBackgroundColor={colors.lightRed}
        disabled={list.length < 1}
        onClick={deleteControl}
      >
        <Icon icon={ArrowLeftIcon} />
        <Name>{`삭제`}</Name>
      </Button>
    </Container>
  );
}

export default ApprovalButtonController;
