import { useState } from "react";
import { useCallback } from "react";
import ListOfEmployeeInGroups from "../../../../../components/approval/approval-process/list-of-employee-in-groups";
import TreeOfApprovalProcess from "../../../../../components/approval/approval-process/tree-of-approval-process";
import { IApprovalLineManagementTabs } from "../../../../../components/approval/approval-setting/approval-process-management/approval-process-management";
import { DelegateContainer } from "../styles/container";
import { GroupEntity } from "../../../../../generated/graphql";
import { ApprovalDelegateLeftContainer } from "../styles/container";
import { EmployeeInformation } from "../../../../../generated/graphql";
import { ICompactRow } from "../../../../../components/compact-table/compact-table-render-row";
interface IProps {
  handleSelectedDepartment: (department: GroupEntity) => void;
  handleAddUser: (user?: ICompactRow<EmployeeInformation>) => void;
  selectedDepartment?: GroupEntity;
}

function ApprovalDelegateSelectionLeft({
  handleSelectedDepartment,
  handleAddUser,
  selectedDepartment
}: IProps) {
  const [_, setSelectedTab] = useState<IApprovalLineManagementTabs>(
    IApprovalLineManagementTabs.ORGANIZATION_CHART
  );

  const handleSelectTab = useCallback((tab: IApprovalLineManagementTabs) => {
    setSelectedTab(tab);
  }, []);

  return (
    <ApprovalDelegateLeftContainer>
      <DelegateContainer>
        <TreeOfApprovalProcess
          handleSelectedDepartment={handleSelectedDepartment}
          selectedTab={IApprovalLineManagementTabs.ORGANIZATION_CHART}
          handleSelectTab={handleSelectTab}
          hiddenGroupTab={false}
          hiddenApprovalTab={true}
        />
        <ListOfEmployeeInGroups
          selectedDepartment={selectedDepartment?.departmentId}
          handleAddUser={handleAddUser}
        />
      </DelegateContainer>
    </ApprovalDelegateLeftContainer>
  );
}

export default ApprovalDelegateSelectionLeft;
