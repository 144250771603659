import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import * as React from "react";
import { ApprovalFlag } from "../../../../../../../__generated__/globalTypes";
import {
  UpdateCommuteDateTime,
  UpdateCommuteDateTimeVariables
} from "../../../../../../../__generated__/UpdateCommuteDateTime";
import CommuteEditorDialog from "../CommuteEditorDialog";
import { ExtendedCommuteManagement } from "../../../../commute-view-section/commute-view-section";
type SelectOnchange = React.ChangeEvent<HTMLSelectElement>;
type HandleSubmitEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

type handleOnChange = (event: SelectOnchange) => void;

interface IProps {
  row?: ExtendedCommuteManagement;
  handleClose: (value: boolean) => void;
}

export interface IChildProps extends IProps {
  handleOption: handleOnChange;
  handleSubmit: (event: HandleSubmitEvent) => void;
  dateWithTime: string;
  handleDateWithTime: (value: string) => void;
}

const MUTATION_UPDATE_COMMUTE_DATE_TIME = gql`
  mutation UpdateCommuteDateTime(
    $selectedEmployeeId: String!
    $checkDateTime: String!
    $approvalFlag: ApprovalFlag!
    $checkDateTimeModify: String!
  ) {
    updateCommuteDateTime(
      selectedEmployeeId: $selectedEmployeeId
      checkDateTime: $checkDateTime
      approvalFlag: $approvalFlag
      checkDateTimeModify: $checkDateTimeModify
    ) {
      ok
      error
    }
  }
`;

const CommuteEditorContainer = (props: IProps) => {
  const { row, handleClose } = props;
  const [dateWithTime, setDateWithTime] = React.useState<string>(
    moment().format("YYYY-MM-DD[T]HH:mm")
  );
  const [option, setOption] = React.useState<ApprovalFlag>(
    ApprovalFlag.ORIGIN_RECOGNITION
  );
  const [updateCommuteDateTime, { client }] = useMutation<
    UpdateCommuteDateTime,
    UpdateCommuteDateTimeVariables
  >(MUTATION_UPDATE_COMMUTE_DATE_TIME, {
    update(cache, { data }) {
      if (data?.updateCommuteDateTime.ok && row) {
        client.resetStore();
      }
      handleClose(false);
    }
  });

  const handleDateWithTime = React.useCallback((value: string) => {
    setDateWithTime(value);
  }, []);

  const handleOption = React.useCallback((event: SelectOnchange) => {
    if (event.target.value === ApprovalFlag.ORIGIN_RECOGNITION) {
      setOption(event.target.value);
    }
    if (event.target.value === ApprovalFlag.CORRECTED_VERSION) {
      setOption(event.target.value);
    }
    if (event.target.value === ApprovalFlag.NO_RECOGNITION) {
      setOption(event.target.value);
    }
  }, []);

  const handleSubmit = React.useCallback(
    async (event: HandleSubmitEvent) => {
      if (row && row.employeeId) {
        const [date, time] = dateWithTime.split("T");
        const modifyDateWithTime = `${date} ${time}`;

        updateCommuteDateTime({
          variables: {
            selectedEmployeeId: row.employeeId,
            checkDateTime: row.originalCheckDateTime as string,
            approvalFlag: option,
            checkDateTimeModify: modifyDateWithTime
          }
        });
      }
    },
    [updateCommuteDateTime, option, row, dateWithTime]
  );

  React.useEffect(() => {
    if (row && row.checkDateTime) {
      const [date, time] = row.checkDateTime.split(" ");
      const result = `${date}T${time}`;
      setDateWithTime(result);
    }
  }, [row]);

  return (
    <CommuteEditorDialog
      {...props}
      handleOption={handleOption}
      handleSubmit={handleSubmit}
      dateWithTime={dateWithTime}
      handleDateWithTime={handleDateWithTime}
    />
  );
};

export default CommuteEditorContainer;
