import { useMemo } from "react";
import StyledButton from "../../../../../../components/shared/styled_button";
import { colors } from "../../../../../../components/GlobalStyle/GlobalStyle";
import routes from "../../../../../../routes";
import UrlParamsCrypto from "../../../../../../Utils/UrlParamsCrypto";
import { IApprovalDelegateInfoWithCheck } from "../constant";
import * as ReactTable from "react-table";

interface ActionButtonsProps {
  selectedRow?: ReactTable.Row<IApprovalDelegateInfoWithCheck>;
  selectedRowAuthorityIdx: number[];
  callEmployeeId: string;
  deleteApprovalDelegateInfo: (idxs: number[]) => void;
  selectedRowsCount: number;
}

function ActionButtons({
  selectedRow,
  selectedRowAuthorityIdx,
  callEmployeeId,
  deleteApprovalDelegateInfo,
  selectedRowsCount
}: ActionButtonsProps) {
  const urlCrypto = useMemo(() => new UrlParamsCrypto(), []);

  // selectedRow?.original 에서 name, departName, userPosition에 대응하는 필드를 만들어서 대결자 정보로 넘겨야한다
  const originDelegateInfo = useMemo(() => {
    return selectedRow?.original;
  }, [selectedRow]);

  const openPopupWindow = (
    mode: "add" | "edit",
    originDelegateInfo?: IApprovalDelegateInfoWithCheck,
    id?: number
  ) => {
    const width = 1080;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const data =
      mode === "add"
        ? { mode, callEmployeeId }
        : { mode, callEmployeeId, id, originDelegateInfo };

    const encryptedData = urlCrypto.encrypt(data);

    window.open(
      `${routes.pageRoutes.approvalDelegateSelection}?data=${encryptedData}`,
      "",
      `width=${width}, height=${height}, left=${left}, top=${top}, scrollbars=yes, resizable=yes`
    );
  };

  return (
    <>
      <StyledButton
        onClick={() => openPopupWindow("add")}
      >{`추가`}</StyledButton>

      <StyledButton
        $backgroundColor={colors.darkBlue}
        $hoverBackgroundColor={colors.skyBlue}
        onClick={() =>
          openPopupWindow(
            "edit",
            originDelegateInfo,
            selectedRow?.original.approvalAuthorityIdx
          )
        }
        disabled={selectedRowsCount !== 1}
      >{`수정`}</StyledButton>

      <StyledButton
        $backgroundColor={colors.darkRed}
        $hoverBackgroundColor={colors.lightRed}
        disabled={selectedRowsCount < 1}
        onClick={() => {
          deleteApprovalDelegateInfo(selectedRowAuthorityIdx);
        }}
      >{`삭제`}</StyledButton>
    </>
  );
}

export default ActionButtons;
