import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../../../apollo/apollo-var";
import { useEffect, useMemo } from "react";
import {
  ApprovalAdditionalFieldEntity,
  ApprovalRemainingTimeEntity,
  useGetAdditionalFieldInApprovalFormLazyQuery,
  useGetApprovalRemainingTimeInfoLazyQuery
} from "../../../../generated/graphql";
import { IApprovalDataParams } from "../types";
import moment from "moment";
import { useApproverInfo } from "./useApproverInfo";
import { useSignatureInfo } from "./useSignatureInfo";
import { useWorkScheduleInfo } from "./useWorkScheduleInfo";
import { useUserInfo } from "./useUserInfo";
import { TOKEN } from "../../../../apollo/apollo";
import { handleDecodedToken } from "../../../../Utils/tokenMaker";
import {
  parseAnnualInfo,
  parseApprovalRemainingTimeInfo
} from "../approval.utils";

export function useApprovalForm(params: IApprovalDataParams) {
  const {
    template,
    data,
    approvalTemporaryStorageData,
    listOfUser,
    isRequest,
    startDate,
    approvalDetailInformation
  } = params;

  const fName = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const formName = useMemo(() => {
    if (template?.formIdx) {
      return fName.get(template?.formIdx);
    }
    if (data?.formIdx) {
      return fName.get(data?.formIdx);
    }
    if (approvalTemporaryStorageData?.formIdx) {
      return fName.get(approvalTemporaryStorageData.formIdx);
    }
    return "";
  }, [template, data, approvalTemporaryStorageData]);

  const { isDrafterSelected, listOfApprover } = useApproverInfo(listOfUser);
  const { signImage, signText, signType, fetchSignatureInfo } =
    useSignatureInfo();
  const { isActiveTime, isActiveWorkType, workingTemplateOptions } =
    useWorkScheduleInfo(template, data, approvalTemporaryStorageData);
  const { user, departmentName, fetchUserInfo } = useUserInfo();

  const [getAdditionalFieldInApprovalForm, { data: additionalFieldData }] =
    useGetAdditionalFieldInApprovalFormLazyQuery({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
      }
    });

  const [
    getApprovalRemainingTimeInfo,
    { data: approvalRemainingTimeInfoData }
  ] = useGetApprovalRemainingTimeInfoLazyQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
    }
  });

  const createDate: string = useMemo(() => {
    let date = "";
    if (approvalDetailInformation?.signUpDateTimeTemp) {
      date = moment(approvalDetailInformation?.signUpDateTimeTemp).format(
        "(MM/DD)"
      );
    }
    return date;
  }, [approvalDetailInformation?.signUpDateTimeTemp]);

  const formTemplateIdx = useMemo(() => {
    const idx =
      data?.formTemplateIdx ??
      template?.formTemplateIdx ??
      approvalTemporaryStorageData?.formTemplateIdx;
    if (idx) {
      return `:${idx}`;
    }
    return "";
  }, [
    data?.formTemplateIdx,
    template?.formTemplateIdx,
    approvalTemporaryStorageData?.formTemplateIdx
  ]);

  const annualInfo = useMemo<ApprovalAdditionalFieldEntity | undefined>(() => {
    if (data?.annualInfo) {
      return parseAnnualInfo(data?.annualInfo);
    }
    if (
      additionalFieldData?.getAdditionalFieldInApprovalForm.ok &&
      additionalFieldData?.getAdditionalFieldInApprovalForm.info
    ) {
      return additionalFieldData?.getAdditionalFieldInApprovalForm.info;
    }
    return undefined;
  }, [data?.annualInfo, additionalFieldData]);

  const approvalRemainingTimeInfo = useMemo<
    ApprovalRemainingTimeEntity | undefined
  >(() => {
    if (data?.annualInfo) {
      return parseApprovalRemainingTimeInfo(data?.annualInfo);
    }
    if (
      approvalRemainingTimeInfoData?.getApprovalRemainingTimeInfo.ok &&
      approvalRemainingTimeInfoData?.getApprovalRemainingTimeInfo.info
    ) {
      return approvalRemainingTimeInfoData?.getApprovalRemainingTimeInfo.info;
    }
    return undefined;
  }, [data?.annualInfo, approvalRemainingTimeInfoData]);

  const drafter = useMemo(() => {
    let newDrafter = {
      employeeId: user?.employeeId ?? "",
      name: user?.name ?? "",
      signImage,
      signText,
      signType,
      approvalTime: ""
    };
    if (isDrafterSelected(listOfUser)) {
      newDrafter = {
        employeeId: listOfUser![0].employeeId ?? "",
        name: listOfUser![0].name,
        signImage: listOfUser![0].signImage,
        signText: listOfUser![0].signText,
        signType: listOfUser![0].signType,
        approvalTime: listOfUser![0]?.approvalTime ?? ""
      };
    }
    if (newDrafter.name.length > 10) {
      let name = newDrafter.name;
      name = name.slice(0, 9);
      name += "...";
      newDrafter.name = name;
    }
    return newDrafter;
  }, [listOfUser, signImage, signText, signType, user]);

  useEffect(() => {
    if (template?.categoryId && isRequest && drafter.employeeId) {
      getAdditionalFieldInApprovalForm({
        variables: {
          employeeId: drafter.employeeId,
          year: startDate.value.slice(0, 4),
          categoryId: template?.categoryId
        }
      });
    }
  }, [
    getAdditionalFieldInApprovalForm,
    startDate,
    template?.categoryId,
    drafter
  ]);

  useEffect(() => {
    if (template?.categoryId && isRequest && drafter.employeeId) {
      getApprovalRemainingTimeInfo({
        variables: {
          employeeId: drafter.employeeId,
          startDate: startDate.value,
          categoryId: template?.categoryId
        }
      });
    }
  }, [getApprovalRemainingTimeInfo, startDate, template?.categoryId, drafter]);

  useEffect(() => {
    if (isDrafterSelected(listOfUser)) {
      const newDrafter = {
        employeeId: listOfUser![0].employeeId,
        name: listOfUser![0].name,
        signImage: listOfUser![0].signImage,
        signText: listOfUser![0].signText,
        signType: listOfUser![0].signType,
        approvalTime: listOfUser![0]?.approvalTime ?? ""
      };
      fetchUserInfo(newDrafter.employeeId);
      fetchSignatureInfo(newDrafter.employeeId);
    } else {
      const token = localStorage.getItem(TOKEN);
      if (token) {
        const decodedData: any = handleDecodedToken(token);
        fetchUserInfo(decodedData.employee_id);
        fetchSignatureInfo(decodedData.employee_id);
      }
    }
  }, [fetchUserInfo, fetchSignatureInfo, listOfUser]);

  return {
    drafter,
    formName,
    user,
    createDate,
    isActiveTime,
    annualInfo,
    approvalRemainingTimeInfo,
    listOfApprover,
    departmentName,
    formTemplateIdx,
    isActiveWorkType,
    workingTemplateOptions
  };
}
