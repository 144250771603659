import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex: 10;
  will-change: scroll-position;
`;

export const TableFooter = styled.div`
  display: flex;
  flex: 1;
`;
