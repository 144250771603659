import { useState, useEffect } from "react";
import UrlParamsCrypto from "../../../../../Utils/UrlParamsCrypto";
import { IApprovalDelegateInfoWithCheck } from "../../tabs/approval-delegate-settings/constant";
export type AddEditMode = "add" | "edit";

export interface DelegateUrlParams {
  mode: AddEditMode;
  callEmployeeId: string;
  approvalAuthorityIdx: number;
  originDelegateInfo?: IApprovalDelegateInfoWithCheck;
}

export default function useUrlParams() {
  const [mode, setMode] = useState<AddEditMode>("add");
  const [callEmployeeId, setCallEmployeeId] = useState<string>("");
  const [approvalAuthorityIdx, setApprovalAuthorityIdx] = useState<number>(-1);
  const [originDelegateInfo, setOriginDelegateInfo] =
    useState<IApprovalDelegateInfoWithCheck>();
  useEffect(() => {
    // URL 파라미터 복호화
    const urlCrypto = new UrlParamsCrypto();
    const params = urlCrypto.decryptFromUrl(window.location.search);
    if (params) {
      // 복호화된 파라미터 사용
      setMode(params.mode);
      setCallEmployeeId(params.callEmployeeId);
      if (params.id) {
        setApprovalAuthorityIdx(params.id);
      }
      if (params.originDelegateInfo) {
        setOriginDelegateInfo(params.originDelegateInfo);
      }
    }
  }, []);

  return {
    mode,
    callEmployeeId,
    approvalAuthorityIdx,
    originDelegateInfo
  };
}
