import { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Reducers } from "../../../../types/reducers";
import useAsonicTab from "../../../hooks/hooks-shared/use-asonic-tab";
import { Role } from "../../../user-types";
import AsonicTab from "../../shared/asonic-tab";
import AdministratorSetting from "./administrator-setting";
import ApprovalManagementSetting from "./approval-management-setting/approval-management-setting";
import ApprovalProcessManagement from "./approval-process-management/approval-process-management";
import ApprovalDelegateSettingsTab from "../../../screens/approval-management/approval-settings/tabs/approval-delegate-settings/ApprovalDelegateSettingsTab";

interface ITabs {
  [key: string]: string;
}

type KTabs = string;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 15;
`;

const SHOW = 1;

function ApprovalSetting() {
  const { signInReducer } = useSelector((state: Reducers) => state);
  const tabs = useMemo(() => {
    const newTabs: ITabs = {
      APPROVAL_SETTINGS: "결재설정",
      APPROVAL_PROCESSING_MANAGEMENT: "결재선관리",
      APPROVAL_DELEGATE_SELECTION: "대결자설정"
    };
    if (signInReducer.checkWebAuthFn?.working_auth_web & SHOW) {
      newTabs["ADMINISTRATOR_SETTING"] = "관리자설정";
    }
    return newTabs;
  }, [signInReducer]);

  const { list, selectedTab, handleSelectTab } = useAsonicTab<ITabs, KTabs>({
    tabs
  });
  return (
    <Container>
      <AsonicTab<string>
        list={list}
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
      />
      <Content>
        {selectedTab === list[0]?.key && <ApprovalManagementSetting />}
        {selectedTab === list[1]?.key && <ApprovalProcessManagement />}
        {selectedTab === list[2]?.key && <ApprovalDelegateSettingsTab />}
        {selectedTab === list[3]?.key &&
          signInReducer.loginIdInfo !== Role.USER && <AdministratorSetting />}
      </Content>
    </Container>
  );
}

export default ApprovalSetting;
