import PageSet from "../../../../../components/table/set_page";
import { Icon } from "@iconify/react";
import MagnifyIcon from "@iconify/icons-mdi/magnify";
import HideColumn, {
  IHideColumnProps
} from "../../../../../components/table/hide_column";
import PointTitle from "../../../../../components/shared/point_title";
import StyledInput from "../../../../../components/shared/styled_input";
import IconContainer from "../../../../../components/shared/icon_container";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../../../components/table_v2/fixed_column_dropbox";
import {
  LeftSection,
  SearchContainer,
  SubTitle,
  TableHeaderContainer
} from "../styles";
import SearchCategoryDropBox from "./SearchCategoryDropBox";
import { useEffect, useState } from "react";
import { IApprovalDelegateInfoSearchType } from "../../../../../generated/graphql";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox {
  startDate: string;
  endDate: string;
  searchKeyword: string;
  searchType: IApprovalDelegateInfoSearchType;
  setSearchType: React.Dispatch<
    React.SetStateAction<IApprovalDelegateInfoSearchType>
  >;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  take: number;
  handleTake: (value: string) => void;
  count: number;
  handleCurrentPage: (page: number) => void;
  downloadExcel?: () => void;
}

function TableHeader<P extends object>({
  columns,
  table,
  title,
  startDate,
  endDate,
  searchType,
  setSearchType,
  setStartDate,
  setEndDate,
  setIsSearch,
  headerTitleList,
  take,
  handleTake,
  count,
  handleCurrentPage,
  downloadExcel,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN,
  searchKeyword,
  setSearchKeyword
}: IProps<P>) {
  const [headerSearchKeyword, setHeaderSearchKeyword] = useState(searchKeyword);
  const [headerStartDate, setHeaderStartDate] = useState(startDate);
  const [headerEndDate, setHeaderEndDate] = useState(endDate);
  const [headerSearchType, setHeaderSearchType] = useState(searchType);

  useEffect(() => {
    if (
      searchType === headerSearchType &&
      startDate === headerStartDate &&
      endDate === headerEndDate &&
      searchKeyword === headerSearchKeyword
    ) {
      setIsSearch(true);
      handleCurrentPage(1);
    }
  }, [searchType, startDate, endDate, searchKeyword]);

  return (
    <TableHeaderContainer>
      <LeftSection>
        <PointTitle title="검색결과" subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <SearchContainer>
          <SubTitle>검색조건:</SubTitle>
          <span>{`기간`}</span>
          <StyledInput
            type="date"
            value={headerStartDate}
            onChange={event => {
              setHeaderStartDate(event.currentTarget.value);
            }}
          />
          <span>{`~`}</span>
          <StyledInput
            type="date"
            value={headerEndDate}
            onChange={event => {
              setHeaderEndDate(event.currentTarget.value);
            }}
          />
          <SearchCategoryDropBox
            searchType={headerSearchType}
            setSearchType={setHeaderSearchType}
          />
          <StyledInput
            id="search-input"
            name="searchKeyword"
            value={headerSearchKeyword}
            onChange={event => {
              setHeaderSearchKeyword(event.currentTarget.value);
            }}
          />
          <IconContainer
            onClick={() => {
              setSearchType(headerSearchType);
              setStartDate(headerStartDate);
              setEndDate(headerEndDate);
              setSearchKeyword(headerSearchKeyword);
            }}
          >
            <Icon icon={MagnifyIcon} />
          </IconContainer>
        </SearchContainer>
      </LeftSection>
      <PageSet take={take} handleTake={handleTake} />
    </TableHeaderContainer>
  );
}

export default TableHeader;
