import { Approval_User_Type } from "../../../../generated/graphql";
import { IEmployeeInformation } from "../../approval-process/list-of-selected-approval-user";
import { useMemo } from "react";

export function useApproverInfo(listOfUser?: IEmployeeInformation[]) {
  const isDrafterSelected = (listOfUser?: IEmployeeInformation[]) => {
    return (
      listOfUser && listOfUser[0]?.approverType === Approval_User_Type.Drafter
    );
  };

  const listOfApprover = useMemo(() => {
    return listOfUser?.filter(
      item => item.approverType !== Approval_User_Type.Drafter
    );
  }, [listOfUser]);

  return {
    isDrafterSelected,
    listOfApprover
  };
}
