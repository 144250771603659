import {
  Container,
  ContentContainer,
  SelectContainer,
  Select,
  Option,
  Label
} from "./styled";
import { IChildProps } from "../CommuteEditorDialogContainer";
import DateWithTimeContainer from "../../../../../../DateWithTime/DateWithTimeContainer";
import Button from "../../../../../../globalComponents/Button";
import { ApprovalFlag } from "../../../../../../../__generated__/globalTypes";
import AsonicDialog from "../../../../../../asonic-dialog/asonic-dialog";

const CommuteEditorDialog = (props: IChildProps) => {
  const {
    handleClose,
    handleOption,
    handleSubmit,
    dateWithTime,
    handleDateWithTime
  } = props;
  return (
    <Container>
      <AsonicDialog
        title="출/퇴근 기록변경"
        handleClose={handleClose}
        // width="460px"
        // height="20%"
        // minHeight="200px"
      >
        <ContentContainer>
          <DateWithTimeContainer
            title="출/퇴근 시간"
            dateWithTime={dateWithTime}
            handleDateWithTime={handleDateWithTime}
          />
          <SelectContainer>
            <Label htmlFor="isAllowed">인정 여부</Label>
            <Select onChange={handleOption} id="isAllowed">
              <Option value={ApprovalFlag.ORIGIN_RECOGNITION}>원본 인정</Option>
              <Option value={ApprovalFlag.CORRECTED_VERSION}>
                수정본 인정
              </Option>
              <Option value={ApprovalFlag.NO_RECOGNITION}>원본 미인정</Option>
            </Select>
          </SelectContainer>
          <Button onClick={handleSubmit}>확인</Button>
        </ContentContainer>
      </AsonicDialog>
    </Container>
  );
};

export default CommuteEditorDialog;
