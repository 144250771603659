import {
  Approval_User_Type,
  ApprovalRemainingTimeEntity
} from "../../../generated/graphql";

export const returnApprovalTypeString = (
  type: Approval_User_Type,
  isLast: boolean
) => {
  if (type === Approval_User_Type.Agreement) {
    return "합의";
  }
  if (type === Approval_User_Type.Approval) {
    return "결재";
  }
};

export const parseAnnualInfo = (annualInfo: string) => {
  try {
    const jsonParse = JSON.parse(annualInfo);
    return {
      processType: jsonParse?.process_type ?? 0,
      totDay: jsonParse?.tot_day ?? "",
      useDay: jsonParse?.use_day ?? "",
      leaveDay: jsonParse?.leave_day ?? ""
    };
  } catch (error) {
    console.error("Invalid JSON in annualInfo:", error);
    return undefined;
  }
};

export const parseApprovalRemainingTimeInfo = (
  approvalRemainingTimeInfo: string
) => {
  try {
    const jsonParse = JSON.parse(approvalRemainingTimeInfo);
    return {
      processType: jsonParse?.process_type ?? 0,
      totTime: jsonParse?.tot_time ?? "",
      useTime: jsonParse?.use_time ?? "",
      leaveTime: jsonParse?.leave_time ?? ""
    };
  } catch (error) {
    console.error("Invalid JSON in approvalRemainingTimeInfo:", error);
    return undefined;
  }
};
