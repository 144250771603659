import styled from "styled-components";

interface SectionContainerProps {
  flex?: number;
  justifyContent?: string;
  maxHeight?: string;
  disableOverflowY?: boolean;
}

const SectionContainer = styled.section<SectionContainerProps>`
  display: flex;
  flex: ${props => props.flex ?? 1};
  background-color: ${props => props.theme.colors.white};
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  justify-content: ${props => props.justifyContent ?? ""};
  max-height: ${props => props.maxHeight ?? "auto"};
  ${props =>
    !props.disableOverflowY &&
    `overflow-y: ${props.maxHeight ? "auto" : "hidden"};`}
`;

export default SectionContainer;
