import SectionContainer from "../../../../shared/section_container/section_container";
import SubTitle from "../../../../shared/sub-title/sub-title";
import TitleContainer from "../../../../shared/title_container/title_container";
import ContentContainer from "../../../../shared/grid_content_container/grid_content_container";
import FormRow from "../../../../shared/form-row/form-row";
import SSelect from "../../../../shared/s_select/s_select";
import useTimeList, {
  HOUR_TYPE
} from "../../../../../hooks/use_time_list/use_time_list";
import CBhelp from "./c_b_help";
import {
  OtSelfWorkingStandardHoursEntity,
  SelfDeterminedWorkHourBasicBasicEntity
} from "../../../../../generated/graphql";
import { useContext, useEffect, useState } from "react";
import { WS_STATE } from "../../../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import useTimeControl from "../../../../../hooks/use_time_control/use_time_control";
import styled from "styled-components";

interface IProps {
  basicInfo?: SelfDeterminedWorkHourBasicBasicEntity;
  checkedTemplateList: OtSelfWorkingStandardHoursEntity[];
}

export const SInput = styled.input`
  width: 40px;
`;

function ChoiceBasicWS({ basicInfo, checkedTemplateList }: IProps) {
  const wSState = useContext(WS_STATE);
  const { displayHours, makeHoursType, makeHours, handleMinute } =
    useTimeControl();
  const { hourList, hourTypeList } = useTimeList();

  const [startBreakTime, setStartBreakTime] = useState<string>("12:00");
  const [endBreakTime, setEndBreakTime] = useState<string>("13:00");

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      wSState?.setSelfDeterminedWorkHourBasicInfo(state => {
        if (wSState?.workingTemplateIdx) {
          return {
            workingTemplateIdx: wSState.workingTemplateIdx,
            startBreakTime,
            endBreakTime,
            workingTimeName: checkedTemplateList.map(
              item => item.workingTimeName
            )
          };
        }
        return state;
      });
    }
  }, [
    startBreakTime,
    endBreakTime,
    checkedTemplateList,
    wSState?.workingTemplateIdx,
    wSState?.setSelfDeterminedWorkHourBasicInfo
  ]);

  useEffect(() => {
    if (basicInfo?.startBreakTime && basicInfo?.endBreakTime) {
      setStartBreakTime(basicInfo?.startBreakTime);
      setEndBreakTime(basicInfo.endBreakTime);
    }
  }, [basicInfo?.startBreakTime, basicInfo?.endBreakTime]);

  return (
    <SectionContainer disableOverflowY>
      <TitleContainer flex={0}>
        <SubTitle title={"기본 설정"} />
        <CBhelp />
      </TitleContainer>
      <ContentContainer flex={0}>
        <FormRow title="휴게시간 설정">
          <SSelect
            name="startHourType"
            id="startHourType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(startBreakTime.split(":")[0]) <= 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartBreakTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="startHour"
            id="startHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(startBreakTime.split(":")[0])}
            onChange={event => {
              const hours = startBreakTime.split(":")[0];
              const minute = startBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartBreakTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startHour">{`시`}</label>
          <SInput
            type="number"
            name="startMin"
            id="startMin"
            value={startBreakTime.split(":")[1].padStart(2, "0")}
            onChange={event => {
              const value = event.currentTarget.value;
              const minute = handleMinute(value);
              setStartBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            max={59}
            min={0}
          />
          <label htmlFor="startMin">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            name="endHourType"
            id="endHourType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(endBreakTime.split(":")[0]) <= 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndBreakTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="endHour"
            id="endHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(endBreakTime.split(":")[0])}
            onChange={event => {
              const hours = endBreakTime.split(":")[0];
              const minute = endBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndBreakTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endHour">{`시`}</label>
          <SInput
            type="number"
            name="endMin"
            id="endMin"
            value={endBreakTime.split(":")[1].padStart(2, "0")}
            onChange={event => {
              const value = event.currentTarget.value;
              const minute = handleMinute(value);
              setEndBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            max={59}
            min={0}
          />
          <label htmlFor="endMin">{`분`}</label>
        </FormRow>
      </ContentContainer>
    </SectionContainer>
  );
}

export default ChoiceBasicWS;
