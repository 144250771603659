import { useMemo } from "react";
import * as ReactTable from "react-table";
import { Cell } from "react-table";
import { EmployeeInformation } from "../../../../../generated/graphql";

export default function useDelegateTable(delegateUsers: EmployeeInformation[]) {
  const columns: ReactTable.Column<EmployeeInformation>[] = useMemo(() => {
    const width = 180;
    return [
      {
        Header: "대결자정보",
        accessor: "name",
        Cell(cell: Cell<EmployeeInformation>) {
          const { name, departName, userPosition } = cell.row.original;
          let result = `${name} / ${departName}`;
          if (userPosition?.trim()) {
            result = `${name} / ${userPosition} / ${departName}`;
          }
          return result;
        },
        width
      }
    ];
  }, []);

  const table = ReactTable.useTable<EmployeeInformation>(
    {
      columns,
      data: delegateUsers
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  return table;
}
