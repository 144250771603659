import { useState, useEffect, useCallback, useMemo } from "react";
import {
  ApprovalEntity,
  ApprovalTemplateEntity,
  ListOfApprovalTemporaryStorageEntity,
  useGetListAllWorkingTemplateLazyQuery
} from "../../../../generated/graphql";
import { LABELS } from "../../../../screens/view-settings/work-schedule-settings/add-update/top-tabs/additional-setting-tab/strings";
import listOfApolloVar from "../../../../apollo/apollo-var";
import { useReactiveVar } from "@apollo/client";

export function useWorkScheduleInfo(
  template?: ApprovalTemplateEntity,
  data?: ApprovalEntity,
  approvalTemporaryStorageData?: ListOfApprovalTemporaryStorageEntity
) {
  const fName = useReactiveVar(listOfApolloVar.approvalFormNameVar);
  const [isActiveTime, setIsActiveTime] = useState(true);
  const [isActiveWorkType, setIsActiveWorkType] = useState(false);

  const [getListWorkingTemplate, { data: workingTemplateData }] =
    useGetListAllWorkingTemplateLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  const workingTemplateOptions = useMemo(() => {
    return [
      { value: 0, name: LABELS.NOT_USE_KO },
      ...(workingTemplateData?.getListAllWorkingTemplate?.list?.map(item => ({
        value: item.workingTemplateIdx,
        name: item.name
      })) ?? [])
    ];
  }, [workingTemplateData]);

  const handleIsActiveTime = useCallback(() => {
    const initFormIdx = 0;
    const formIdx: number =
      template?.formIdx ??
      data?.formIdx ??
      approvalTemporaryStorageData?.formIdx ??
      initFormIdx;
    const currentFormName = fName.get(formIdx);
    if ([fName.get(3), fName.get(4), fName.get(5)].includes(currentFormName)) {
      setIsActiveTime(false);
    }
  }, [template, data, approvalTemporaryStorageData]);

  const handleIsActiveWorkType = useCallback(() => {
    const initFormIdx = 0;
    const formIdx: number =
      template?.formIdx ??
      data?.formIdx ??
      approvalTemporaryStorageData?.formIdx ??
      initFormIdx;
    setIsActiveWorkType(formIdx === 10);
  }, [template, data, approvalTemporaryStorageData]);

  useEffect(() => {
    getListWorkingTemplate({
      variables: {
        searchValue: ""
      }
    });
  }, [getListWorkingTemplate]);

  useEffect(() => {
    handleIsActiveTime();
    handleIsActiveWorkType();
  }, [handleIsActiveTime, handleIsActiveWorkType]);

  return {
    isActiveTime,
    isActiveWorkType,
    workingTemplateOptions
  };
}
