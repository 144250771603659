import { RadioButtonGroup } from "../../../../../components/main-view/settings/MenuCheckBoxList/styled";
import FormRow from "../../../../../components/shared/form-row/form-row";
import RadioContainer from "../../../../../components/shared/radio_container/radio_container";
import { OtTimeTypeCategoryEntity } from "../../../../../generated/graphql";
import { ApprovalInfoContainer } from "../styles/container";
import {
  ApprovalInfoRow,
  ApprovalOption,
  ApprovalSelect,
  ApprovalTextArea
} from "../styles/form";
import ApprovalInput from "../styles/input";

function RightFormView({
  isActive,
  handleActiveChange,
  note,
  handleNoteChange,
  startDate,
  endDate,
  startTime,
  endTime,
  absenceReasonList,
  selectedAbsenceReason,
  handleStartDateChange,
  handleEndDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  handleAbsenceReasonChange,
  handleSelectedAbsenceReasonChange
}: {
  isActive: boolean;
  handleActiveChange: (value: boolean) => void;
  note: string;
  handleNoteChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  absenceReasonList: OtTimeTypeCategoryEntity[];
  selectedAbsenceReason: number;
  handleStartDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEndDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleStartTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEndTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAbsenceReasonChange: (reasons: OtTimeTypeCategoryEntity[]) => void;
  handleSelectedAbsenceReasonChange: (reasonId: number) => void;
}) {
  return (
    <ApprovalInfoContainer>
      <ApprovalInfoRow>
        <FormRow title="부재사유">
          <ApprovalSelect
            value={selectedAbsenceReason}
            onChange={e =>
              handleSelectedAbsenceReasonChange(Number(e.target.value))
            }
          >
            {absenceReasonList.map(reason => (
              <ApprovalOption key={reason.categoryId} value={reason.categoryId}>
                {reason.name}
              </ApprovalOption>
            ))}
          </ApprovalSelect>
        </FormRow>
        <FormRow title="대결시작일">
          <ApprovalInput
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <ApprovalInput
            type="time"
            value={startTime}
            onChange={handleStartTimeChange}
          />
        </FormRow>
        <FormRow title="대결종료일">
          <ApprovalInput
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
          />
          <ApprovalInput
            type="time"
            value={endTime}
            onChange={handleEndTimeChange}
          />
        </FormRow>
        <FormRow title="사용여부">
          <RadioButtonGroup>
            <RadioContainer>
              <ApprovalInput
                type="radio"
                id="active-true"
                name="isActive"
                value="true"
                checked={isActive}
                onChange={() => handleActiveChange(true)}
                minWidth="20px"
              />
              <label htmlFor="active-true">사용</label>
            </RadioContainer>
            <RadioContainer>
              <ApprovalInput
                type="radio"
                id="active-false"
                name="isActive"
                value="false"
                checked={!isActive}
                onChange={() => handleActiveChange(false)}
                minWidth="20px"
              />
              <label htmlFor="active-false">미사용</label>
            </RadioContainer>
          </RadioButtonGroup>
        </FormRow>
        <FormRow minHeight="120px" title="비고">
          <ApprovalTextArea
            value={note}
            onChange={handleNoteChange}
            placeholder="추가 정보를 입력하세요"
          />
        </FormRow>
      </ApprovalInfoRow>
    </ApprovalInfoContainer>
  );
}

export default RightFormView;
