import { useState, useCallback } from "react";
import { DialogType } from "./ConfirmDialog";

interface DialogConfig {
  isOpen: boolean;
  type: DialogType;
  title: string;
  message: string;
  showCancelButton: boolean;
  confirmText?: string;
  cancelText?: string;
}

interface UseConfirmDialogReturn {
  dialogConfig: DialogConfig;
  openDialog: (config: Partial<DialogConfig>) => void;
  closeDialog: () => void;
  openConfirmDialog: (
    title: string,
    message: string,
    type?: DialogType
  ) => void;
  openSuccessDialog: (
    title: string,
    message: string,
    autoCloseMs?: number
  ) => void;
  openErrorDialog: (title: string, message: string) => void;
  openWarningDialog: (title: string, message: string) => void;
  openInfoDialog: (title: string, message: string) => void;
}

const defaultConfig: DialogConfig = {
  isOpen: false,
  type: "info",
  title: "",
  message: "",
  showCancelButton: true,
  confirmText: "확인",
  cancelText: "취소"
};

/**
 * 확인 다이얼로그를 관리하는 커스텀 훅
 * @param onAutoClose 자동 닫힘 후 실행할 콜백 함수
 * @returns 다이얼로그 상태와 관련 함수들
 */
export function useConfirmDialog(
  onAutoClose?: () => void
): UseConfirmDialogReturn {
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>(defaultConfig);

  // 다이얼로그 열기 함수
  const openDialog = useCallback((config: Partial<DialogConfig>) => {
    setDialogConfig(prev => ({ ...prev, isOpen: true, ...config }));
  }, []);

  // 다이얼로그 닫기 함수
  const closeDialog = useCallback(() => {
    setDialogConfig(prev => ({ ...prev, isOpen: false }));
  }, []);

  // 확인 다이얼로그 열기
  const openConfirmDialog = useCallback(
    (title: string, message: string, type: DialogType = "info") => {
      openDialog({
        type,
        title,
        message,
        showCancelButton: true
      });
    },
    [openDialog]
  );

  // 성공 다이얼로그 열기 (자동 닫힘 옵션 포함)
  const openSuccessDialog = useCallback(
    (title: string, message: string, autoCloseMs?: number) => {
      openDialog({
        type: "success",
        title,
        message,
        showCancelButton: false
      });

      // 자동 닫힘 설정
      if (autoCloseMs) {
        setTimeout(() => {
          closeDialog();
          if (onAutoClose) onAutoClose();
        }, autoCloseMs);
      }
    },
    [openDialog, closeDialog, onAutoClose]
  );

  // 에러 다이얼로그 열기
  const openErrorDialog = useCallback(
    (title: string, message: string) => {
      openDialog({
        type: "error",
        title,
        message,
        showCancelButton: false
      });
    },
    [openDialog]
  );

  // 경고 다이얼로그 열기
  const openWarningDialog = useCallback(
    (title: string, message: string) => {
      openDialog({
        type: "warning",
        title,
        message,
        showCancelButton: true
      });
    },
    [openDialog]
  );

  // 정보 다이얼로그 열기
  const openInfoDialog = useCallback(
    (title: string, message: string) => {
      openDialog({
        type: "info",
        title,
        message,
        showCancelButton: true
      });
    },
    [openDialog]
  );

  return {
    dialogConfig,
    openDialog,
    closeDialog,
    openConfirmDialog,
    openSuccessDialog,
    openErrorDialog,
    openWarningDialog,
    openInfoDialog
  };
}
