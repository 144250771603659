import { useEffect, useMemo } from "react";
import { useGetListOfOtTimeTypeCategoryLazyQuery } from "../../../../../generated/graphql";

function useFetchTimeTypeCategories() {
  const [fetchCategories, { data, loading, error }] =
    useGetListOfOtTimeTypeCategoryLazyQuery({
      onError(error) {
        console.error("Failed to fetch time type categories:", error);
      },
      onCompleted(data) {
        if (data.getListOfOtTimeTypeCategory.error) {
          console.error(
            "API returned error:",
            data.getListOfOtTimeTypeCategory.error
          );
        }
      }
    });

  const categories = useMemo(() => {
    return data?.getListOfOtTimeTypeCategory.list ?? [];
  }, [data]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return {
    categories,
    isLoading: loading,
    error: error || data?.getListOfOtTimeTypeCategory.error,
    refetch: fetchCategories
  };
}

export default useFetchTimeTypeCategories;
