import { useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import * as ReactTable from "react-table";
import {
  EmployeeInformation,
  useGetAllEmployeeInformationInDepartmentForAllEmployeesLazyQuery
} from "../../../generated/graphql";
import CompactTable from "../../compact-table/compact-table";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import ReactLoading from "react-loading";
import { colors } from "../../GlobalStyle/GlobalStyle";
import { TOKEN } from "../../../apollo/apollo";
import { handleDecodedToken } from "../../../Utils/tokenMaker";

export interface IListOfEmployeeInGroups {
  selectedDepartment?: number;
  handleAddUser: (user?: ICompactRow<EmployeeInformation>) => void;
}

interface IEmployeeInformation extends EmployeeInformation {
  number?: number;
}

const COLUMN_FOR_VALUE = {
  number: "No",
  departName: "부서",
  name: "결재자정보",
  userPosition: "직책"
} as const;

export type TYPE_OF_EMPLOYEE = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div<{ loading?: number }>`
  display: flex;
  flex: 8;
  justify-content: center;
  align-items: ${props => (props.loading ? "center" : "")};
`;

function ListOfEmployeeInGroups({
  selectedDepartment,
  handleAddUser
}: IListOfEmployeeInGroups) {
  const mounted = useRef(false);
  const [
    getAllEmployeeInformationInDepartmentForAllEmployees,
    { data, loading }
  ] = useGetAllEmployeeInformationInDepartmentForAllEmployeesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network"
  });

  const columns: ReactTable.Column<IEmployeeInformation>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const newListOfColumn = listOfColumn.map(item => {
      let width = 100;

      return {
        Header: COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE],
        accessor: item as TYPE_OF_EMPLOYEE,
        width
      };
    });

    return newListOfColumn;
  }, []);

  const userData = useMemo(() => {
    let newData: IEmployeeInformation[] = [];
    if (
      data?.getAllEmployeeInformationInDepartmentForAllEmployees.listOfEmployee
    ) {
      const token = localStorage.getItem(TOKEN);
      if (token) {
        const decodedData: any = handleDecodedToken(token);
        const employeeId = decodedData.employee_id;
        data?.getAllEmployeeInformationInDepartmentForAllEmployees.listOfEmployee.forEach(
          (item, index) => {
            if (item.employeeId !== employeeId) {
              newData.push({ number: index + 1, ...item });
            }
          }
        );
      }
    }
    return newData;
  }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<IEmployeeInformation>(
    {
      columns,
      data: userData
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow: ICompactRow<EmployeeInformation> | undefined =
    useMemo(() => {
      if (selectedFlatRows.length > 0) {
        return selectedFlatRows[selectedFlatRows.length - 1];
      }
      return undefined;
    }, [selectedFlatRows, handleAddUser]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
    if (selectedDepartment) {
      getAllEmployeeInformationInDepartmentForAllEmployees({
        variables: {
          selectedDepartmentId: `${selectedDepartment}`
        }
      });
    }

    return () => {
      mounted.current = false;
      handleAddUser();
    };
  }, [
    selectedDepartment,
    getAllEmployeeInformationInDepartmentForAllEmployees,
    handleAddUser
  ]);

  return (
    <Container loading={loading ? 1 : undefined}>
      {loading ? (
        <ReactLoading
          type="spokes"
          color={colors.green}
          height={100}
          width={50}
        />
      ) : (
        <CompactTable
          title="결재자"
          handleSelectRow={row => {
            handleAddUser(row);
          }}
          handleUnselectedRow={row => {
            handleAddUser();
          }}
          isLoading={false}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          isTitleBar={false}
        />
      )}
    </Container>
  );
}

export default ListOfEmployeeInGroups;
