import { Suspense, useMemo, useState, useContext, useEffect } from "react";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";
import { TableFooter, Container, ButtonContainer } from "../styles";
import { TableContainer } from "../styles";
import TableHeader from "./TableHeader";
import { TColumn } from "../../../../../hooks/use_hide_table_columns/use_hide_table_columns";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../../../components/table_v2/table_v2";
import Spinner from "../../../../../components/shared/spinner";
import PageController from "../../../../../components/table/page_controller";
import {
  COLUMN_FOR_VALUE,
  IApprovalDelegateInfoWithCheck,
  TYPE_OF_APPROVAL_DELEGATE
} from "./constant";
import { useWeekPeriodStartEndDate } from "../../../../../components/main-view/WorkManagementInfo/hooks";
import ToastMessage from "../../../../../components/toast-message/toast-message";
import useFetchApprovalDelegateInfo from "./hooks/useFetchApprovalDelegateInfo";
import { IApprovalDelegateInfoSearchType } from "../../../../../generated/graphql";
import MainViewContext from "../../../../../components/main-view/store";
import { useApprovalDelegateTable } from "./hooks/useApprovalDelegateTable";
import ActionButtons from "./components/ActionButtons";

function ApprovalDelegateSettingsTab() {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber
  } = useFixedColumn();

  const rootStore = useContext(MainViewContext);
  const {
    signInReducer: { employee_id: callEmployeeId }
  } = rootStore;
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const { firstDayOfMonthFormattedString, lastDayOfMonthFormattedString } =
    useWeekPeriodStartEndDate();

  const [searchType, setSearchType] = useState(
    IApprovalDelegateInfoSearchType.EmployeeIdSubstitute
  );
  const [startDate, setStartDate] = useState(firstDayOfMonthFormattedString);
  const [endDate, setEndDate] = useState(lastDayOfMonthFormattedString);
  const [searchKeyword, setSearchKeyword] = useState("");

  const {
    approvalDelegateInfo,
    isLoading,
    count,
    refetch,
    deleteApprovalDelegateInfo,
    handleSearch
  } = useFetchApprovalDelegateInfo(
    startDate,
    endDate,
    searchType,
    searchKeyword,
    currentPage,
    handleToast,
    take
  );

  const list = approvalDelegateInfo ?? [];

  // 테이블 관련 로직 분리
  const {
    table,
    selectedRow,
    selectedRowAuthorityIdx,
    columnVisibility: tableColumnVisibility,
    setColumnVisibility: setTableColumnVisibility
  } = useApprovalDelegateTable({
    list,
    sFixedColumnNumber: sFixedColumnNumber ?? 0
  });

  // TableV2를 메모이제이션하여 불필요한 재렌더링 방지
  const memoizedTableV2 = useMemo(() => {
    return ({ height, width }: { height: number; width: number }) => (
      <TableV2
        table={table}
        title="대결자설정"
        selectedRow={selectedRow}
        height={height}
        width={width}
        loading={isLoading}
        isCustomSelect
      />
    );
  }, [table, selectedRow, isLoading]);

  // 컴포넌트 최상위 레벨에 useEffect 추가
  useEffect(() => {
    // 메시지 이벤트 리스너 등록
    const handleMessage = (event: MessageEvent) => {
      // 보안을 위해 출처 확인 (같은 도메인에서 온 메시지만 처리)
      if (event.origin !== window.location.origin) return;

      // 메시지 데이터 확인
      if (event.data && event.data.type === "APPROVAL_DELEGATE_UPDATED") {
        // 데이터 다시 불러오기
        refetch(
          startDate,
          endDate,
          searchType,
          searchKeyword,
          currentPage,
          take
        );
      }
    };

    // 이벤트 리스너 등록
    window.addEventListener("message", handleMessage);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [
    startDate,
    endDate,
    searchType,
    searchKeyword,
    currentPage,
    take,
    refetch
  ]);

  return (
    <Container $isVisibility={true}>
      <TableHeader
        columns={table.columns as TColumn<IApprovalDelegateInfoWithCheck>[]}
        table={table}
        title="결재 위임 설정"
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setIsSearch={handleSearch}
        searchType={searchType}
        setSearchType={setSearchType}
        take={take}
        handleTake={handleTake}
        count={count}
        headerTitleList={Object.keys(COLUMN_FOR_VALUE).map(
          item => COLUMN_FOR_VALUE[item as TYPE_OF_APPROVAL_DELEGATE]
        )}
        handleCurrentPage={handleCurrentPage}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
      />
      <Suspense fallback={<Spinner />}>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return memoizedTableV2({ height, width });
            }}
          </AutoSizer>
        </TableContainer>
      </Suspense>
      <TableFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(count / take)}
          handleCurrentPage={handleCurrentPage}
        />
        <ButtonContainer>
          <ActionButtons
            selectedRow={selectedRow}
            selectedRowAuthorityIdx={selectedRowAuthorityIdx}
            callEmployeeId={callEmployeeId}
            deleteApprovalDelegateInfo={deleteApprovalDelegateInfo}
            selectedRowsCount={table.selectedFlatRows.length}
          />
        </ButtonContainer>
      </TableFooter>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ApprovalDelegateSettingsTab;
