import CompactTable from "../../../../../components/compact-table/compact-table";
import { ApprovalDelegateRightContainer } from "../styles/container";
import { IApprovalDelegateInfoWithCheck } from "../../tabs/approval-delegate-settings/constant";
import * as ReactTable from "react-table";
import RightFormView from "./RightFormView";
import {
  EmployeeInformation,
  OtTimeTypeCategoryEntity
} from "../../../../../generated/graphql";

function ApprovalDelegateSelectionRight({
  table,
  isActive,
  handleActiveChange,
  note,
  handleNoteChange,
  startDate,
  endDate,
  startTime,
  endTime,
  absenceReasonList,
  selectedAbsenceReason,
  handleStartDateChange,
  handleEndDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  handleAbsenceReasonChange,
  handleSelectedAbsenceReasonChange
}: {
  table: ReactTable.TableInstance<EmployeeInformation>;
  isActive: boolean;
  handleActiveChange: (value: boolean) => void;
  note: string;
  handleNoteChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  absenceReasonList: OtTimeTypeCategoryEntity[];
  selectedAbsenceReason: number;
  handleStartDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEndDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleStartTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEndTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAbsenceReasonChange: (reasons: OtTimeTypeCategoryEntity[]) => void;
  handleSelectedAbsenceReasonChange: (reasonId: number) => void;
}) {
  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = table;

  return (
    <ApprovalDelegateRightContainer>
      <CompactTable
        title="결재자"
        handleSelectRow={() => {}}
        isLoading={false}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedFlatRows[0]}
        isTitleBar={false}
      />
      <RightFormView
        isActive={isActive}
        handleActiveChange={handleActiveChange}
        note={note}
        handleNoteChange={handleNoteChange}
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
        absenceReasonList={absenceReasonList}
        selectedAbsenceReason={selectedAbsenceReason}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleStartTimeChange={handleStartTimeChange}
        handleEndTimeChange={handleEndTimeChange}
        handleAbsenceReasonChange={handleAbsenceReasonChange}
        handleSelectedAbsenceReasonChange={handleSelectedAbsenceReasonChange}
      />
    </ApprovalDelegateRightContainer>
  );
}

export default ApprovalDelegateSelectionRight;
