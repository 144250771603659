import useTimeList from "../../../../../../hooks/use_time_list/use_time_list";
import { WS_STATE, WS_STORE } from "../../asonic-working-system.screen";
import StyleInput from "../../../../../../components/inputs/style-input";
import ContentContainer from "../../../../../../components/shared/grid_content_container/grid_content_container";
import FormRow from "../../../../../../components/shared/form-row/form-row";
import RadioContainer from "../../../../../../components/shared/radio_container/radio_container";
import SectionContainer from "../../../../../../components/shared/section_container/section_container";
import SubTitle from "../../../../../../components/shared/sub-title/sub-title";
import SSelect from "../../../../../../components/shared/s_select/s_select";
import TitleContainer from "../../../../../../components/shared/title_container/title_container";
import WSBasicInfoHelp from "./w_s_basic_info_help";
import {
  useMemo,
  useContext,
  useEffect,
  memo,
  useState,
  useLayoutEffect
} from "react";
import {
  Day_Type,
  Is_Use,
  Period_Unit_Type,
  Setting_Enable_For_Working_Template,
  Work_Type,
  useGetWorkingTimeListQuery,
  useGetWtBasicInfoLazyQuery
} from "../../../../../../generated/graphql";

import { WorkingTimeInfoEntity } from "../../../../../../generated/graphql";
import handleApolloLog from "../../../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../../../Utils/handle_error_log/handle_error_log";
import { SInput } from "../../../../../../components/w_s_tabs/work_time_tab/choice_w_s/choice_basic_w_s/choice_basic_w_s";
import TitleSection from "../../../../../components/title-section";

interface IProps {
  workType: Work_Type;
  handleWorkType: (payload: Work_Type) => void;
}

function WSBasicInfo({ workType, handleWorkType }: IProps) {
  const wsState = useContext(WS_STATE);
  const { day, dispatch } = useContext(WS_STORE);
  const { hourList } = useTimeList();
  const [name, setName] = useState<string>("");
  const [otHoursTypeCategoryIdx, setOtHoursTypeCategoryIdx] = useState<
    number | undefined | null
  >(undefined);
  const [dayMaxWorkHour, setDayMaxWorkHour] = useState<number>(8);
  const [webTemplateWorkTimeUse, setWebTemplateWorkTimeUse] =
    useState<Setting_Enable_For_Working_Template>(
      Setting_Enable_For_Working_Template.NoUse
    );
  const [dayOfWeekStart, setDayOfWeekStart] = useState<Day_Type>(Day_Type.Mon);
  const [mandatoryBreakTermHour, setMandatoryBreakTermHour] =
    useState<number>(4);
  const [mandatoryBreakMin, setMandatoryBreakMin] = useState(30);
  const [weekMaxExtWorkHour, setWeekMaxExtWorkHour] = useState(0);
  const [weekMaxWorkHour, setWeekMaxWorkHour] = useState(0);
  const [isSelfBasicWorkType, setIsSelfBasicWorkType] =
    useState<boolean>(false);
  const [otHolidayTemplateNewAutoUse, setOtHolidayTemplateNewAutoUse] =
    useState<Is_Use>(Is_Use.UnUse);
  const [sign, setSign] = useState<string>("=");
  const [timeDifferenceMinForHour, setTimeDifferenceMinForHour] =
    useState<number>(0);
  const [timeDifferenceMin, setTimeDifferenceMin] = useState<number>(0);
  const [periodUnitType, setPeriodUnitType] = useState<Period_Unit_Type>(
    Period_Unit_Type.Weekly
  );

  const { data } = useGetWorkingTimeListQuery();
  // 여기서 기본설정을 서버에서 받아온다
  // basicData 변수로 사용됨
  // useGetListAllWorkingTemplateQuery로 미성년자 근무표도 같이 받아와야함
  const [getWtBasicInfo, { data: basicData }] = useGetWtBasicInfoLazyQuery({
    onError(error) {
      handleApolloLog(error);
    },
    onCompleted(data) {
      console.log(data);
      if (!data.getWTBasicInfo.ok && data.getWTBasicInfo.error) {
        handleErrorLog(data.getWTBasicInfo.error);
      }
    }
  });

  useEffect(() => {
    // 근무 시간 유형
    wsState?.handleIsSelfBasicWorkType(isSelfBasicWorkType);
    if (!isSelfBasicWorkType && data?.getWorkingTimeList.list) {
      setDayMaxWorkHour(data.getWorkingTimeList.list[0].dayMaxWorkHour);
      setWeekMaxWorkHour(data.getWorkingTimeList.list[0].weekMaxWorkHour);
      setWeekMaxExtWorkHour(data.getWorkingTimeList.list[0].weekMaxExtWorkHour);
    }
  }, [
    wsState?.handleIsSelfBasicWorkType,
    isSelfBasicWorkType,
    wsState?.workPolicyBasicSetting
  ]);

  useEffect(() => {
    if (basicData?.getWTBasicInfo.wTBasicInfo) {
      const {
        name,
        workType,
        otHoursTypeCategoryIdx,
        dayMaxWorkHour,
        dayOfWeekStart,
        mandatoryBreakTermHour,
        mandatoryBreakMin,
        weekMaxExtWorkHour,
        weekMaxWorkHour,
        otHolidayTemplateNewAutoUse,
        timeDifferenceMin,
        periodUnitType,
        webTemplateWorkTimeUse,
        standardDailyStartTimeRange, // 하루 시작 시간
        standardDailyEndTimeRange, // 하루 종료 시간
        nightReferenceStartTimeRange, // 야간 시작 시간
        nightReferenceEndTimeRange, // 야간 종료 시간
        periodUnitMonthFixMin, // 관리자 입력일 때 분
        periodUnitMonthMinType, // 월 단위 근무 시간 (자동계산, 관리자 입력)
        weeklyHolidayAllowanceUse, // 주당 휴일 사용 여부
        workingTemplateIdxMinors, // 미성년자 근무 템플릿 인덱스
        lateCheckTime // 지각 기준 시간
      } = basicData.getWTBasicInfo.wTBasicInfo;

      // 기본 값 넣어주기
      wsState?.handleWorkPolicyAdditionalSetting({
        lateCheckTime,
        nightReferenceStartTimeRange,
        nightReferenceEndTimeRange,
        periodUnitMonthMinType,
        periodUnitMonthFixMin,
        standardDailyStartTimeRange,
        standardDailyEndTimeRange,
        weeklyHolidayAllowanceUse,
        workingTemplateIdxMinors: workingTemplateIdxMinors ?? 0,
        limitExtworkminDay: 0,
        limitExtworkminWeek: 0,
        limitExtworkminMonth: 0
      });
      setName(name ?? "");
      setWebTemplateWorkTimeUse(webTemplateWorkTimeUse);
      if (!otHoursTypeCategoryIdx) {
        setIsSelfBasicWorkType(true);
      } else {
        setIsSelfBasicWorkType(false);
        setOtHoursTypeCategoryIdx(otHoursTypeCategoryIdx);
      }
      setDayMaxWorkHour(dayMaxWorkHour);
      setWeekMaxWorkHour(weekMaxWorkHour);
      setDayOfWeekStart(dayOfWeekStart);
      setMandatoryBreakTermHour(mandatoryBreakTermHour);
      setMandatoryBreakMin(mandatoryBreakMin);
      setWeekMaxExtWorkHour(weekMaxExtWorkHour);
      setOtHolidayTemplateNewAutoUse(otHolidayTemplateNewAutoUse);
      if (periodUnitType) {
        setPeriodUnitType(periodUnitType);
      }
      // 시차 정보 가져와서 표현을 위한 설정
      const newTimeDifferenceHour = Math.floor(
        Math.abs(timeDifferenceMin) / 60
      );
      const newTimeDifferenceMin = timeDifferenceMin % 60;
      setSign(timeDifferenceMin < 0 ? "-" : "+");
      setTimeDifferenceMinForHour(Math.abs(newTimeDifferenceHour));
      setTimeDifferenceMin(Math.abs(newTimeDifferenceMin));
      if (dispatch) {
        dispatch({ type: dayOfWeekStart });
      }
      handleWorkType(workType);
    }
  }, [dispatch, basicData]);

  const workTimeTypeList: WorkingTimeInfoEntity[] = useMemo(() => {
    if (data?.getWorkingTimeList.list) {
      setOtHoursTypeCategoryIdx(
        data.getWorkingTimeList.list[0].otHoursTypeCategoryIdx
      );
      setDayMaxWorkHour(data.getWorkingTimeList.list[0].dayMaxWorkHour);
      setWeekMaxWorkHour(data.getWorkingTimeList.list[0].weekMaxWorkHour);
      setWeekMaxExtWorkHour(data.getWorkingTimeList.list[0].weekMaxExtWorkHour);
      return data.getWorkingTimeList.list;
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (wsState?.handleWorkPolicyBasicSetting) {
      let newTimeDifferenceMin = timeDifferenceMin;
      let timeDifferenceMinFromHour = 0;
      if (timeDifferenceMinForHour > 0) {
        timeDifferenceMinFromHour = timeDifferenceMinForHour * 60;
      }
      newTimeDifferenceMin += timeDifferenceMinFromHour;
      if (sign === "-") {
        newTimeDifferenceMin = -newTimeDifferenceMin;
      }
      wsState.handleWorkPolicyBasicSetting({
        __typename: "WTBasicInfoEntity",
        workingTemplateIdx: wsState?.workingTemplateIdx ?? 0,
        name,
        workType,
        otHoursTypeCategoryIdx: otHoursTypeCategoryIdx ?? 0,
        dayMaxWorkHour,
        weekMaxWorkHour,
        weekMaxExtWorkHour,
        mandatoryBreakTermHour,
        mandatoryBreakMin,
        dayOfWeekStart,
        otHolidayTemplateNewAutoUse,
        timeDifferenceMin: newTimeDifferenceMin,
        periodUnitType,
        webTemplateWorkTimeUse
        // nightReferenceStartTimeRange: "22:00",
        // nightReferenceEndTimeRange: "06:00",
        // periodUnitMonthMinType: Setting_Enable_For_Working_Template.NoUse,
        // periodUnitMonthFixMin: 0,
        // standardDailyStartTimeRange: "09:00",
        // standardDailyEndTimeRange: "18:00",
        // weeklyHolidayAllowanceUse: Setting_Enable_For_Working_Template.NoUse,
        // webTemplateWorkTimeUse: Setting_Enable_For_Working_Template.NoUse,
        // workingTemplateIdxMinors: 0
      });

      // 새로 추가 일 때만 기본 값을 아래와 같이 설정한다
      if (!wsState?.workingTemplateIdx) {
        wsState.handleWorkPolicyAdditionalSetting({
          limitExtworkminDay: 0,
          limitExtworkminWeek: 0,
          limitExtworkminMonth: 0,
          lateCheckTime: "22:00:00",
          nightReferenceStartTimeRange: "22:00:00",
          nightReferenceEndTimeRange: "05:59:00",
          periodUnitMonthMinType: Setting_Enable_For_Working_Template.NoUse,
          periodUnitMonthFixMin: 0,
          standardDailyStartTimeRange: "06:00:00",
          standardDailyEndTimeRange: "05:59:00",
          weeklyHolidayAllowanceUse: Setting_Enable_For_Working_Template.NoUse,
          workingTemplateIdxMinors: 0
        });
      }
    }
  }, [
    wsState?.workingTemplateIdx,
    wsState?.handleWorkPolicyBasicSetting,
    name,
    workType,
    otHoursTypeCategoryIdx,
    dayMaxWorkHour,
    dayOfWeekStart,
    mandatoryBreakTermHour,
    mandatoryBreakMin,
    weekMaxExtWorkHour,
    weekMaxWorkHour,
    otHolidayTemplateNewAutoUse,
    sign,
    timeDifferenceMinForHour,
    timeDifferenceMin,
    periodUnitType,
    webTemplateWorkTimeUse
  ]);

  useEffect(() => {
    if (wsState?.workingTemplateIdx) {
      getWtBasicInfo({
        variables: {
          workingTemplateIdx: wsState.workingTemplateIdx
        }
      });
    }
  }, [getWtBasicInfo, wsState?.workingTemplateIdx]);

  // 전체 근무시간 셋팅
  useLayoutEffect(() => {
    if (wsState?.setWeekTotalWorkHour) {
      wsState?.setWeekTotalWorkHour(weekMaxWorkHour + weekMaxExtWorkHour);
    }
  }, [wsState?.setWeekTotalWorkHour, weekMaxWorkHour, weekMaxExtWorkHour]);

  return (
    <SectionContainer>
      <TitleSection>
        <TitleContainer>
          <SubTitle title={"기본정보"} />
          <WSBasicInfoHelp />
        </TitleContainer>
      </TitleSection>
      <ContentContainer flex={0}>
        <FormRow title="관리 이름">
          <StyleInput
            minWidth="98%"
            maxWidth="98%"
            value={name}
            onChange={event => {
              setName(event.currentTarget.value);
            }}
          />
        </FormRow>
        <FormRow title="근무 시간 설정">
          <RadioContainer>
            <StyleInput
              type="radio"
              id="workTimeNotUse"
              minWidth="15px"
              maxWidth="15px"
              name="workTimeUsage"
              value={Setting_Enable_For_Working_Template.NoUse}
              checked={
                webTemplateWorkTimeUse ===
                Setting_Enable_For_Working_Template.NoUse
              }
              onChange={_ => {
                setWebTemplateWorkTimeUse(
                  Setting_Enable_For_Working_Template.NoUse
                );
              }}
            />
            <label>{`사용안함`}</label>
            <StyleInput
              type="radio"
              id="workTimeUse"
              minWidth="15px"
              maxWidth="15px"
              name="workTimeUsage"
              value={Setting_Enable_For_Working_Template.Use}
              checked={
                webTemplateWorkTimeUse ===
                Setting_Enable_For_Working_Template.Use
              }
              onChange={_ => {
                setWebTemplateWorkTimeUse(
                  Setting_Enable_For_Working_Template.Use
                );
              }}
            />
            <label>{`사용`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="근무 유형" gridNumber={1}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id={Work_Type.SFCommutingPolicy}
              minWidth="15px"
              maxWidth="15px"
              name="workType"
              value={Work_Type.SFCommutingPolicy}
              checked={workType === Work_Type.SFCommutingPolicy}
              onChange={event => {
                handleWorkType(event.currentTarget.value as Work_Type);
              }}
            />
            <label
              htmlFor={Work_Type.SFCommutingPolicy}
            >{`표준 / 시차 출퇴근`}</label>
            <StyleInput
              type="radio"
              id={Work_Type.FlexibleWorkingHoursPolicy}
              minWidth="15px"
              maxWidth="15px"
              name="workType"
              value={Work_Type.FlexibleWorkingHoursPolicy}
              checked={workType === Work_Type.FlexibleWorkingHoursPolicy}
              onChange={event => {
                handleWorkType(event.currentTarget.value as Work_Type);
              }}
            />
            <label
              htmlFor={Work_Type.FlexibleWorkingHoursPolicy}
            >{`탄력 근무 시간제`}</label>
            <StyleInput
              type="radio"
              id={Work_Type.FlexibleSchedulePolicy}
              minWidth="15px"
              maxWidth="15px"
              name="workType"
              value={Work_Type.FlexibleSchedulePolicy}
              checked={workType === Work_Type.FlexibleSchedulePolicy}
              onChange={event => {
                handleWorkType(event.currentTarget.value as Work_Type);
              }}
            />
            <label htmlFor={Work_Type.FlexibleSchedulePolicy}>
              선택 근무 시간제
            </label>
            <StyleInput
              type="radio"
              id={Work_Type.AutonomousCommutingPolicy}
              minWidth="15px"
              maxWidth="15px"
              name="workType"
              value={Work_Type.AutonomousCommutingPolicy}
              checked={workType === Work_Type.AutonomousCommutingPolicy}
              onChange={event => {
                handleWorkType(event.currentTarget.value as Work_Type);
              }}
            />
            <label htmlFor={Work_Type.AutonomousCommutingPolicy}>
              자율출근제
            </label>
            <StyleInput
              type="radio"
              id={`Work_Type.AUTONOMY_LEAVE`}
              minWidth="15px"
              maxWidth="15px"
              name="workType"
              value={`Work_Type.AUTONOMY_LEAVE`}
              checked={false}
              onChange={event => {
                handleWorkType(event.currentTarget.value as Work_Type);
              }}
              disabled
            />
            <label htmlFor={`Work_Type.AUTONOMY_LEAVE`}>자율퇴근제</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="근무 시간 유형">
          <SSelect
            id="otHoursTypeCategoryIdx"
            minWidth="50px"
            maxWidth="380px"
            value={otHoursTypeCategoryIdx ?? undefined}
            onChange={event => {
              setOtHoursTypeCategoryIdx(Number(event.currentTarget.value));
            }}
            disabled={isSelfBasicWorkType}
          >
            {workTimeTypeList.map(item => (
              <option
                value={item.otHoursTypeCategoryIdx}
                key={item.otHoursTypeCategoryIdx}
              >
                {item.typeName}
              </option>
            ))}
          </SSelect>
          <StyleInput
            type="checkbox"
            id="isSelf"
            minWidth="15px"
            maxWidth="15px"
            checked={isSelfBasicWorkType}
            onChange={event => {
              setIsSelfBasicWorkType(event.currentTarget.checked);
            }}
          />
          <label htmlFor="isSelf">{`직접설정`}</label>
        </FormRow>
        <FormRow title="근무 시간(일)">
          <StyleInput
            id="day"
            minWidth="40px"
            maxWidth="40px"
            type="number"
            step="1"
            min={1}
            value={dayMaxWorkHour}
            onChange={event => {
              if (
                event.currentTarget.value !== "0" &&
                event.currentTarget.value
              ) {
                setDayMaxWorkHour(Number(event.currentTarget.value));
                setWeekMaxWorkHour(Number(event.currentTarget.value) * 5);
              }
            }}
            disabled={!isSelfBasicWorkType}
          />
          <label htmlFor="day">{`시간`}</label>
        </FormRow>
        <FormRow title="근무 시간(주)">
          <StyleInput
            id="week"
            minWidth="40px"
            maxWidth="40px"
            type="number"
            step="1"
            min={1}
            max={dayMaxWorkHour * 5}
            value={weekMaxWorkHour}
            onChange={event => {
              setWeekMaxWorkHour(Number(event.currentTarget.value));
            }}
            disabled={!isSelfBasicWorkType}
          />
          <label htmlFor="week">{`시간`}</label>
        </FormRow>
        <FormRow title="시간외 근무시간(주)">
          <StyleInput
            id="overtime"
            minWidth="40px"
            maxWidth="40px"
            type="number"
            step="1"
            min={1}
            value={weekMaxExtWorkHour}
            onChange={event => {
              setWeekMaxExtWorkHour(Number(event.currentTarget.value));
            }}
            disabled={!isSelfBasicWorkType}
          />
          <label htmlFor="overtime">{`시간`}</label>
        </FormRow>
        <FormRow title="의무 휴게 시간">
          <SSelect
            id="mandatoryBreakTermHour"
            minWidth="50px"
            maxWidth="50px"
            value={mandatoryBreakTermHour}
            onChange={event => {
              setMandatoryBreakTermHour(Number(event.currentTarget.value));
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="mandatoryBreakTermHour">{`시간마다`}</label>
          <SInput
            type="number"
            name="mandatoryBreakMin"
            id="mandatoryBreakMin"
            value={mandatoryBreakMin.toString().padStart(2, "0")}
            onChange={event => {
              const value = event.currentTarget.value;
              setMandatoryBreakMin(Number(value));
            }}
            max={59}
            min={0}
          />
          <label htmlFor="mandatoryBreakMin">{`분`}</label>
        </FormRow>
        <FormRow title="시작기준요일 ">
          <RadioContainer>
            <StyleInput
              type="radio"
              id="monday"
              minWidth="15px"
              maxWidth="15px"
              name="days"
              value={Day_Type.Mon}
              checked={day === Day_Type.Mon}
              onChange={() => {
                setDayOfWeekStart(Day_Type.Mon);
                if (dispatch) {
                  dispatch({ type: Day_Type.Mon });
                }
              }}
            />
            <label htmlFor="monday">월</label>
            <StyleInput
              type="radio"
              id="tuesday"
              minWidth="15px"
              maxWidth="15px"
              name="days"
              value={Day_Type.Tue}
              checked={day === Day_Type.Tue}
              onChange={() => {
                setDayOfWeekStart(Day_Type.Tue);

                if (dispatch) {
                  dispatch({ type: Day_Type.Tue });
                }
              }}
            />
            <label htmlFor="tuesday">화</label>
            <StyleInput
              type="radio"
              id="wednesday"
              minWidth="15px"
              maxWidth="15px"
              name="days"
              value={Day_Type.Wed}
              checked={day === Day_Type.Wed}
              onChange={() => {
                setDayOfWeekStart(Day_Type.Wed);
                if (dispatch) {
                  dispatch({ type: Day_Type.Wed });
                }
              }}
            />
            <label htmlFor="wednesday">수</label>
            <StyleInput
              type="radio"
              id="thursday"
              minWidth="15px"
              maxWidth="15px"
              name="days"
              value={Day_Type.Thr}
              checked={day === Day_Type.Thr}
              onChange={() => {
                setDayOfWeekStart(Day_Type.Thr);
                if (dispatch) {
                  dispatch({ type: Day_Type.Thr });
                }
              }}
            />
            <label htmlFor="thursday">목</label>
            <StyleInput
              type="radio"
              id="friday"
              minWidth="15px"
              maxWidth="15px"
              name="days"
              value={Day_Type.Fri}
              checked={day === Day_Type.Fri}
              onChange={() => {
                setDayOfWeekStart(Day_Type.Fri);
                if (dispatch) {
                  dispatch({ type: Day_Type.Fri });
                }
              }}
            />
            <label htmlFor="friday">금</label>
            <StyleInput
              type="radio"
              id="saturday"
              minWidth="15px"
              maxWidth="15px"
              name="days"
              value={Day_Type.Sat}
              checked={day === Day_Type.Sat}
              onChange={() => {
                setDayOfWeekStart(Day_Type.Sat);
                if (dispatch) {
                  dispatch({ type: Day_Type.Sat });
                }
              }}
            />
            <label htmlFor="saturday">토</label>
            <StyleInput
              type="radio"
              id="sunday"
              minWidth="15px"
              maxWidth="15px"
              name="days"
              value={Day_Type.Sun}
              checked={day === Day_Type.Sun}
              onChange={() => {
                setDayOfWeekStart(Day_Type.Sun);
                if (dispatch) {
                  dispatch({ type: Day_Type.Sun });
                }
              }}
            />
            <label htmlFor="sunday">일</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="시차">
          <SSelect
            id="mandatoryBreakTermHour"
            minWidth="50px"
            maxWidth="50px"
            value={sign}
            onChange={event => {
              setSign(event.currentTarget.value);
            }}
          >
            <option value={"+"}>{"+"}</option>
            <option value={"-"}>{"-"}</option>
          </SSelect>
          <SInput
            type="number"
            name="timeDifferenceMinForHour"
            id="timeDifferenceMinForHour"
            value={timeDifferenceMinForHour}
            onChange={event => {
              const value = event.currentTarget.value;
              setTimeDifferenceMinForHour(Number(value));
            }}
            max={24}
            min={0}
          />
          <label htmlFor="timeDifferenceMinForHour">{`시간`}</label>
          <SInput
            type="number"
            name="timeDifferenceMin"
            id="timeDifferenceMin"
            value={timeDifferenceMin}
            onChange={event => {
              const value = event.currentTarget.value;
              setTimeDifferenceMin(Number(value));
            }}
            max={59}
            min={0}
          />
          <label htmlFor="timeDifferenceMin">{`분`}</label>
        </FormRow>
        <FormRow title="근무 단위 설정">
          <SSelect
            id="workUnitSetting"
            minWidth="200px"
            maxWidth="200px"
            value={periodUnitType}
            onChange={event => {
              setPeriodUnitType(event.currentTarget.value as Period_Unit_Type);
            }}
          >
            <option value={Period_Unit_Type.Weekly}>{"주 단위 근무제"}</option>
            <option value={Period_Unit_Type.Monthly}>{"월 단위 근무제"}</option>
          </SSelect>
        </FormRow>
      </ContentContainer>
    </SectionContainer>
  );
}

export default memo(WSBasicInfo);
