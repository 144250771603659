import styled from "styled-components";

const ApprovalInput = styled.input<{
  minWidth?: string;
  maxWidth?: string;
  $width?: string;
}>`
  display: flex;
  flex: 1;
  width: ${props => props.$width ?? "max-content"};
`;

export default ApprovalInput;
