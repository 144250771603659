import { useState, useCallback } from "react";
import {
  EmployeeInformation,
  OtTimeTypeCategoryEntity
} from "../../../../../generated/graphql";
import { ICompactRow } from "../../../../../components/compact-table/compact-table-render-row";
import { IApprovalDelegateInfoWithCheck } from "../../tabs/approval-delegate-settings/constant";

export interface DelegateInfoState {
  selectedUser: EmployeeInformation | undefined;
  delegateUsers: EmployeeInformation[];
  absenceReasonList: OtTimeTypeCategoryEntity[];
  selectedAbsenceReason: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  isActive: boolean;
  note: string;
}

export default function useDelegateInfo(
  initialCategories: OtTimeTypeCategoryEntity[],
  todayFormattedString: string,
  aWeekAfterFormattedString: string
) {
  const [delegateInfo, setDelegateInfo] = useState<DelegateInfoState>({
    selectedUser: undefined,
    delegateUsers: [] as EmployeeInformation[],
    absenceReasonList: initialCategories,
    selectedAbsenceReason:
      initialCategories.length > 0 ? initialCategories[0].categoryId : -1,
    startDate: todayFormattedString,
    endDate: aWeekAfterFormattedString,
    startTime: "09:00",
    endTime: "18:00",
    isActive: true,
    note: ""
  });

  // 개별 필드 업데이트 함수
  const updateDelegateInfo = useCallback(
    (field: keyof DelegateInfoState, value: any) => {
      setDelegateInfo(prev => ({
        ...prev,
        [field]: value
      }));
    },
    []
  );

  // 이벤트 타입별 공통 핸들러 함수들
  const handleInputChange = useCallback(
    (field: keyof DelegateInfoState) =>
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateDelegateInfo(field, e.target.value);
      },
    [updateDelegateInfo]
  );

  const handleDirectValueChange = useCallback(
    (field: keyof DelegateInfoState) => (value: any) => {
      updateDelegateInfo(field, value);
    },
    [updateDelegateInfo]
  );

  // 사용자 추가 핸들러
  const handleAddUser = useCallback(
    (user?: ICompactRow<EmployeeInformation>) => {
      updateDelegateInfo("selectedUser", user?.original);
    },
    [updateDelegateInfo]
  );

  // 자주 사용되는 핸들러들
  const handleActiveChange = handleDirectValueChange("isActive");
  const handleNoteChange = handleInputChange("note");
  const handleStartDateChange = handleInputChange("startDate");
  const handleEndDateChange = handleInputChange("endDate");
  const handleStartTimeChange = handleInputChange("startTime");
  const handleEndTimeChange = handleInputChange("endTime");
  const handleAbsenceReasonChange =
    handleDirectValueChange("absenceReasonList");
  const handleSelectedAbsenceReasonChange = handleDirectValueChange(
    "selectedAbsenceReason"
  );
  const handleDelegateUserChange = handleDirectValueChange("delegateUsers");

  // 카테고리 업데이트 함수
  const updateCategories = useCallback(
    (categories: OtTimeTypeCategoryEntity[]) => {
      setDelegateInfo(prev => ({
        ...prev,
        absenceReasonList: categories,
        selectedAbsenceReason:
          categories.length > 0 ? categories[0]?.categoryId : -1
      }));
    },
    []
  );

  // 직접 값을 설정하는 함수 추가
  const setDelegateInfoValues = useCallback(
    (values: Partial<DelegateInfoState>) => {
      setDelegateInfo(prev => ({
        ...prev,
        ...values
      }));
    },
    []
  );

  return {
    delegateInfo,
    updateDelegateInfo,
    handleAddUser,
    handleActiveChange,
    handleNoteChange,
    handleStartDateChange,
    handleEndDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleAbsenceReasonChange,
    handleSelectedAbsenceReasonChange,
    handleDelegateUserChange,
    updateCategories,
    setDelegateInfoValues
  };
}
