import { useCallback } from "react";
import { useConfirmDialog } from "../../../confirm-dialog/useConfirmDialog";
import useAddEditApprovalDelegateInfo from "./useAddEditApprovalDelegateInfo";
import { DelegateInfoState } from "./useDelegateInfo";
import { AddEditMode } from "./useUrlParams";

interface IResultType {
  ok: boolean;
  error?: string | null;
  result: string;
}

export default function useDelegateSave(
  mode: AddEditMode,
  callEmployeeId: string,
  approvalAuthorityIdx: number,
  delegateInfo: DelegateInfoState
) {
  const { addInfo, editInfo } = useAddEditApprovalDelegateInfo();

  const isAddMode = mode === "add";
  let result: IResultType;

  const notifyParentWindow = useCallback(() => {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: "APPROVAL_DELEGATE_UPDATED"
        },
        window.location.origin
      );
    }
  }, []);

  // 저장 성공 후 처리를 위한 콜백
  // openSuccessDialog의 autoCloseMs 옵션이 사용이 되면 아래 콜백함수가 실행이 된다.
  const handleSaveSuccessAutoClose = useCallback(() => {
    notifyParentWindow();
    window.close();
  }, [notifyParentWindow]);

  // useConfirmDialog 훅 사용
  const { dialogConfig, openDialog, closeDialog, openSuccessDialog } =
    useConfirmDialog(handleSaveSuccessAutoClose);

  // 저장 확인 다이얼로그 열기
  const openSaveConfirmDialog = useCallback(() => {
    openDialog({
      type: "info",
      title: isAddMode ? "대결자 추가" : "대결자 수정",
      message: `${isAddMode ? "추가" : "수정"}하시겠습니까?`,
      showCancelButton: true
    });
  }, [isAddMode, openDialog]);

  // 저장 처리 함수
  const handleConfirmSave = useCallback(async () => {
    if (dialogConfig.type === "success") {
      closeDialog();
      return;
    }

    if (isAddMode) {
      result = await addInfo(
        delegateInfo.delegateUsers[0]?.employeeId,
        callEmployeeId,
        delegateInfo.selectedAbsenceReason,
        `${delegateInfo.startDate} ${delegateInfo.startTime}`,
        `${delegateInfo.endDate} ${delegateInfo.endTime}`,
        delegateInfo.isActive ? 1 : 2,
        delegateInfo.note
      );
    } else {
      result = await editInfo(
        approvalAuthorityIdx,
        delegateInfo.delegateUsers[0]?.employeeId,
        callEmployeeId,
        delegateInfo.selectedAbsenceReason,
        `${delegateInfo.startDate} ${delegateInfo.startTime}`,
        `${delegateInfo.endDate} ${delegateInfo.endTime}`,
        delegateInfo.isActive ? 1 : 2,
        delegateInfo.note
      );
    }

    closeDialog();

    const actionType = isAddMode ? "추가" : "수정";

    if (result.ok) {
      openSuccessDialog("완료", `성공적으로 ${actionType}되었습니다.`);
      notifyParentWindow();
    } else {
      // 에러 다이얼로그 표시
      openDialog({
        type: "error",
        title: "오류 발생",
        message: `대결자 ${actionType} 중 오류가 발생했습니다: ${
          result.error || "알 수 없는 오류"
        }`,
        showCancelButton: false
      });
    }
  }, [
    mode,
    addInfo,
    editInfo,
    approvalAuthorityIdx,
    delegateInfo,
    callEmployeeId,
    closeDialog,
    openSuccessDialog,
    openDialog,
    dialogConfig.type,
    notifyParentWindow
  ]);

  return {
    dialogConfig,
    openSaveConfirmDialog,
    handleConfirmSave,
    closeDialog
  };
}
