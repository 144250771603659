import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import EChannel from "../../../Utils/EChannel/e-channel";
import Button from "../../globalComponents/Button";
import { ApprovalType, IApprovalDetailMessage } from "../approval-detail-popup";
import ApprovalReceiveAndReferenceForm from "../approval-receive-and-reference-form";
import { IEmployeeInformationForReceiveAndReference } from "../approval-receive-and-reference-form/list-of-selected-approval-user-for-receive-and-reference-form";
import ApprovalProcessManagement from "../approval-setting/approval-process-management/approval-process-management";
import { IEmployeeInformation } from "./list-of-selected-approval-user";
import { colors } from "../../GlobalStyle/GlobalStyle";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex: 10;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

function ApprovalProcess() {
  const { formTemplateIdx } = useParams();
  const [isReceiveReference, setIsReceiveReference] = useState<boolean>(false);
  const bc = useMemo(() => new BroadcastChannel(EChannel.APPROVAL), []);
  const [listOfUser, setListOfUser] = useState<IEmployeeInformation[]>([]);
  const [
    listOfUserForReceiveAndReference,
    setListOfUserForReceiveAndReference
  ] = useState<IEmployeeInformationForReceiveAndReference[]>([]);
  const [initListForUser, setInitListForUser] = useState<
    IEmployeeInformationForReceiveAndReference[]
  >([]);
  const [initListForDepartment, setInitListForDepartment] = useState<
    IEmployeeInformationForReceiveAndReference[]
  >([]);

  const handleSetListOfUserForReceiveAndReference = useCallback(
    (list: IEmployeeInformationForReceiveAndReference[]) => {
      setListOfUserForReceiveAndReference(list);
    },
    []
  );

  const handleSetListOfUser = useCallback((list: IEmployeeInformation[]) => {
    setListOfUser(list);
  }, []);

  const handleSendData = useCallback(() => {
    const message: IApprovalDetailMessage = {
      type: ApprovalType.APPROVAL_PROCESS_POPUP,
      listOfEmployeeInformation: listOfUser,
      formTemplateIdx: formTemplateIdx?.slice(1)
    };
    bc.postMessage(message);
    window.close();
  }, [bc, listOfUser, formTemplateIdx]);

  const handleSendDataForReceiveAndReference = useCallback(() => {
    const message: IApprovalDetailMessage = {
      type: ApprovalType.APPROVAL_PROCESS_POPUP_FOR_RECEIVE_REFERENCE,
      listOfEmployeeInformationForReceiveAndReference:
        listOfUserForReceiveAndReference,
      formTemplateIdx: formTemplateIdx?.slice(1)
    };
    bc.postMessage(message);
    window.close();
  }, [bc, listOfUserForReceiveAndReference, formTemplateIdx]);

  const isBtnActive: boolean = useMemo(() => {
    if (listOfUser.length > 0) {
      return true;
    }
    if (listOfUserForReceiveAndReference.length > 0) {
      return true;
    }
    return false;
  }, [listOfUser, listOfUserForReceiveAndReference]);

  useEffect(() => {
    if (bc) {
      const newMessage: IApprovalDetailMessage = {
        type: ApprovalType.APPROVAL_RECEIVE_REFERENCE
      };
      bc.postMessage(newMessage);
      bc.onmessage = event => {
        const message: IApprovalDetailMessage = event.data;
        if (message.type === ApprovalType.REQUEST_APPROVAL_RECEIVE_REFERENCE) {
          if (message.listOfEmployeeInformationForReceiveAndReference) {
            const newInitListForUser: IEmployeeInformationForReceiveAndReference[] =
              [];
            const newInitListForDepartment: IEmployeeInformationForReceiveAndReference[] =
              [];
            message.listOfEmployeeInformationForReceiveAndReference.forEach(
              item => {
                if (item.employeeId) {
                  newInitListForUser.push(item);
                } else {
                  newInitListForDepartment.push(item);
                }
              }
            );
            setInitListForUser(newInitListForUser);
            setInitListForDepartment(newInitListForDepartment);
          }
          setIsReceiveReference(true);
        }
      };
      return () => {
        bc.close();
      };
    }
  }, [bc]);

  return (
    <Container>
      <Content>
        {isReceiveReference ? (
          // 수신 참조 폼
          <ApprovalReceiveAndReferenceForm
            handleSetListOfUserForReceiveAndReference={
              handleSetListOfUserForReceiveAndReference
            }
            initListForUser={initListForUser}
            initListForDepartment={initListForDepartment}
          />
        ) : (
          // 결재자 정보 폼
          <ApprovalProcessManagement
            handleSetListOfUser={handleSetListOfUser}
          />
        )}
      </Content>
      <ButtonContainer>
        <Button
          disabled={!isBtnActive}
          onClick={
            isReceiveReference
              ? handleSendDataForReceiveAndReference
              : handleSendData
          }
        >
          적용
        </Button>
        <Button
          backgroundColor={colors.darkRed}
          hoverBackgroundColor={colors.lightRed}
          onClick={() => window.close()}
        >
          닫기
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default ApprovalProcess;
