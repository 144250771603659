import { useState, useEffect, useCallback } from "react";
import { UserEntity, useGetUserLazyQuery } from "../../../../generated/graphql";

export function useUserInfo(employeeId?: string) {
  const [user, setUser] = useState<UserEntity>();

  const [getUser, { data: userData }] = useGetUserLazyQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
    }
  });

  useEffect(() => {
    if (userData?.getUser.user) {
      setUser(userData?.getUser.user[0]);
    }
  }, [userData]);

  const fetchUserInfo = useCallback(
    (employeeId: string) => {
      getUser({
        variables: {
          getUserId: employeeId,
          like: false
        }
      });
    },
    [getUser]
  );

  const departmentName = user?.fullDepartmentName?.split(">").at(-1) || "";

  return {
    user,
    departmentName,
    fetchUserInfo
  };
}
