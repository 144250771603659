import { useCallback, useState } from "react";
import {
  AddAbsenceManagementInfoMutationVariables,
  AddAutonomyWorkingHourInfoMutationVariables,
  AddCommuteRecordInfoMutationVariables,
  AddControlNotificationMutationVariables,
  AddEtcOperatingHoursMutationVariables,
  AddSelfDeterminedWorkHoursMutationVariables,
  AddWidgetSettingInfoMutationVariables,
  Day_Type,
  Is_Use,
  OtWorkingFlexibleBasicEntity,
  OtWorkingFlexibleHoursEntity,
  OtWorkingStandardHoursEntity,
  Period_Unit_Type,
  Setting_Enable_For_Working_Template,
  Work_Type,
  WtBasicInfoEntity
} from "../../generated/graphql";

export interface IWorkPolicyBasicSetting {
  __typename: string;
  workingTemplateIdx: number;
  name: string;
  workType: Work_Type;
  otHoursTypeCategoryIdx: number;
  dayMaxWorkHour: number;
  weekMaxWorkHour: number;
  weekMaxExtWorkHour: number;
  mandatoryBreakTermHour: number;
  mandatoryBreakMin: number;
  dayOfWeekStart: Day_Type;
  otHolidayTemplateNewAutoUse: Is_Use;
  timeDifferenceMin: number;
  periodUnitType: Period_Unit_Type;
  webTemplateWorkTimeUse: Setting_Enable_For_Working_Template;
}

export interface IWorkPolicyAdditionalSetting {
  nightReferenceStartTimeRange: string;
  nightReferenceEndTimeRange: string;
  lateCheckTime: string;
  periodUnitMonthMinType: Setting_Enable_For_Working_Template;
  periodUnitMonthFixMin: number;
  standardDailyStartTimeRange: string;
  standardDailyEndTimeRange: string;
  weeklyHolidayAllowanceUse: Setting_Enable_For_Working_Template;
  workingTemplateIdxMinors: number;
  limitExtworkminDay: number;
  limitExtworkminWeek: number;
  limitExtworkminMonth: number;
}

function useAsonicWorkingSystem() {
  const [weekTotalWorkHour, setWeekTotalWorkHour] = useState(0);
  const [workingTemplateIdx, setWorkingTemplateIdx] = useState<
    number | undefined
  >();
  const handleWorkingTemplateIdx = useCallback((id: number) => {
    setWorkingTemplateIdx(id);
  }, []);

  // 근무 정책 설정 정보 (기본설정, 추가설정)
  // 두개의 탭으로 분리되면서 이렇게 나누게 됨
  const [wSBasicInfo, setWSBasicInfo] = useState<WtBasicInfoEntity>();
  const handleWSBasicInfo = useCallback((payload: WtBasicInfoEntity) => {
    setWSBasicInfo(payload);
  }, []);

  // 근무 정책 설정 정보 (기본설정, 추가설정)
  const [workPolicyBasicSetting, setWorkPolicyBasicSetting] =
    useState<IWorkPolicyBasicSetting>();
  const [workPolicyAdditionalSetting, setWorkPolicyAdditionalSetting] =
    useState<IWorkPolicyAdditionalSetting>();
  const handleWorkPolicyBasicSetting = useCallback(
    (payload: IWorkPolicyBasicSetting) => {
      setWorkPolicyBasicSetting(payload);
    },
    []
  );
  const handleWorkPolicyAdditionalSetting = useCallback(
    (payload: IWorkPolicyAdditionalSetting) => {
      setWorkPolicyAdditionalSetting(payload);
    },
    []
  );
  //////////////////////////////////////////////
  const [isSelfBasicWorkType, setIsSelfBasicWorkType] =
    useState<boolean>(false);
  const [otHolidayTemplateNewAutoUse, setOtHolidayTemplateNewAutoUse] =
    useState<Is_Use>(Is_Use.UnUse);

  const handleOtHolidayTemplateNewAutoUse = useCallback((value: Is_Use) => {
    setOtHolidayTemplateNewAutoUse(value);
  }, []);

  const handleIsSelfBasicWorkType = useCallback((type: boolean) => {
    setIsSelfBasicWorkType(type);
  }, []);

  const [otWorkingStandardHours, setOtWorkingStandardHours] =
    useState<OtWorkingStandardHoursEntity>();

  const handleOtWorkingStandardHours = useCallback(
    (payload: OtWorkingStandardHoursEntity) => {
      setOtWorkingStandardHours(payload);
    },
    []
  );

  const [otWorkingStandardHourList, setOtWorkingStandardHourList] = useState<
    OtWorkingStandardHoursEntity[]
  >([]);
  const handleOtWorkingStandardHourList = useCallback(
    (list: OtWorkingStandardHoursEntity[]) => {
      setOtWorkingStandardHourList(list);
    },
    []
  );

  const [otWorkingFlexibleBasic, setOtWorkingFlexibleBasic] =
    useState<OtWorkingFlexibleBasicEntity>();
  const [otWorkingFlexibleHours, setOtWorkingFlexibleHours] = useState<
    OtWorkingFlexibleHoursEntity[]
  >([]);

  const [selfDeterminedWorkHourBasicInfo, setSelfDeterminedWorkHourBasicInfo] =
    useState<AddSelfDeterminedWorkHoursMutationVariables>();

  const [workingTimeName, setWorkingTimeName] = useState<string[]>([]);

  const [autonomyWorkingHourInfo, setAutonomyWorkingHourInfo] =
    useState<AddAutonomyWorkingHourInfoMutationVariables>();

  const [commuteRecordInfo, setCommuteRecordInfo] =
    useState<AddCommuteRecordInfoMutationVariables>();

  const [controlNotiInfo, setControlNotInfo] =
    useState<AddControlNotificationMutationVariables>();

  const [absenceManagementInfo, setAbsenceManagementInfo] =
    useState<AddAbsenceManagementInfoMutationVariables>();

  const [widgetSettingInfo, setWidgetSettingInfo] =
    useState<AddWidgetSettingInfoMutationVariables>();

  const [etcOperatingHours, setEtcOperatingHours] =
    useState<AddEtcOperatingHoursMutationVariables>();

  return {
    workingTemplateIdx,
    handleWorkingTemplateIdx,
    wSBasicInfo,
    handleWSBasicInfo,
    workPolicyBasicSetting,
    workPolicyAdditionalSetting,
    handleWorkPolicyBasicSetting,
    handleWorkPolicyAdditionalSetting,
    isSelfBasicWorkType,
    otHolidayTemplateNewAutoUse,
    handleOtHolidayTemplateNewAutoUse,
    handleIsSelfBasicWorkType,
    otWorkingStandardHours,
    handleOtWorkingStandardHours,
    otWorkingStandardHourList,
    handleOtWorkingStandardHourList,
    otWorkingFlexibleBasic,
    setOtWorkingFlexibleBasic,
    otWorkingFlexibleHours,
    setOtWorkingFlexibleHours,
    selfDeterminedWorkHourBasicInfo,
    setSelfDeterminedWorkHourBasicInfo,
    workingTimeName,
    setWorkingTimeName,
    autonomyWorkingHourInfo,
    setAutonomyWorkingHourInfo,
    commuteRecordInfo,
    setCommuteRecordInfo,
    controlNotiInfo,
    setControlNotInfo,
    absenceManagementInfo,
    setAbsenceManagementInfo,
    widgetSettingInfo,
    setWidgetSettingInfo,
    etcOperatingHours,
    setEtcOperatingHours,
    weekTotalWorkHour,
    setWeekTotalWorkHour
  };
}

export default useAsonicWorkingSystem;
