import { useEffect, useState } from "react";
import {
  EmployeeInformation,
  GroupEntity
} from "../../../../generated/graphql";
import Button from "../../../../components/globalComponents/Button";
import { colors } from "../../../../components/GlobalStyle/GlobalStyle";
import {
  ButtonContainer,
  HorizontalContainer,
  MainContainer
} from "./styles/container";
import { SelectionTitle } from "./styles/title";
import ApprovalDelegateSelectionLeft from "./left-view/ApprovalDelegateSelectionLeft";
import AddDeleteButtons from "./center-view/AddDeleteButtons";
import ApprovalDelegateSelectionRight from "./right-view/ApprovalDelegateSelectionRight";
import { useWeekPeriodStartEndDate } from "../../../../components/main-view/WorkManagementInfo/hooks";
import useFetchTimeTypeCategories from "./hooks/useFetchTimeTypeCategories";
import ConfirmDialog from "../../confirm-dialog/ConfirmDialog";
import useDelegateInfo from "./hooks/useDelegateInfo";
import useDelegateTable from "./hooks/useDelegateTable";
import useDelegateSave from "./hooks/useDelegateSave";
import useUrlParams from "./hooks/useUrlParams";

function ApprovalDelegateSelection() {
  const { mode, callEmployeeId, approvalAuthorityIdx, originDelegateInfo } =
    useUrlParams();

  const isEditMode = mode === "edit";

  const [selectedDepartment, setSelectedDepartment] = useState<GroupEntity>();

  const handleSelectedDepartment = (department: GroupEntity) => {
    setSelectedDepartment(department);
  };

  const { aWeekAfterFormattedString, todayFormattedString } =
    useWeekPeriodStartEndDate();

  const { categories } = useFetchTimeTypeCategories();

  const {
    delegateInfo,
    handleAddUser,
    handleActiveChange,
    handleNoteChange,
    handleStartDateChange,
    handleEndDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleAbsenceReasonChange,
    handleSelectedAbsenceReasonChange,
    handleDelegateUserChange,
    updateCategories,
    setDelegateInfoValues
  } = useDelegateInfo(
    categories,
    todayFormattedString,
    aWeekAfterFormattedString
  );

  // 카테고리 업데이트
  useEffect(() => {
    if (categories.length > 0) {
      updateCategories(categories);
    }
    if (originDelegateInfo) {
      // originDelegateInfo정보를 EmployeeInformation 타입으로 변환
      // originDelegateInfo 없는 정보는 공백으로 처리
      const delegateUser: EmployeeInformation = {
        employeeId: originDelegateInfo.employeeIdSubstitute,
        name: originDelegateInfo.substituteName,
        departName: originDelegateInfo.substituteDepartment,
        userPosition: originDelegateInfo.substitutePosition,
        approvalDefine: 0,
        asTimeTemplateName: "",
        col1: "",
        col2: "",
        col3: "",
        col4: "",
        col5: "",
        col6: "",
        col7: "",
        col8: "",
        col9: "",
        col10: "",
        col11: "",
        col12: "",
        col13: "",
        col14: "",
        col15: "",
        departmentId: "",
        endpointCount: 0,
        fullDepartmentName: "",
        insertType: 0,
        workingName: ""
      };

      const selectedAbsenceReason = categories.find(
        category => category.categoryId === originDelegateInfo.categoryId
      );
      // 직접 값 설정 함수를 사용하여 값 설정
      setDelegateInfoValues({
        note: originDelegateInfo.descr,
        isActive: originDelegateInfo.useFlag === 1,
        delegateUsers: [delegateUser],
        startDate:
          originDelegateInfo.startDatetime?.split(" ")[0] ||
          todayFormattedString,
        endDate:
          originDelegateInfo.endDatetime?.split(" ")[0] ||
          aWeekAfterFormattedString,
        startTime: originDelegateInfo.startDatetime?.split(" ")[1] || "09:00",
        endTime: originDelegateInfo.endDatetime?.split(" ")[1] || "18:00",
        // 위에서 받아온 OtTimeTypeCategoryEntity 중에서 해당 카테고리 아이디를 찾는다.
        // 같은 categoryId가 없는 경우 제일 첫번째 카테고리로 설정
        selectedAbsenceReason:
          selectedAbsenceReason?.categoryId || categories[0]?.categoryId || -1
      });
    }
  }, [
    categories,
    originDelegateInfo,
    updateCategories,
    setDelegateInfoValues,
    todayFormattedString,
    aWeekAfterFormattedString
  ]);

  const table = useDelegateTable(delegateInfo.delegateUsers);

  const {
    dialogConfig,
    openSaveConfirmDialog,
    handleConfirmSave,
    closeDialog
  } = useDelegateSave(mode, callEmployeeId, approvalAuthorityIdx, delegateInfo);

  return (
    <MainContainer>
      <SelectionTitle>
        {isEditMode ? "대결자 수정" : "대결자 추가"}
      </SelectionTitle>
      <HorizontalContainer>
        <ApprovalDelegateSelectionLeft
          handleSelectedDepartment={handleSelectedDepartment}
          handleAddUser={handleAddUser}
          selectedDepartment={selectedDepartment}
        />
        <AddDeleteButtons
          handleDelegateUserChange={handleDelegateUserChange}
          selectedUser={delegateInfo.selectedUser}
          delegateUsers={delegateInfo.delegateUsers}
        />
        <ApprovalDelegateSelectionRight
          table={table}
          isActive={delegateInfo.isActive}
          handleActiveChange={handleActiveChange}
          note={delegateInfo.note}
          handleNoteChange={handleNoteChange}
          startDate={delegateInfo.startDate}
          endDate={delegateInfo.endDate}
          startTime={delegateInfo.startTime}
          endTime={delegateInfo.endTime}
          absenceReasonList={delegateInfo.absenceReasonList}
          selectedAbsenceReason={delegateInfo.selectedAbsenceReason}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          handleStartTimeChange={handleStartTimeChange}
          handleEndTimeChange={handleEndTimeChange}
          handleAbsenceReasonChange={handleAbsenceReasonChange}
          handleSelectedAbsenceReasonChange={handleSelectedAbsenceReasonChange}
        />
      </HorizontalContainer>
      <ButtonContainer>
        <Button
          disabled={!delegateInfo.delegateUsers.length}
          onClick={openSaveConfirmDialog}
        >
          {isEditMode ? "수정" : "추가"}
        </Button>
        <Button
          backgroundColor={colors.darkRed}
          hoverBackgroundColor={colors.lightRed}
          onClick={() => window.close()}
        >
          닫기
        </Button>
      </ButtonContainer>

      <ConfirmDialog
        isOpen={dialogConfig.isOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        type={dialogConfig.type}
        showCancelButton={dialogConfig.showCancelButton}
        confirmText={dialogConfig.confirmText}
        cancelText={dialogConfig.cancelText}
        onConfirm={handleConfirmSave}
        onCancel={closeDialog}
      />
    </MainContainer>
  );
}

export default ApprovalDelegateSelection;
