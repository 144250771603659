import React, { Suspense, lazy, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { RootDiv } from "./custom-theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import routes from "./routes";
import { lightTheme } from "./components/GlobalStyle/GlobalStyle";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import listOfApolloVar from "./apollo/apollo-var";
import {
  Chart as ChartJS,
  LineController,
  CategoryScale,
  BarElement,
  LineElement,
  ArcElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useDrop } from "react-dnd";
import Chat, { initChatRoomName } from "./components/chat/chat";
import MasterWorkManagementPage from "./components/main-view/master-work-management-page";
import PersonalWorkDashboardPage from "./components/main-view/personal-work-dashboard-page";
import WorkManagementInfoContainer from "./containers/main-view/WorkManagementInfoContainer";
import SuperAdminSettingsPage from "./components/super-admin-settings-page";
import VehicleDrivingManagementScreen from "./screens/vehicle-driving-management.screen";
import ReservationManagementScreen from "./screens/reservation-management.screen";
import AsonicCalendarMonth from "./components/asonic-calendar/asonic-calendar-month";
import Approval from "./screens/approval";
import { SettingsContainer } from "./containers";
import StatsContainer from "./components/main-view/statistics/StatsContainer";
import MobileSummaryContainer from "./moContainer/main-view/MobileSummaryContainer";
import MobileRequestContainer from "./moContainer/main-view/MobileRequestContainer";
import MobileApprovalContainer from "./moContainer/main-view/MobileApprovalContainer";
import MobileSettingContainer from "./moContainer/main-view/MobileSettingContainer";
import ApprovalDashBoard from "./components/approval/approval-dash-board";
import ApprovalList from "./components/approval/approval-list/approval-list";
import ApprovalTemporaryDocument from "./components/approval/approval-temporary-document";
import ApprovalSetting from "./components/approval/approval-setting/approval-setting";
import ApprovalDetailPopup from "./components/approval/approval-detail-popup";
import SelectApprovalFormPopup from "./components/approval/select-approval-form-popup/select-approval-form-popup";
import ApprovalProcess from "./components/approval/approval-process";
import ApprovalRequestDetailPopup from "./components/approval/approval-request-detail-popup/approval-request-detail-popup";
import AnnualSettingScreen from "./screens/annual-setting.screen";
import ServiceStatus from "./components/electron/service-status/service-status";
import CalendarInWidget from "./components/electron/calendar-in-widget/calendar-in-widget";
import WorkingSystemScreen from "./screens/view-settings/work-schedule-settings/working_system.screen";
import AsonicWorkingSystemScreen from "./screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import MobileWorkInfo from "./components/mobile/mobile_work_info/mobile_work_info";
import { MobileMainViewContainer } from "./moContainer";
import ApprovalSelectUsersTreeAndListView from "./components/approval/approval-setting/select-user/approval-select-users-tree-and-list-view";
import ApprovalSelectUsersOnNewWindow from "./components/approval/approval-setting/select-user/approval-select-users-on-new-window";
import { LoadingProvider } from "./screens/components/loading-context";
import { LoadingSpinner } from "./screens/components/loading-spinner";
import ApprovalDelegateSelection from "./screens/approval-management/approval-settings/open-windows/ApprovalDelegateSelection";

ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const ElectronMainPage = React.lazy(() => import("./components/electron"));

const ChartContainer = React.lazy(
  () => import("./components/main-view/ChartContainer")
);
const MainViewContainer = lazy(
  () => import("./containers/main-view/MainViewContainer")
);

const SignInContainer = lazy(
  () => import("./containers/sign-in/SignInContainer")
);
const RoomsContainer = lazy(() => import("./containers/WebRTC/RoomsContainer"));
// DatePicker에서 달력이나 시간 ui를 custom하기위한 function

const QUERY_GET_CHAT_SERVER_IP = gql`
  query GetChatServerIp {
    getChatServerIp {
      ok
      error
      chatServerIp
    }
  }
`;

const App = () => {
  const approvalData = useReactiveVar(listOfApolloVar.approvalDataVar);
  const approvalType = useReactiveVar(listOfApolloVar.approvalTypeVar);

  const { data } = useQuery(QUERY_GET_CHAT_SERVER_IP);
  const [, drop] = useDrop(() => ({
    accept: initChatRoomName,
    drop(_, monitor) {
      const alpha = monitor.getInitialSourceClientOffset();
      const delta = monitor.getDifferenceFromInitialOffset();
      if (delta?.x && delta.y && alpha) {
        const left = `${Math.round(alpha.x + delta.x)}px`;
        const top = `${Math.round(alpha.y + delta.y)}px`;

        listOfApolloVar.chatPosition({
          top,
          left
        });
      }
      return undefined;
    },
    collect: monitor => ({
      isOver: !monitor.isOver()
    })
  }));

  useEffect(() => {
    if (data?.getChatServerIp.ok) {
      if (data.getChatServerIp.chatServerIp) {
        listOfApolloVar.chatServerIp(data.getChatServerIp.chatServerIp);
      }
    }
  }, [data]);

  const renderRoutes = (routesArray: any) => {
    return routesArray.map(({ path, element, children }: any) => (
      <Route key={path} path={path} element={element}>
        {children && renderRoutes(children)}
      </Route>
    ));
  };

  const mainRoutes = [
    { path: routes.pageRoutes.home, element: <MasterWorkManagementPage /> },
    {
      path: routes.pageRoutes.personal,
      element: <PersonalWorkDashboardPage />
    },
    {
      path: routes.pageRoutes.workManagementInformation,
      element: <WorkManagementInfoContainer />
    },
    {
      path: routes.pageRoutes.superAdminSettings,
      element: <SuperAdminSettingsPage />
    },
    {
      path: routes.pageRoutes.vehicleDrivingManagement,
      element: <VehicleDrivingManagementScreen />
    },
    {
      path: routes.pageRoutes.reservationManagement,
      element: <ReservationManagementScreen />
    },
    { path: routes.pageRoutes.calendar, element: <AsonicCalendarMonth /> },
    {
      path: routes.pageRoutes.approval,
      element: <Approval />,
      children: [
        {
          path: routes.pageRoutes.approvalDashboard,
          element: <ApprovalDashBoard />
        },
        { path: routes.pageRoutes.approvalList, element: <ApprovalList /> },
        {
          path: routes.pageRoutes.approvalTemporaryDocument,
          element: <ApprovalTemporaryDocument />
        },
        {
          path: routes.pageRoutes.approvalSetting,
          element: <ApprovalSetting />
        }
      ]
    },
    { path: routes.pageRoutes.settings, element: <SettingsContainer /> },
    {
      path: routes.pageRoutes.annualEnvSetting,
      element: <AnnualSettingScreen />
    },
    { path: routes.pageRoutes.workingSystem, element: <WorkingSystemScreen /> },
    {
      path: routes.pageRoutes.statisticsUser,
      element: <StatsContainer isAdmin={false} />
    },
    {
      path: routes.pageRoutes.statisticsAdmin,
      element: <StatsContainer isAdmin={true} />
    }
  ];

  const mobileRoutes = [
    { path: routes.pageRoutes.mobileHome, element: <MobileSummaryContainer /> },
    {
      path: routes.pageRoutes.mobileRequest,
      element: <MobileRequestContainer />
    },
    {
      path: routes.pageRoutes.mobileApproval,
      element: <MobileApprovalContainer />
    },
    {
      path: routes.pageRoutes.mobileSettings,
      element: <MobileSettingContainer />
    }
  ];

  return (
    <RecoilRoot>
      <ThemeProvider theme={lightTheme}>
        <LoadingProvider>
          <RootDiv ref={drop}>
            <BrowserRouter>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  {renderRoutes([
                    {
                      path: routes.pageRoutes.electron,
                      element: <ElectronMainPage />,
                      children: [
                        {
                          path: routes.pageRoutes.electronServiceStatus,
                          element: <ServiceStatus />
                        },
                        {
                          path: routes.pageRoutes.electronCalendar,
                          element: <CalendarInWidget />
                        },
                        {
                          path: routes.pageRoutes.electronChat,
                          element: <Chat />
                        }
                      ]
                    },
                    {
                      path: routes.pageRoutes.approvalPopup,
                      element: (
                        <ApprovalDetailPopup
                          approvalData={approvalData}
                          newApprovalType={approvalType}
                        />
                      )
                    },
                    {
                      path: routes.pageRoutes.approvalRequestPopup,
                      element: (
                        <ApprovalRequestDetailPopup
                          newApprovalType={approvalType}
                        />
                      )
                    },
                    {
                      path: routes.pageRoutes.selectApprovalFormPopup,
                      element: <SelectApprovalFormPopup />
                    },
                    {
                      path: `${routes.pageRoutes.approvalProcess}:formTemplateIdx`,
                      element: <ApprovalProcess />
                    },
                    {
                      path: `${routes.pageRoutes.approvalDelegateSelection}`,
                      element: <ApprovalDelegateSelection />
                    },
                    {
                      path: `${routes.pageRoutes.approvalSelectUsers}:formTemplateIdx`,
                      element: <ApprovalSelectUsersOnNewWindow />
                    },
                    {
                      path: `${routes.pageRoutes.main}${routes.pageRoutes.chart}`,
                      element: <ChartContainer />
                    },
                    {
                      path: `${routes.pageRoutes.main}${routes.pageRoutes.rtc}`,
                      element: <RoomsContainer />
                    },
                    {
                      path: routes.pageRoutes.main,
                      element: <MainViewContainer />,
                      children: mainRoutes
                    },
                    {
                      path: `${routes.pageRoutes.mobileWorkInfo}/:id`,
                      element: <MobileWorkInfo />
                    },
                    {
                      path: routes.pageRoutes.mobile,
                      element: <MobileMainViewContainer />,
                      children: mobileRoutes
                    },
                    {
                      path: routes.pageRoutes.noAuth,
                      element: <SignInContainer />
                    },
                    {
                      path: routes.pageRoutes.root,
                      element: <SignInContainer />
                    },
                    {
                      path: `${routes.pageRoutes.asonicWorkingSystem}/:id`,
                      element: <AsonicWorkingSystemScreen />
                    },
                    {
                      path: `${routes.pageRoutes.asonicWorkingSystem}`,
                      element: <AsonicWorkingSystemScreen />
                    },
                    { path: "*", element: <SignInContainer /> }
                  ])}
                </Routes>
              </Suspense>
            </BrowserRouter>
            <Chat />
          </RootDiv>
          <LoadingSpinner />
        </LoadingProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default App;
