import { useMemo, useState } from "react";
import * as ReactTable from "react-table";
import { useSticky } from "react-table-sticky";
import { IApprovalDelegateInfoWithCheck } from "../constant";
import { useApprovalDelegateColumns } from "../components/ApprovalDelegateColumns";

interface UseApprovalDelegateTableProps {
  list: IApprovalDelegateInfoWithCheck[];
  sFixedColumnNumber: number;
}

export function useApprovalDelegateTable({
  list,
  sFixedColumnNumber
}: UseApprovalDelegateTableProps) {
  const [columnVisibility, setColumnVisibility] = useState({});

  const columns = useApprovalDelegateColumns({ sFixedColumnNumber });

  const table = ReactTable.useTable<IApprovalDelegateInfoWithCheck>(
    {
      columns,
      data: list,
      state: {
        columnVisibility
      },
      onColumnVisibilityChange: setColumnVisibility
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const selectedRow = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }
    return undefined;
  }, [table.selectedFlatRows]);

  const selectedRowAuthorityIdx = useMemo(() => {
    return table.selectedFlatRows.map(row => row.original.approvalAuthorityIdx);
  }, [table.selectedFlatRows]);

  return {
    table,
    selectedRow,
    selectedRowAuthorityIdx,
    columnVisibility,
    setColumnVisibility
  };
}
