import styled from "styled-components";

export const Title = styled.span`
  display: flex;
  flex: 1;
  user-select: none;
  display: flex;
  font-weight: bold;
  min-width: max-content;
  font-size: 14px;
`;

export const SubTitle = styled.span`
  font-weight: bold;
  padding-right: 5px;
  padding-left: 5px;
`;
