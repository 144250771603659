import SectionContainer from "../../../../shared/section_container/section_container";
import ContentContainer from "../../../../shared/grid_content_container/grid_content_container";
import FormRow from "../../../../shared/form-row/form-row";
import StyleInput from "../../../../inputs/style-input";
import RadioContainer from "../../../../shared/radio_container/radio_container";
import SSelect from "../../../../shared/s_select/s_select";
import useTimeList, {
  HOUR_TYPE
} from "../../../../../hooks/use_time_list/use_time_list";
import * as ReactTable from "react-table";
import {
  AddSelfDeterminedWorkHourTemplateMutationVariables,
  Break_Time_Type,
  Day_Type,
  Exact,
  Is_Use,
  Operating_Period_Type,
  OtSelfWorkingStandardHoursEntity,
  Work_Day_Of_Week,
  Work_Hours_Setting_Type,
  useGetSelfDeterminedWorkTemplateHoursLazyQuery
} from "../../../../../generated/graphql";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import handleApolloLog from "../../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import useTimeControl from "../../../../../hooks/use_time_control/use_time_control";
import { WS_STATE } from "../../../../../screens/view-settings/work-schedule-settings/add-update/asonic-working-system.screen";
import { SInput } from "../choice_basic_w_s/choice_basic_w_s";

interface IProps {
  selectedRow?: ReactTable.Row<OtSelfWorkingStandardHoursEntity>;
  setTemplate: React.Dispatch<
    React.SetStateAction<
      Exact<AddSelfDeterminedWorkHourTemplateMutationVariables> | undefined
    >
  >;
}

function ChoiceFormWS({ selectedRow, setTemplate }: IProps) {
  const wSState = useContext(WS_STATE);
  const { hourList, hourTypeList } = useTimeList();
  const { displayHours, makeHoursType, makeHours, handleMinute } =
    useTimeControl();
  const [workingTimeName, setWorkingTimeName] = useState<string>(
    selectedRow?.original.workingTimeName ?? ""
  );
  const [operatingPeriodType, setOperatingPeriodType] =
    useState<Operating_Period_Type>(Operating_Period_Type.Always);
  const [operatingPeriodStartDate, setOperatingPeriodStartDate] =
    useState<string>(moment().format("YYYY-MM-DD"));
  const [operatingPeriodEndDate, setOperatingPeriodEndDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [dayOfWeekType, setDayOfWeekType] = useState<Work_Day_Of_Week>(
    Work_Day_Of_Week.MonToFri
  );
  const [dayOfWeek, setDayOfWeek] = useState<Day_Type[]>([
    Day_Type.Mon,
    Day_Type.Tue,
    Day_Type.Wed,
    Day_Type.Thr,
    Day_Type.Fri
  ]);

  const [workHoursType, setWorkHoursType] = useState<Work_Hours_Setting_Type>(
    Work_Hours_Setting_Type.DesignatedTime
  );

  const [startTime, setStartTime] = useState<string>("09:00");
  const [endTime, setEndTime] = useState<string>("18:00");
  const [dutyWorkHoursType, setDutyWorkHoursType] = useState<Is_Use>(
    Is_Use.UnUse
  );
  const [dutyStartTime, setDutyStartTime] = useState<string>("09:00");
  const [dutyEndTime, setDutyEndTime] = useState<string>("18:00");
  const [startBreakTime, setStartBreakTime] = useState<string>("12:00");

  const [endBreakTime, setEndBreakTime] = useState<string>("13:00");

  const [getSelfDeterminedWTH] = useGetSelfDeterminedWorkTemplateHoursLazyQuery(
    {
      onError(error) {
        handleApolloLog(error);
      },
      onCompleted(data) {
        if (data.getSelfDeterminedWorkTemplateHours.template) {
          const {
            workingTimeName,
            operatingPeriodType,
            operatingPeriodStartDate,
            operatingPeriodEndDate,
            dayOfWeekType,
            dayOfWeek,
            startTime,
            endTime,
            workHoursType,
            dutyWorkHoursType,
            dutyStartTime,
            dutyEndTime,
            startBreakTime,
            endBreakTime
          } = data.getSelfDeterminedWorkTemplateHours.template;
          setWorkingTimeName(workingTimeName ?? "");
          setOperatingPeriodType(
            operatingPeriodType ?? Operating_Period_Type.SetPeriod
          );
          setOperatingPeriodStartDate(
            operatingPeriodStartDate ?? moment().format("YYYY-MM-DD")
          );
          setOperatingPeriodEndDate(
            operatingPeriodEndDate ?? moment().format("YYYY-MM-DD")
          );
          setDayOfWeekType(dayOfWeekType ?? Work_Day_Of_Week.MonToFri);
          setDayOfWeek(dayOfWeek ?? []);

          setStartTime(startTime);
          setEndTime(endTime);

          setWorkHoursType(
            workHoursType ?? Work_Hours_Setting_Type.DesignatedTime
          );

          setDutyWorkHoursType(dutyWorkHoursType ?? Is_Use.UnUse);
          setDutyStartTime(dutyStartTime);
          setDutyEndTime(dutyEndTime);
          setStartBreakTime(startBreakTime);
          setEndBreakTime(endBreakTime);
        }
      }
    }
  );

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      setTemplate({
        workingTemplateIdx: wSState?.workingTemplateIdx,
        workingTimeName,
        operatingPeriodType,
        operatingPeriodStartDate,
        operatingPeriodEndDate,
        dayOfWeekType,
        dayOfWeek,
        workHoursType,
        startTime,
        endTime,
        dutyWorkHoursType,
        dutyStartTime,
        dutyEndTime,
        breakTimeType: Break_Time_Type.Basic,
        startBreakTime,
        endBreakTime
      });
    }
  }, [
    setTemplate,
    workingTimeName,
    operatingPeriodType,
    operatingPeriodStartDate,
    operatingPeriodEndDate,
    dayOfWeekType,
    dayOfWeek,
    workHoursType,
    startTime,
    endTime,
    dutyWorkHoursType,
    dutyStartTime,
    dutyEndTime,
    startBreakTime,
    endBreakTime
  ]);

  useEffect(() => {
    if (selectedRow?.original.workingTemplateIdx) {
      getSelfDeterminedWTH({
        variables: {
          workingTemplateIdx: selectedRow?.original.workingTemplateIdx,
          workingTimeName: selectedRow?.original.workingTimeName
        }
      });
    }
  }, [getSelfDeterminedWTH, selectedRow, wSState?.workingTemplateIdx]);

  return (
    <SectionContainer disableOverflowY>
      <ContentContainer flex={0}>
        <FormRow title="근무 템플릿 명" gridNumber={1}>
          <StyleInput
            minWidth="99%"
            maxWidth="99%"
            value={workingTimeName}
            onChange={event => {
              setWorkingTimeName(event.currentTarget.value);
            }}
          />
        </FormRow>
        <FormRow title="운영기간 설정">
          <RadioContainer>
            <StyleInput
              type="radio"
              id="periodSetting"
              minWidth="20px"
              maxWidth="20px"
              name="operatingTimeSetting"
              checked={operatingPeriodType === Operating_Period_Type.SetPeriod}
              onChange={() => {
                setOperatingPeriodType(Operating_Period_Type.SetPeriod);
              }}
            />
            <label htmlFor="periodSetting">{`기간설정`}</label>
            <StyleInput
              type="radio"
              id="always"
              minWidth="20px"
              maxWidth="20px"
              name="operatingTimeSetting"
              checked={operatingPeriodType === Operating_Period_Type.Always}
              onChange={() => {
                setOperatingPeriodType(Operating_Period_Type.Always);
              }}
            />
            <label htmlFor="always">{`상시적용`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="운영 기간">
          <StyleInput
            type="date"
            value={operatingPeriodStartDate}
            onChange={event => {
              setOperatingPeriodStartDate(event.currentTarget.value);
            }}
            disabled={operatingPeriodType === Operating_Period_Type.Always}
          />
          <span>{`~`}</span>
          <StyleInput
            type="date"
            value={operatingPeriodEndDate}
            onChange={event => {
              setOperatingPeriodEndDate(event.currentTarget.value);
            }}
            disabled={operatingPeriodType === Operating_Period_Type.Always}
          />
        </FormRow>
        <FormRow title={"근무요일 설정"}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id="weekDay"
              minWidth="20px"
              maxWidth="20px"
              name="daySetting"
              checked={dayOfWeekType === Work_Day_Of_Week.MonToFri}
              onChange={() => {
                setDayOfWeekType(Work_Day_Of_Week.MonToFri);
                setDayOfWeek([
                  Day_Type.Mon,
                  Day_Type.Tue,
                  Day_Type.Wed,
                  Day_Type.Thr,
                  Day_Type.Fri
                ]);
              }}
            />
            <label htmlFor="weekDay">{`지정(월~금)`}</label>
            <StyleInput
              type="radio"
              id="weekDayWithSat"
              minWidth="20px"
              maxWidth="20px"
              name="daySetting"
              checked={dayOfWeekType === Work_Day_Of_Week.MonToSat}
              onChange={() => {
                setDayOfWeekType(Work_Day_Of_Week.MonToSat);
                setDayOfWeek([
                  Day_Type.Mon,
                  Day_Type.Tue,
                  Day_Type.Wed,
                  Day_Type.Thr,
                  Day_Type.Fri,
                  Day_Type.Sat
                ]);
              }}
            />
            <label htmlFor="weekDayWithSat">{`지정(월~토)`}</label>
            <StyleInput
              type="radio"
              id="directSelect"
              minWidth="20px"
              maxWidth="20px"
              name="daySetting"
              checked={dayOfWeekType === Work_Day_Of_Week.InputMethod}
              onChange={() => {
                setDayOfWeekType(Work_Day_Of_Week.InputMethod);
              }}
            />
            <label htmlFor="directSelect">{`지정(직접선택)`}</label>
            <StyleInput
              type="radio"
              id="self"
              minWidth="20px"
              maxWidth="20px"
              name="daySetting"
              checked={dayOfWeekType === Work_Day_Of_Week.SelfForWorker}
              onChange={() => {
                setDayOfWeekType(Work_Day_Of_Week.SelfForWorker);
              }}
            />
            <label htmlFor="self">{`근무자 자율편성`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"지정 근무 요일"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="monday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Mon)}
              disabled={dayOfWeekType !== Work_Day_Of_Week.InputMethod}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Mon)) {
                    return list.filter(day => day !== Day_Type.Mon);
                  }
                  return [...list, Day_Type.Mon];
                });
              }}
            />
            <label htmlFor="monday">{`월`}</label>
            <StyleInput
              type="checkbox"
              id="tuesday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Tue)}
              disabled={dayOfWeekType !== Work_Day_Of_Week.InputMethod}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Tue)) {
                    return list.filter(day => day !== Day_Type.Tue);
                  }
                  return [...list, Day_Type.Tue];
                });
              }}
            />
            <label htmlFor="tuesday">{`화`}</label>
            <StyleInput
              type="checkbox"
              id="wednesday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Wed)}
              disabled={dayOfWeekType !== Work_Day_Of_Week.InputMethod}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Wed)) {
                    return list.filter(day => day !== Day_Type.Wed);
                  }
                  return [...list, Day_Type.Wed];
                });
              }}
            />
            <label htmlFor="wednesday">{`수`}</label>
            <StyleInput
              type="checkbox"
              id="thursday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Thr)}
              disabled={dayOfWeekType !== Work_Day_Of_Week.InputMethod}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Thr)) {
                    return list.filter(day => day !== Day_Type.Thr);
                  }
                  return [...list, Day_Type.Thr];
                });
              }}
            />
            <label htmlFor="thursday">{`목`}</label>
            <StyleInput
              type="checkbox"
              id="friday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Fri)}
              disabled={dayOfWeekType !== Work_Day_Of_Week.InputMethod}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Fri)) {
                    return list.filter(day => day !== Day_Type.Fri);
                  }
                  return [...list, Day_Type.Fri];
                });
              }}
            />
            <label htmlFor="friday">{`금`}</label>
            <StyleInput
              type="checkbox"
              id="saturday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Sat)}
              disabled={dayOfWeekType !== Work_Day_Of_Week.InputMethod}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Sat)) {
                    return list.filter(day => day !== Day_Type.Sat);
                  }
                  return [...list, Day_Type.Sat];
                });
              }}
            />
            <label htmlFor="saturday">{`토`}</label>
            <StyleInput
              type="checkbox"
              id="sunday"
              minWidth="20px"
              maxWidth="20px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Sun)}
              disabled={dayOfWeekType !== Work_Day_Of_Week.InputMethod}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Sun)) {
                    return list.filter(day => day !== Day_Type.Sun);
                  }
                  return [...list, Day_Type.Sun];
                });
              }}
            />
            <label htmlFor="sunday">{`일`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="근무시간 설정">
          <RadioContainer>
            <StyleInput
              type="radio"
              id="designatedPeriod"
              minWidth="20px"
              maxWidth="20px"
              name="workTimeSetting"
              checked={workHoursType === Work_Hours_Setting_Type.DesignatedTime}
              onChange={() => {
                setWorkHoursType(Work_Hours_Setting_Type.DesignatedTime);
              }}
            />
            <label htmlFor="designatedPeriod">{`지정 시간`}</label>
            <StyleInput
              type="radio"
              id="workTimeSelf"
              minWidth="20px"
              maxWidth="20px"
              name="workTimeSetting"
              checked={workHoursType === Work_Hours_Setting_Type.SelfForWorker}
              onChange={() => {
                setWorkHoursType(Work_Hours_Setting_Type.SelfForWorker);
              }}
            />
            <label htmlFor="workTimeSelf">{`근무자 자율편성`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="지정 근무시간">
          <SSelect
            name="startHourType"
            id="startHourType"
            minWidth="60px"
            maxWidth="60px"
            disabled={workHoursType === Work_Hours_Setting_Type.SelfForWorker}
            value={
              parseInt(startTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="startHour"
            id="startHour"
            minWidth="50px"
            maxWidth="50px"
            disabled={workHoursType === Work_Hours_Setting_Type.SelfForWorker}
            value={displayHours(startTime.split(":")[0])}
            onChange={event => {
              const hours = startTime.split(":")[0];
              const minute = startTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startHour">{`시`}</label>
          <SInput
            type="number"
            name="startMin"
            id="startMin"
            disabled={workHoursType === Work_Hours_Setting_Type.SelfForWorker}
            value={startTime.split(":")[1].padStart(2, "0")}
            onChange={event => {
              const value = event.currentTarget.value;
              const minute = handleMinute(value);
              setStartTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            max={59}
            min={0}
          />
          <label htmlFor="startMin">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            name="endHourType"
            id="endHourType"
            minWidth="60px"
            maxWidth="60px"
            disabled={workHoursType === Work_Hours_Setting_Type.SelfForWorker}
            value={
              parseInt(endTime.split(":")[0]) < 12 ? HOUR_TYPE.AM : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="endHour"
            id="endHour"
            minWidth="50px"
            maxWidth="50px"
            disabled={workHoursType === Work_Hours_Setting_Type.SelfForWorker}
            value={displayHours(endTime.split(":")[0])}
            onChange={event => {
              const hours = endTime.split(":")[0];
              const minute = endTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endHour">{`시`}</label>
          <SInput
            type="number"
            name="endMin"
            id="endMin"
            disabled={workHoursType === Work_Hours_Setting_Type.SelfForWorker}
            value={endTime.split(":")[1].padStart(2, "0")}
            onChange={event => {
              const value = event.currentTarget.value;
              const minute = handleMinute(value);
              setEndTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            max={59}
            min={0}
          />
          <label htmlFor="endMin">{`분`}</label>
        </FormRow>
        <FormRow title="의무 근무시간">
          <RadioContainer>
            <StyleInput
              type="radio"
              id="useMandatoryWorkTime"
              minWidth="20px"
              maxWidth="20px"
              name="isMandatoryWorkTime"
              checked={dutyWorkHoursType === Is_Use.Use}
              onChange={() => {
                setDutyWorkHoursType(Is_Use.Use);
              }}
            />
            <label htmlFor="useMandatoryWorkTime">{`사용`}</label>
            <StyleInput
              type="radio"
              id="unusedMandatoryWorkTime"
              minWidth="20px"
              maxWidth="20px"
              name="isMandatoryWorkTime"
              checked={dutyWorkHoursType === Is_Use.UnUse}
              onChange={() => {
                setDutyWorkHoursType(Is_Use.UnUse);
              }}
            />
            <label htmlFor="unusedMandatoryWorkTime">{`미사용`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="의무 근무시간">
          <SSelect
            name="mandatoryStartHourType"
            id="mandatoryStartHourType"
            minWidth="60px"
            maxWidth="60px"
            disabled={dutyWorkHoursType === Is_Use.UnUse}
            value={
              parseInt(dutyStartTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: dutyStartTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setDutyStartTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="mandatoryStartHour"
            id="mandatoryStartHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(dutyStartTime.split(":")[0])}
            disabled={dutyWorkHoursType === Is_Use.UnUse}
            onChange={event => {
              const hours = dutyStartTime.split(":")[0];
              const minute = dutyStartTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setDutyStartTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="mandatoryStartHour">{`시`}</label>
          <SInput
            type="number"
            name="mandatoryStartMin"
            id="mandatoryStartMin"
            value={dutyStartTime.split(":")[1].padStart(2, "0")}
            disabled={dutyWorkHoursType === Is_Use.UnUse}
            onChange={event => {
              const value = event.currentTarget.value;
              const minute = handleMinute(value);
              setDutyStartTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            max={59}
            min={0}
          />
          <label htmlFor="mandatoryStartMin">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            name="mandatoryEndHourType"
            id="mandatoryEndHourType"
            minWidth="60px"
            maxWidth="60px"
            disabled={dutyWorkHoursType === Is_Use.UnUse}
            value={
              parseInt(dutyEndTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: dutyEndTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setDutyEndTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="mandatoryEndHour"
            id="mandatoryEndHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(dutyEndTime.split(":")[0])}
            disabled={dutyWorkHoursType === Is_Use.UnUse}
            onChange={event => {
              const hours = dutyEndTime.split(":")[0];
              const minute = dutyEndTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setDutyEndTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="mandatoryEndHour">{`시`}</label>
          <SInput
            type="number"
            name="mandatoryEndMin"
            id="mandatoryEndMin"
            disabled={dutyWorkHoursType === Is_Use.UnUse}
            value={dutyEndTime.split(":")[1].padStart(2, "0")}
            onChange={event => {
              const value = event.currentTarget.value;
              const minute = handleMinute(value);
              setDutyEndTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            max={59}
            min={0}
          />
          <label htmlFor="mandatoryEndMin">{`분`}</label>
        </FormRow>
        <FormRow title="휴게시간 선택" gridNumber={1}>
          <SSelect
            name="startBreakTimeType"
            id="startBreakTimeType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(startBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartBreakTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="startBreakTime"
            id="startBreakTime"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(startBreakTime.split(":")[0])}
            onChange={event => {
              const hours = startBreakTime.split(":")[0];
              const minute = startBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartBreakTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startBreakTimeType">{`시`}</label>
          <SInput
            type="number"
            name="startBreakTimeMinute"
            id="startBreakTimeMinute"
            value={startBreakTime.split(":")[1].padStart(2, "0")}
            onChange={event => {
              const value = event.currentTarget.value;
              const minute = handleMinute(value);
              setStartBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            max={59}
            min={0}
          />
          <label htmlFor="startBreakTimeMinute">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            name="endBreakTimeType"
            id="endBreakTimeType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(endBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndBreakTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="endBreakTime"
            id="endBreakTime"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(endBreakTime.split(":")[0])}
            onChange={event => {
              const hours = endBreakTime.split(":")[0];
              const minute = endBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndBreakTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endBreakTime">{`시`}</label>
          <SInput
            type="number"
            name="endBreakTimeMinute"
            id="endBreakTimeMinute"
            value={endBreakTime.split(":")[1].padStart(2, "0")}
            onChange={event => {
              const value = event.currentTarget.value;
              const minute = handleMinute(value);
              setEndBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
            max={59}
            min={0}
          />
          <label htmlFor="endBreakTimeMinute">{`분`}</label>
        </FormRow>
      </ContentContainer>
    </SectionContainer>
  );
}

export default ChoiceFormWS;
