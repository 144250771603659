import styled from "styled-components";

export const SelectionTitle = styled.h2`
  margin: 0 0 4px 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 10px;
  text-align: center;
`;

export const Name = styled.span`
  display: flex;
  flex: 1;
  min-width: max-content;
  flex-wrap: nowrap;
  white-space: nowrap;
`;
