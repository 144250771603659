import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import Edit from "@iconify/icons-mdi/file-document-edit-outline";
import List from "@iconify/icons-clarity/list-line";
import Cog from "@iconify/icons-clarity/cog-line";
import routes from "../routes";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  background-color: ${props => props.theme.colors.lightGrey};
  min-height: 80px;
  max-height: 80px;
  height: 80px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 8;
`;

const Menu = styled.div`
  position: relative;
  display: flex;
  font-size: 13px;
  color: ${props => props.theme.colors.darkGrey};
  font-weight: bold;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
    color: ${props => props.theme.colors.white};
    ::after {
      border-color: ${props => props.theme.colors.white};
    }
  }
  :after {
    position: absolute;
    content: "";
    border-right: 1px solid ${props => props.theme.colors.grey};
    height: 10px;
    right: 0px;
    bottom: 50%;
  }
  :active {
    background-color: ${props => props.theme.colors.green};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 40px;
`;

const Approval = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <MenuContainer>
        <Menu
          onClick={() => {
            const width = 1050;
            const height = 1030;
            const left = (window.screen.width - width) / 2;
            const top = (window.screen.height - height) / 2;

            window.open(
              routes.pageRoutes.selectApprovalFormPopup,
              "",
              `width=${width}, height=${height}, left=${left}, top=${top}, scrollbars=yes`
            );
          }}
        >
          <IconContainer>
            <Icon icon={Edit} />
          </IconContainer>
          <span>결재신청</span>
        </Menu>
        <Menu
          onClick={() => {
            navigate(routes.pageRoutes.approvalList, {
              state: { type: "listWaiting" }
            });
          }}
        >
          <IconContainer>
            <Icon icon={List} />
          </IconContainer>
          <span>결재목록</span>
        </Menu>
        <Menu
          onClick={() => {
            navigate(routes.pageRoutes.approvalList, {
              state: { type: "receiveAndReference" }
            });
          }}
        >
          <IconContainer>
            <Icon icon={List} />
          </IconContainer>
          <span>수신/참조</span>
        </Menu>
        <Menu
          onClick={() => {
            navigate(routes.pageRoutes.approvalSetting);
          }}
        >
          <IconContainer>
            <Icon icon={Cog} />
          </IconContainer>
          <span>결재설정</span>
        </Menu>
      </MenuContainer>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

export default Approval;
