import CryptoJS from "crypto-js";

/**
 * URL 파라미터 암호화 및 복호화를 위한 유틸리티 클래스
 */
class UrlParamsCrypto {
  private secretKey: string = "!iMadeItThroughTheRain@";

  /**
   * 객체를 암호화된 문자열로 변환 (URL 안전 인코딩 적용)
   * @param params 암호화할 파라미터 객체
   * @returns URL 안전한 암호화된 문자열
   */
  encrypt(params: Record<string, any>): string {
    const paramsString = JSON.stringify(params);
    const encrypted = CryptoJS.AES.encrypt(
      paramsString,
      this.secretKey
    ).toString();
    // URL 안전한 형식으로 인코딩 (+ → -, / → _, = → 제거)
    return this.encodeForUrl(encrypted);
  }

  /**
   * 암호화된 문자열을 URL 안전하게 인코딩
   * @param str 암호화된 문자열
   * @returns URL 안전한 문자열
   */
  private encodeForUrl(str: string): string {
    return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  }

  /**
   * URL 안전 인코딩된 문자열을 원래 형식으로 디코딩
   * @param str URL 안전 인코딩된 문자열
   * @returns 원래 암호화 형식의 문자열
   */
  private decodeFromUrl(str: string): string {
    // URL 안전 형식에서 원래 형식으로 복원 (- → +, _ → /)
    let result = str.replace(/-/g, "+").replace(/_/g, "/");

    // = 패딩 복원
    const paddingLength = 4 - (result.length % 4);
    if (paddingLength < 4) {
      result += "=".repeat(paddingLength);
    }

    return result;
  }

  /**
   * 암호화된 문자열을 원래 객체로 복호화
   * @param encryptedString URL 안전 인코딩된 암호화 문자열
   * @returns 복호화된 객체
   */
  decrypt(encryptedString: string): Record<string, any> {
    try {
      // URL 안전 인코딩에서 원래 암호화 형식으로 복원
      const originalEncrypted = this.decodeFromUrl(encryptedString);

      const decryptedBytes = CryptoJS.AES.decrypt(
        originalEncrypted,
        this.secretKey
      );
      const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedString);
    } catch (error) {
      console.error("Failed to decrypt URL params:", error);
      throw new Error("Invalid encrypted data");
    }
  }

  /**
   * URL 쿼리 파라미터에서 암호화된 데이터를 추출하고 복호화
   * @param url URL 문자열 또는 URLSearchParams 객체
   * @param paramName 암호화된 데이터가 저장된 파라미터 이름 (기본값: 'data')
   * @returns 복호화된 객체, 파라미터가 없거나 복호화 실패 시 null
   */
  decryptFromUrl(
    url: string | URLSearchParams,
    paramName: string = "data"
  ): Record<string, any> | null {
    try {
      const searchParams =
        typeof url === "string"
          ? new URLSearchParams(url.split("?")[1] || "")
          : url;

      const encryptedData = searchParams.get(paramName);
      if (!encryptedData) return null;

      return this.decrypt(encryptedData);
    } catch (error) {
      console.error("Failed to extract and decrypt URL params:", error);
      return null;
    }
  }
}

export default UrlParamsCrypto;
