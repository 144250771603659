import styled from "styled-components";
import StyledColumnContainer from "../../shared/styled_column_container";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import AsonicTab from "../../shared/asonic-tab";
import useAsonicTab, { ITab } from "../../../hooks/hooks-shared/use-asonic-tab";
import {
  Is_Show,
  UserMonthlyWorkPlanEntity,
  useGetMobilWebViewInfoLazyQuery,
  useGetUserLazyQuery,
  useGetUserMonthlyWorkPlanLazyQuery
} from "../../../generated/graphql";
import MMonthlyWorkInfo from "./m_monthly_work_info";
import moment from "moment";
import MApprovalInfo from "../m_approval_info/m_approval_info";

const Tabs = {
  MONTHLY_WORK_INFO: "월간근무정보",
  APPROVAL_INFO: "결재정보"
} as const;

type Tabs = typeof Tabs;

type KTabs = keyof typeof Tabs;

const Container = styled(StyledColumnContainer)`
  padding: 10px;
`;

function MobileWorkInfo() {
  const [isMonthlyTab, setIsMonthlyTab] = useState<boolean>(true);
  const [user, setUser] = useState<
    Partial<UserMonthlyWorkPlanEntity> | undefined
  >();
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const params = useParams();
  const { list, selectedTab, handleSelectTab } = useAsonicTab<Tabs, KTabs>({
    tabs: Tabs
  });

  const [tabList, setTabList] =
    useState<ITab<"MONTHLY_WORK_INFO" | "APPROVAL_INFO">[]>(list);

  const removeMonthlyTab = useCallback(() => {
    handleSelectTab("APPROVAL_INFO");
    setIsMonthlyTab(false);
    setTabList(pre => {
      const newList: ITab<"MONTHLY_WORK_INFO" | "APPROVAL_INFO">[] = pre.filter(
        item => item.key !== "MONTHLY_WORK_INFO"
      );
      return newList;
    });
  }, [handleSelectTab]);

  const [getMobilWebViewInfo] = useGetMobilWebViewInfoLazyQuery({
    onError(error) {
      console.log(error);
      removeMonthlyTab();
    },
    onCompleted(data) {
      if (data.getMobilWebViewInfo.ok && data.getMobilWebViewInfo.webViewInfo) {
        if (
          data.getMobilWebViewInfo.webViewInfo.appWebViewMonthWorkInfoStatus ===
          Is_Show.Show
        ) {
          setIsMonthlyTab(true);
        } else {
          removeMonthlyTab();
        }
      } else if (data.getMobilWebViewInfo.error) {
        console.log(data.getMobilWebViewInfo.error);
        removeMonthlyTab();
      }
    }
  });

  const [getUser] = useGetUserLazyQuery({
    onCompleted(data) {
      if (data.getUser.ok && data.getUser.user) {
        const originalUserData = data.getUser.user[0];
        const departmentName = originalUserData.fullDepartmentName ?? "";
        const employeeId = originalUserData.employeeId ?? params.id ?? "";
        const employeeName = originalUserData.name ?? "";
        setUser(pre => {
          if (pre) {
            return {
              departmentName,
              employeeId,
              employeeName,
              ...pre
            };
          } else {
            return {
              departmentName,
              employeeId,
              employeeName
            };
          }
        });
      }
    }
  });
  const [getUserMonthlyWorkPlan] = useGetUserMonthlyWorkPlanLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getUserMonthlyWorkPlan.ok && data.getUserMonthlyWorkPlan.list) {
        const {
          endTime,
          inCheckDatetime,
          isCheckStatus,
          isClosingStatus,
          isSendStatus,
          monthLastDay,
          monthStartDay,
          outCheckDatetime,
          payTime,
          recognitionAdmitTime,
          recognitionPlanTime,
          restAdmitMin,
          restPlanMin,
          startTime
        } = data.getUserMonthlyWorkPlan.list[0];

        setUser(pre => {
          if (pre) {
            return {
              ...pre,
              endTime,
              inCheckDatetime,
              isCheckStatus,
              isClosingStatus,
              isSendStatus,
              monthLastDay,
              monthStartDay,
              outCheckDatetime,
              payTime,
              recognitionAdmitTime,
              recognitionPlanTime,
              restAdmitMin,
              restPlanMin,
              startTime
            };
          } else {
            return {
              endTime,
              inCheckDatetime,
              isCheckStatus,
              isClosingStatus,
              isSendStatus,
              monthLastDay,
              monthStartDay,
              outCheckDatetime,
              payTime,
              recognitionAdmitTime,
              recognitionPlanTime,
              restAdmitMin,
              restPlanMin,
              startTime
            };
          }
        });
      } else if (data.getUserMonthlyWorkPlan.error) {
        console.log(data.getUserMonthlyWorkPlan.error);
      }
    }
  });

  useEffect(() => {
    if (params.id) {
      getUser({
        variables: {
          getUserId: params.id,
          like: false
        }
      });
    }
  }, [params]);

  useEffect(() => {
    if (params.id) {
      const { id } = params;
      if (id) {
        getUserMonthlyWorkPlan({
          variables: {
            callEmployeeId: id,
            employeeIdList: [id],
            startDate: startDate,
            endDate: endDate
          }
        });
      }
    }
  }, [params, getUserMonthlyWorkPlan, startDate, endDate]);

  useEffect(() => {
    getMobilWebViewInfo();
  }, [getMobilWebViewInfo]);

  return (
    <Container>
      <AsonicTab<KTabs>
        list={tabList}
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
      />
      {selectedTab === "MONTHLY_WORK_INFO" && isMonthlyTab && (
        <MMonthlyWorkInfo
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          user={user}
        />
      )}
      {selectedTab === "APPROVAL_INFO" && (
        <MApprovalInfo employeeId={user?.employeeId} />
      )}
    </Container>
  );
}

export default MobileWorkInfo;
