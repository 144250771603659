import styled from "styled-components";
import StyledFlexContainer from "../../../../../components/shared/styled_flex_container";

export const Container = styled.div<{ $isVisibility: boolean }>`
  display: flex;
  flex: ${props => (props.$isVisibility ? "1" : "0")};
  overflow-x: hidden;
  flex-direction: column;
  visibility: ${props => (props.$isVisibility ? "visible" : "hidden")};
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

export const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

export const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;

export const FileContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  > div {
    height: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
`;

export const CheckBoxContainer = styled(StyledFlexContainer)`
  justify-content: center;
  gap: 0.5em;
`;
