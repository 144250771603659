import { useCallback } from "react";
import { MessageTypes } from "../../../../../components/toast-message/toast-message";
import {
  useAddApprovalDelegateInfoMutation,
  useEditApprovalDelegateInfoMutation
} from "../../../../../generated/graphql";

// Define the interface to match what ApprovalDelegateSelection expects
interface IResultType {
  ok: boolean;
  error?: string | null;
  result: string;
}

function useAddEditApprovalDelegateInfo() {
  const [addApprovalDelegateInfo, { loading: addLoading, error: addError }] =
    useAddApprovalDelegateInfoMutation({
      fetchPolicy: "network-only",
      onError(error) {
        console.error("Failed to add/edit approval delegate info:", error);
      },
      onCompleted(data) {}
    });

  const [editApprovalDelegateInfo, { loading: editLoading, error: editError }] =
    useEditApprovalDelegateInfoMutation({
      fetchPolicy: "network-only",
      onError(error) {
        console.error("Failed to edit approval delegate info:", error);
      },
      onCompleted(data) {}
    });

  const addInfo = useCallback(
    async (
      employeeIdSubstitute: string,
      employeeIdAbsence: string,
      categoryId: number,
      startDatetime: string,
      endDatetime: string,
      useFlag: number,
      descr: string
    ): Promise<IResultType> => {
      try {
        const result = await addApprovalDelegateInfo({
          variables: {
            employeeIdSubstitute,
            employeeIdAbsence,
            categoryId,
            startDatetime,
            endDatetime,
            useFlag,
            descr
          }
        });
        const response = result.data?.addApprovalDelegateInfo;
        return {
          ok: response?.ok ?? false,
          error: response?.error ?? null,
          result: response?.result ?? ""
        };
      } catch (error) {
        return {
          ok: false,
          error: error instanceof Error ? error.message : String(error),
          result: ""
        };
      }
    },
    [addApprovalDelegateInfo]
  );

  const editInfo = useCallback(
    async (
      approvalAuthorityIdx: number,
      employeeIdSubstitute: string,
      employeeIdAbsence: string,
      categoryId: number,
      startDatetime: string,
      endDatetime: string,
      useFlag: number,
      descr: string
    ): Promise<IResultType> => {
      try {
        const result = await editApprovalDelegateInfo({
          variables: {
            approvalAuthorityIdx,
            employeeIdSubstitute,
            employeeIdAbsence,
            categoryId,
            startDatetime,
            endDatetime,
            useFlag,
            descr
          }
        });
        const response = result.data?.editApprovalDelegateInfo;
        return {
          ok: response?.ok ?? false,
          error: response?.error ?? null,
          result: response?.result ?? ""
        };
      } catch (error) {
        return {
          ok: false,
          error: error instanceof Error ? error.message : String(error),
          result: ""
        };
      }
    },
    [editApprovalDelegateInfo]
  );

  return {
    addInfo,
    editInfo,
    loading: addLoading || editLoading,
    error: addError || editError
  };
}

export default useAddEditApprovalDelegateInfo;
