import styled from "styled-components";
import WSTabs from "./bottom-tabs/w_s_tabs";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";
import {
  AddAbsenceManagementInfoMutationVariables,
  AddAutonomyWorkingHourInfoMutationVariables,
  AddCommuteRecordInfoMutationVariables,
  AddControlNotificationMutationVariables,
  AddEtcOperatingHoursMutationVariables,
  AddSelfDeterminedWorkHoursMutationVariables,
  AddWidgetSettingInfoMutationVariables,
  Day_Type,
  Exact,
  Is_Use,
  OtWorkingFlexibleBasicEntity,
  OtWorkingFlexibleHoursEntity,
  OtWorkingStandardHoursEntity,
  Setting_Enable_For_Working_Template,
  Work_Type,
  WtBasicInfoEntity,
  useAddAbsenceManagementInfoMutation,
  useAddAutonomyWorkingHourInfoMutation,
  useAddCommuteRecordInfoMutation,
  useAddControlNotificationMutation,
  useAddEtcOperatingHoursMutation,
  useAddFlexibleWorkingHourInfoMutation,
  useAddSelfDeterminedWorkHoursMutation,
  useAddStandardWtBasicInfoMutation,
  useAddWidgetSettingInfoMutation,
  useAddWtBasicInfoMutation,
  useUpdateWtBasicInfoMutation
} from "../../../../generated/graphql";
import { useParams } from "react-router-dom";
import handleApolloErrorLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../Utils/handle_error_log/handle_error_log";
import ReactLoading from "react-loading";
import { colors } from "../../../../components/GlobalStyle/GlobalStyle";
import useAsonicWorkingSystem, {
  IWorkPolicyAdditionalSetting,
  IWorkPolicyBasicSetting
} from "../../../../hooks/use_asonic_working_system/use_asonic_working_system";
import TopTabs from "./top-tabs/top-tabs";
import ToastMessage, {
  MessageTypes
} from "../../../../components/toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";

interface IReducer {
  day: Day_Type;
}

interface IWSStore extends IReducer {
  dispatch?: React.Dispatch<{
    type: Day_Type;
  }>;
}

export const SELECTED_SUB_TAB = {
  WORKING_TIME: "WORKING_TIME",
  RECORDING_TIME: "RECORDING_TIME",
  CONTROL_ALARM: "CONTROL_ALARM",
  LEAVE_WORK_MANAGEMENT: "LEAVE_WORK_MANAGEMENT",
  ETC_OPERATING_TIME: "ETC_OPERATING_TIME",
  WIDGET_SETTING: "WIDGET_SETTING"
} as const;

export type SELECTED_SUB_TAB =
  (typeof SELECTED_SUB_TAB)[keyof typeof SELECTED_SUB_TAB];

interface IWSState {
  isSave: boolean;
  handleIsSave: (value: boolean) => void;
  workingTemplateIdx?: number;
  isSelfBasicWorkType?: boolean;
  workPolicyBasicSetting?: IWorkPolicyBasicSetting;
  workPolicyAdditionalSetting?: IWorkPolicyAdditionalSetting;
  handleWorkPolicyBasicSetting: (payload: IWorkPolicyBasicSetting) => void;
  handleWorkPolicyAdditionalSetting: (
    payload: IWorkPolicyAdditionalSetting
  ) => void;
  handleIsSelfBasicWorkType: (type: boolean) => void;
  handleOtHolidayTemplateNewAutoUse: (value: Is_Use) => void;
  otHolidayTemplateNewAutoUse: Is_Use;
  otWorkingStandardHours: OtWorkingStandardHoursEntity | undefined;
  handleOtWorkingStandardHours: (payload: OtWorkingStandardHoursEntity) => void;
  handleOtWorkingStandardHourList: (
    list: OtWorkingStandardHoursEntity[]
  ) => void;
  setOtWorkingFlexibleBasic: React.Dispatch<
    React.SetStateAction<OtWorkingFlexibleBasicEntity | undefined>
  >;
  setOtWorkingFlexibleHours: React.Dispatch<
    React.SetStateAction<OtWorkingFlexibleHoursEntity[]>
  >;
  setSelfDeterminedWorkHourBasicInfo: React.Dispatch<
    React.SetStateAction<
      AddSelfDeterminedWorkHoursMutationVariables | undefined
    >
  >;
  setWorkingTimeName: React.Dispatch<React.SetStateAction<string[]>>;
  setAutonomyWorkingHourInfo: React.Dispatch<
    React.SetStateAction<
      Exact<AddAutonomyWorkingHourInfoMutationVariables> | undefined
    >
  >;
  setCommuteRecordInfo: React.Dispatch<
    React.SetStateAction<
      Exact<AddCommuteRecordInfoMutationVariables> | undefined
    >
  >;
  setControlNotInfo: React.Dispatch<
    React.SetStateAction<
      Exact<AddControlNotificationMutationVariables> | undefined
    >
  >;
  setAbsenceManagementInfo: React.Dispatch<
    React.SetStateAction<
      Exact<AddAbsenceManagementInfoMutationVariables> | undefined
    >
  >;
  setWidgetSettingInfo: React.Dispatch<
    React.SetStateAction<
      Exact<AddWidgetSettingInfoMutationVariables> | undefined
    >
  >;
  setEtcOperatingHours: React.Dispatch<
    React.SetStateAction<
      Exact<AddEtcOperatingHoursMutationVariables> | undefined
    >
  >;
  weekTotalWorkHour?: number;
  setWeekTotalWorkHour: React.Dispatch<React.SetStateAction<number>>;
  selectedSubTab: SELECTED_SUB_TAB;
  setSelectedSubTab: React.Dispatch<React.SetStateAction<SELECTED_SUB_TAB>>;
}

export const WS_STORE = createContext<IWSStore>({
  day: Day_Type.Mon
});

export const WS_STATE = createContext<IWSState | undefined>(undefined);

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  background-color: ${props => props.theme.colors.lightSkyBlue};
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  flex-basis: 100%;
`;

const LoadingContainer = styled.div<{ isLoading?: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-basis: ${props => (props.isLoading ? "100%" : "0%")};
  visibility: ${props => (props.isLoading ? "visible" : "hidden")};
  left: 45%;
  top: 40%;
  z-index: 2;
`;

const ContentContainer = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex: ${props => (props.isLoading ? "0" : "1")};
  flex-direction: column;
  visibility: ${props => (props.isLoading ? "hidden" : "visible")};
`;

function AsonicWorkingSystemScreen() {
  const { id } = useParams();
  const [isSave, setIsSave] = useState<boolean>(false);
  const [selectedSubTab, setSelectedSubTab] = useState<SELECTED_SUB_TAB>(
    SELECTED_SUB_TAB.WORKING_TIME
  );
  const {
    workingTemplateIdx,
    handleWorkingTemplateIdx,
    workPolicyBasicSetting,
    handleWorkPolicyBasicSetting,
    workPolicyAdditionalSetting,
    handleWorkPolicyAdditionalSetting,
    isSelfBasicWorkType,
    otHolidayTemplateNewAutoUse,
    handleOtHolidayTemplateNewAutoUse,
    handleIsSelfBasicWorkType,
    otWorkingStandardHours,
    handleOtWorkingStandardHours,
    otWorkingStandardHourList,
    handleOtWorkingStandardHourList,
    otWorkingFlexibleBasic,
    setOtWorkingFlexibleBasic,
    otWorkingFlexibleHours,
    setOtWorkingFlexibleHours,
    selfDeterminedWorkHourBasicInfo,
    setSelfDeterminedWorkHourBasicInfo,
    workingTimeName,
    setWorkingTimeName,
    autonomyWorkingHourInfo,
    setAutonomyWorkingHourInfo,
    commuteRecordInfo,
    setCommuteRecordInfo,
    controlNotiInfo,
    setControlNotInfo,
    absenceManagementInfo,
    setAbsenceManagementInfo,
    widgetSettingInfo,
    setWidgetSettingInfo,
    etcOperatingHours,
    setEtcOperatingHours,
    weekTotalWorkHour,
    setWeekTotalWorkHour
  } = useAsonicWorkingSystem();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [workType, setWorkType] = useState<Work_Type>(
    Work_Type.SFCommutingPolicy
  );

  const handleIsSave = useCallback((value: boolean) => {
    setIsSave(value);
  }, []);

  const reducer = useCallback((_: IReducer, action: { type: Day_Type }) => {
    return {
      day: action.type
    };
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    day: Day_Type.Mon
  });

  const handleWorkType = useCallback((payload: Work_Type) => {
    setWorkType(payload);
  }, []);

  const [addWTBasicInfo, { loading: loading1, client }] =
    useAddWtBasicInfoMutation({
      onError(error) {
        handleApolloErrorLog(error);
        handleToast("근무제를 추가하지 못했습니다.", MessageTypes.ERROR);
      },
      update(_, { data }) {
        if (
          data?.addWTBasicInfo.ok &&
          data?.addWTBasicInfo.workingTemplateIdx
        ) {
          handleWorkingTemplateIdx(data?.addWTBasicInfo.workingTemplateIdx);
          handleToast(
            "성공적으로 근무제를 추가하였습니다.",
            MessageTypes.SUCCESS
          );
        } else if (data?.addWTBasicInfo.error) {
          handleErrorLog(data?.addWTBasicInfo.error);
          handleToast("근무제를 추가하지 못했습니다.", MessageTypes.ERROR);
        }
      }
    });

  const [updateWTBasicInfo, { loading: loading2 }] =
    useUpdateWtBasicInfoMutation({
      onError(error) {
        handleApolloErrorLog(error);
        handleToast("근무제를 수정하지 못했습니다.", MessageTypes.ERROR);
      },
      update(_, { data }) {
        if (data?.updateWTBasicInfo.ok) {
          handleToast(
            "성공적으로 근무제를 수정하였습니다.",
            MessageTypes.SUCCESS
          );
        } else if (data?.updateWTBasicInfo.error) {
          handleErrorLog(data?.updateWTBasicInfo.error);
          handleToast("근무제를 수정하지 못했습니다.", MessageTypes.ERROR);
        }
      }
    });

  const [addStandardWTBasicInfo, { loading: loading3 }] =
    useAddStandardWtBasicInfoMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addStandardWTBasicInfo.error) {
          handleErrorLog(data?.addStandardWTBasicInfo.error);
        }
      }
    });

  const [addFlexibleWorkingHourInfo, { loading: loading4 }] =
    useAddFlexibleWorkingHourInfoMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addFlexibleWorkingHourInfo.error) {
          handleErrorLog(data?.addFlexibleWorkingHourInfo.error);
        }
      }
    });

  const [addSelfDeterminedWorkHours, { loading: loading5 }] =
    useAddSelfDeterminedWorkHoursMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addSelfDeterminedWorkHours.error) {
          handleErrorLog(data?.addSelfDeterminedWorkHours.error);
        }
      }
    });

  const [addAutonomyWorkingHourInfo, { loading: loading6 }] =
    useAddAutonomyWorkingHourInfoMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addAutonomyWorkingHourInfo.error) {
          handleErrorLog(data?.addAutonomyWorkingHourInfo.error);
        }
      }
    });

  const [addCommuteRecordInfo, { loading: loading7 }] =
    useAddCommuteRecordInfoMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addCommuteRecordInfo.error) {
          handleErrorLog(data?.addCommuteRecordInfo.error);
        }
      }
    });

  const [addControlNotification, { loading: loading8 }] =
    useAddControlNotificationMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addControlNotification.error) {
          handleErrorLog(data?.addControlNotification.error);
        }
      }
    });

  const [addAbsenceManagementInfo, { loading: loading9 }] =
    useAddAbsenceManagementInfoMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addAbsenceManagementInfo.error) {
          handleErrorLog(data?.addAbsenceManagementInfo.error);
        }
      }
    });

  const [addWidgetSettingInfo, { loading: loading10 }] =
    useAddWidgetSettingInfoMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addWidgetSettingInfo.error) {
          handleErrorLog(data?.addWidgetSettingInfo.error);
        }
      }
    });

  const [addEtcOperatingHours, { loading: loading11 }] =
    useAddEtcOperatingHoursMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addEtcOperatingHours.error) {
          handleErrorLog(data?.addEtcOperatingHours.error);
        }
      }
    });

  const loading = useMemo(() => {
    return (
      loading1 ||
      loading2 ||
      loading3 ||
      loading4 ||
      loading5 ||
      loading6 ||
      loading7 ||
      loading8 ||
      loading9 ||
      loading10 ||
      loading11
    );
  }, [
    loading1,
    loading2,
    loading3,
    loading4,
    loading5,
    loading6,
    loading7,
    loading8
  ]);

  useEffect(() => {
    if (isSave && workPolicyBasicSetting) {
      setIsSave(false);
      if (
        workPolicyBasicSetting.name &&
        !workPolicyBasicSetting.workingTemplateIdx
      ) {
        Promise.all([
          addWTBasicInfo({
            variables: {
              name: workPolicyBasicSetting.name,
              workType: workPolicyBasicSetting.workType,
              dayMaxWorkHour: workPolicyBasicSetting.dayMaxWorkHour,
              weekMaxWorkHour: workPolicyBasicSetting.weekMaxWorkHour,
              weekMaxExtWorkHour: workPolicyBasicSetting.weekMaxExtWorkHour,
              mandatoryBreakTermHour:
                workPolicyBasicSetting.mandatoryBreakTermHour,
              mandatoryBreakMin: workPolicyBasicSetting.mandatoryBreakMin,
              dayOfWeekStart: workPolicyBasicSetting.dayOfWeekStart,
              timeDifferenceMin: workPolicyBasicSetting.timeDifferenceMin,
              periodUnitType: workPolicyBasicSetting.periodUnitType,
              ...(isSelfBasicWorkType
                ? {}
                : {
                    otHoursTypeCategoryIdx:
                      workPolicyBasicSetting.otHoursTypeCategoryIdx
                  }),
              webTemplateWorkTimeUse:
                workPolicyBasicSetting?.webTemplateWorkTimeUse ??
                Setting_Enable_For_Working_Template.NoUse,
              workingTemplateIdxMinors:
                workPolicyAdditionalSetting?.workingTemplateIdxMinors ?? 0,
              periodUnitMonthMinType:
                workPolicyAdditionalSetting?.periodUnitMonthMinType ??
                Setting_Enable_For_Working_Template.NoUse,
              periodUnitMonthFixMin:
                workPolicyAdditionalSetting?.periodUnitMonthFixMin ?? 0,
              standardDailyStartTimeRange:
                workPolicyAdditionalSetting?.standardDailyStartTimeRange ?? "",
              standardDailyEndTimeRange:
                workPolicyAdditionalSetting?.standardDailyEndTimeRange ?? "",
              nightReferenceStartTimeRange:
                workPolicyAdditionalSetting?.nightReferenceStartTimeRange ?? "",
              nightReferenceEndTimeRange:
                workPolicyAdditionalSetting?.nightReferenceEndTimeRange ?? "",
              lateCheckTime: workPolicyAdditionalSetting?.lateCheckTime ?? "",
              limitExtworkminDay:
                workPolicyAdditionalSetting?.limitExtworkminDay ?? 0,
              limitExtworkminWeek:
                workPolicyAdditionalSetting?.limitExtworkminWeek ?? 0,
              limitExtworkminMonth:
                workPolicyAdditionalSetting?.limitExtworkminMonth ?? 0,
              weeklyHolidayAllowanceUse:
                workPolicyAdditionalSetting?.weeklyHolidayAllowanceUse ??
                Setting_Enable_For_Working_Template.NoUse
            }
          })
        ]);
      }

      if (
        workPolicyBasicSetting.name &&
        workPolicyBasicSetting.workingTemplateIdx
      ) {
        const workingTemplateIdx =
          workPolicyBasicSetting.workingTemplateIdx as number;
        const parsedOtWorkingStandardHourList = otWorkingStandardHourList.map(
          item => ({
            breaktime_type: item.breakTimeType,
            day_of_week: item.dayOfWeek,
            day_of_week_type: item.dayOfWeekType,
            end_breaktime: item.endBreakTime,
            end_time: item.endTime,
            start_breaktime: item.startBreakTime,
            start_time: item.startTime,
            time_sequence: item.timeSequence,
            work_week_select: item.workWeekSelect,
            work_week_type: item.workWeekType
          })
        );
        const parsedOtWorkingFlexibleHours = otWorkingFlexibleHours.map(
          item => ({
            day_of_week: item.dayOfWeek,
            end_breaktime: item.endBreakTime,
            end_time: item.endTime,
            start_breaktime: item.startBreakTime,
            start_time: item.startTime,
            week_sequence: item.weekSequence,
            work_hour: item.workHour
          })
        );
        let mutationArr: any[] = [
          updateWTBasicInfo({
            variables: {
              name: workPolicyBasicSetting.name,
              workType: workPolicyBasicSetting.workType,
              dayMaxWorkHour: workPolicyBasicSetting.dayMaxWorkHour,
              weekMaxWorkHour: workPolicyBasicSetting.weekMaxWorkHour,
              weekMaxExtWorkHour: workPolicyBasicSetting.weekMaxExtWorkHour,
              mandatoryBreakTermHour:
                workPolicyBasicSetting.mandatoryBreakTermHour,
              mandatoryBreakMin: workPolicyBasicSetting.mandatoryBreakMin,
              dayOfWeekStart: workPolicyBasicSetting.dayOfWeekStart,
              timeDifferenceMin: workPolicyBasicSetting.timeDifferenceMin,
              periodUnitType: workPolicyBasicSetting.periodUnitType,
              ...(isSelfBasicWorkType
                ? {}
                : {
                    otHoursTypeCategoryIdx:
                      workPolicyBasicSetting.otHoursTypeCategoryIdx
                  }),
              workingTemplateIdx,
              otHolidayTemplateNewAutoUse,
              webTemplateWorkTimeUse:
                workPolicyBasicSetting?.webTemplateWorkTimeUse ??
                Setting_Enable_For_Working_Template.NoUse,
              workingTemplateIdxMinors:
                workPolicyAdditionalSetting?.workingTemplateIdxMinors ?? 0,
              periodUnitMonthMinType:
                workPolicyAdditionalSetting?.periodUnitMonthMinType ??
                Setting_Enable_For_Working_Template.NoUse,
              periodUnitMonthFixMin:
                workPolicyAdditionalSetting?.periodUnitMonthFixMin ?? 0,
              standardDailyStartTimeRange:
                workPolicyAdditionalSetting?.standardDailyStartTimeRange ?? "",
              standardDailyEndTimeRange:
                workPolicyAdditionalSetting?.standardDailyEndTimeRange ?? "",
              nightReferenceStartTimeRange:
                workPolicyAdditionalSetting?.nightReferenceStartTimeRange ?? "",
              nightReferenceEndTimeRange:
                workPolicyAdditionalSetting?.nightReferenceEndTimeRange ?? "",
              lateCheckTime: workPolicyAdditionalSetting?.lateCheckTime ?? "",
              limitExtworkminDay:
                workPolicyAdditionalSetting?.limitExtworkminDay ?? 0,
              limitExtworkminWeek:
                workPolicyAdditionalSetting?.limitExtworkminWeek ?? 0,
              limitExtworkminMonth:
                workPolicyAdditionalSetting?.limitExtworkminMonth ?? 0,
              weeklyHolidayAllowanceUse:
                workPolicyAdditionalSetting?.weeklyHolidayAllowanceUse ??
                Setting_Enable_For_Working_Template.NoUse
            }
          })
        ];
        if (
          otWorkingStandardHours &&
          selectedSubTab === SELECTED_SUB_TAB.WORKING_TIME
        ) {
          const {
            startTime,
            endTime,
            workWeekType,
            workWeekSelect,
            dayOfWeekType,
            dayOfWeek,
            startBreakTime,
            endBreakTime
          } = otWorkingStandardHours;
          mutationArr.push(
            addStandardWTBasicInfo({
              variables: {
                workingTemplateIdx,
                startTime,
                endTime,
                workWeekType,
                workWeekSelect,
                dayOfWeekType,
                dayOfWeek,
                startBreakTime,
                endBreakTime,
                otWorkingStandardHourList: parsedOtWorkingStandardHourList
              }
            })
          );
        }

        if (
          otWorkingFlexibleBasic &&
          selectedSubTab === SELECTED_SUB_TAB.WORKING_TIME
        ) {
          addFlexibleWorkingHourInfo({
            variables: {
              ...otWorkingFlexibleBasic,
              otWorkingFlexibleHours: parsedOtWorkingFlexibleHours,
              workingTemplateIdx
            }
          });
          mutationArr.push(addFlexibleWorkingHourInfo);
        }
        if (
          selfDeterminedWorkHourBasicInfo &&
          selectedSubTab === SELECTED_SUB_TAB.WORKING_TIME
        ) {
          mutationArr.push(
            addSelfDeterminedWorkHours({
              variables: { ...selfDeterminedWorkHourBasicInfo, workingTimeName }
            })
          );
        }
        if (
          autonomyWorkingHourInfo &&
          selectedSubTab === SELECTED_SUB_TAB.WORKING_TIME
        ) {
          mutationArr.push(
            addAutonomyWorkingHourInfo({
              variables: autonomyWorkingHourInfo
            })
          );
        }
        if (
          commuteRecordInfo &&
          selectedSubTab === SELECTED_SUB_TAB.RECORDING_TIME
        ) {
          mutationArr.push(
            addCommuteRecordInfo({
              variables: commuteRecordInfo
            })
          );
        }
        if (
          controlNotiInfo &&
          selectedSubTab === SELECTED_SUB_TAB.CONTROL_ALARM
        ) {
          mutationArr.push(
            addControlNotification({
              variables: controlNotiInfo
            })
          );
        }
        if (
          absenceManagementInfo &&
          selectedSubTab === SELECTED_SUB_TAB.LEAVE_WORK_MANAGEMENT
        ) {
          mutationArr.push(
            addAbsenceManagementInfo({
              variables: absenceManagementInfo
            })
          );
        }
        if (
          widgetSettingInfo &&
          selectedSubTab === SELECTED_SUB_TAB.WIDGET_SETTING
        ) {
          mutationArr.push(
            addWidgetSettingInfo({ variables: widgetSettingInfo })
          );
        }
        if (
          etcOperatingHours &&
          selectedSubTab === SELECTED_SUB_TAB.ETC_OPERATING_TIME
        ) {
          mutationArr.push(
            addEtcOperatingHours({
              variables: etcOperatingHours
            })
          );
        }
        Promise.all(mutationArr).then(() => {
          client.resetStore();
        });
      }
    }
  }, [
    isSave,
    addWTBasicInfo,
    updateWTBasicInfo,
    otHolidayTemplateNewAutoUse,
    isSelfBasicWorkType,
    otWorkingStandardHours,
    otWorkingStandardHourList,
    addFlexibleWorkingHourInfo,
    otWorkingFlexibleBasic,
    otWorkingFlexibleHours,
    selfDeterminedWorkHourBasicInfo,
    addSelfDeterminedWorkHours,
    workingTimeName,
    autonomyWorkingHourInfo,
    addAutonomyWorkingHourInfo,
    addCommuteRecordInfo,
    commuteRecordInfo,
    controlNotiInfo,
    addControlNotification,
    absenceManagementInfo,
    addAbsenceManagementInfo,
    widgetSettingInfo,
    addWidgetSettingInfo,
    etcOperatingHours,
    addEtcOperatingHours,
    client,
    selectedSubTab
  ]);

  useEffect(() => {
    if (id) {
      handleWorkingTemplateIdx(parseInt(id));
    }
  }, [id, handleWorkingTemplateIdx]);

  return (
    <WS_STATE.Provider
      value={{
        isSave,
        handleIsSave,
        workingTemplateIdx,
        workPolicyBasicSetting, // 기본설정 탭
        handleWorkPolicyBasicSetting, // 기본설정 탭 정보 저장
        workPolicyAdditionalSetting, // 추가설정 탭
        handleWorkPolicyAdditionalSetting, // 추가설정 탭 정보 저장
        isSelfBasicWorkType,
        handleIsSelfBasicWorkType,
        otHolidayTemplateNewAutoUse,
        handleOtHolidayTemplateNewAutoUse,
        otWorkingStandardHours,
        handleOtWorkingStandardHours,
        handleOtWorkingStandardHourList,
        setOtWorkingFlexibleBasic,
        setOtWorkingFlexibleHours,
        setSelfDeterminedWorkHourBasicInfo,
        setWorkingTimeName,
        setAutonomyWorkingHourInfo,
        setCommuteRecordInfo,
        setControlNotInfo,
        setAbsenceManagementInfo,
        setWidgetSettingInfo,
        setEtcOperatingHours,
        weekTotalWorkHour,
        setWeekTotalWorkHour,
        selectedSubTab,
        setSelectedSubTab
      }}
    >
      <WS_STORE.Provider value={{ ...state, dispatch }}>
        <Container>
          <LoadingContainer isLoading={loading}>
            <ReactLoading
              type="spokes"
              color={colors.green}
              height={200}
              width={170}
            />
            <span>{`저장 중입니다. 잠시만 기다려 주세요`}</span>
          </LoadingContainer>
          <ContentContainer isLoading={loading}>
            <TopTabs workType={workType} handleWorkType={handleWorkType} />
            <WSTabs workType={workType} />
          </ContentContainer>
          <ToastMessage
            message={message}
            isOpen={isToastMessageOpen}
            handleIsOpen={handleIsToastMessageOpen}
            messageTypes={toastMessageType}
          />
        </Container>
      </WS_STORE.Provider>
    </WS_STATE.Provider>
  );
}

export default AsonicWorkingSystemScreen;
