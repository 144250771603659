import { ApprovalDetailInformationEntity } from "../../../generated/graphql";
import { ApprovalTemplateEntity } from "../../../generated/graphql";
import { ApprovalTitle } from "./styles";

interface IApprovalFormTitleProps {
  template?: ApprovalTemplateEntity;
  approvalDetailInformation?: ApprovalDetailInformationEntity;
  formName: string;
}

export default function ApprovalFormTitle({
  template,
  approvalDetailInformation,
  formName
}: IApprovalFormTitleProps) {
  return (
    <ApprovalTitle>
      <h1>
        {template?.templateTitle ??
          approvalDetailInformation?.formTemplateTitle ??
          formName}
      </h1>
    </ApprovalTitle>
  );
}
