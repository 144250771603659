import styled from "styled-components";
import { SEARCH_CATEGORY_FOR_VALUE, TYPE_OF_SEARCH_CATEGORY } from "./constant";
import { IApprovalDelegateInfoSearchType } from "../../../../../generated/graphql";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

function SearchCategoryDropBox({
  searchType,
  setSearchType
}: {
  searchType: IApprovalDelegateInfoSearchType;
  setSearchType: (searchType: IApprovalDelegateInfoSearchType) => void;
}) {
  return (
    <Container>
      <select
        id="search-category-select"
        name="searchCategory"
        value={searchType}
        onChange={event => {
          setSearchType(
            event.currentTarget.value as IApprovalDelegateInfoSearchType
          );
        }}
      >
        {Object.keys(SEARCH_CATEGORY_FOR_VALUE).map((item, index) => (
          <option value={item} key={`${item}-${index}`}>
            {SEARCH_CATEGORY_FOR_VALUE[item as TYPE_OF_SEARCH_CATEGORY]}
          </option>
        ))}
      </select>
    </Container>
  );
}

export default SearchCategoryDropBox;
