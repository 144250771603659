// employee_id_substitute : 아이디(대결자)
// substitute_name; 성명(대결자)
// substitute_posion; 직책(대결자)
// substitute_department; 부서명(대결자)
// employee_id_absence; 아이디(부재자)
// absence_name:성명(부재자)
// absence_posion;직책(부재자)
// absence_department;부서명(부재자)
// category_id;부재사유idx
// category_name;부재사유텍스트
// start_datetime;시작일시
// end_datetime;종료일시
// use_flag;사용여부
// descr:비고

import { Row } from "react-table";
import { ApprovalDelegateInfoEntity } from "../../../../../generated/graphql";

// camelCase로 변환
export const COLUMN_FOR_VALUE = {
  isCheck: "선택",
  employeeIdSubstitute: "아이디(대결자)",
  substituteName: "성명(대결자)",
  substitutePosition: "직책(대결자)",
  substituteDepartment: "부서명(대결자)",
  employeeIdAbsence: "아이디(부재자)",
  absenceName: "성명(부재자)",
  absencePosition: "직책(부재자)",
  absenceDepartment: "부서명(부재자)",
  categoryName: "부재사유텍스트",
  startDatetime: "시작일시",
  endDatetime: "종료일시",
  useFlag: "사용여부",
  descr: "비고"
} as const;

export const SEARCH_CATEGORY_FOR_VALUE = {
  employeeIdSubstitute: "아이디(대결자)",
  substituteName: "성명(대결자)",
  substitutePosition: "직책(대결자)",
  substituteDepartment: "부서명(대결자)",
  employeeIdAbsence: "아이디(부재자)",
  absenceName: "성명(부재자)",
  absencePosition: "직책(부재자)",
  absenceDepartment: "부서명(부재자)",
  categoryName: "부재사유텍스트",
  useFlag: "사용여부",
  descr: "비고"
} as const;

export type TYPE_OF_APPROVAL_DELEGATE = keyof typeof COLUMN_FOR_VALUE;
export type TYPE_OF_SEARCH_CATEGORY = keyof typeof SEARCH_CATEGORY_FOR_VALUE;

export interface ISampleApprovalDelegateInfo {
  approvalAuthorityIdx: number;
  employeeIdSubstitute: string;
  substituteName: string;
  substitutePosition?: string;
  substituteDepartment?: string;
  employeeIdAbsence: string;
  absenceName?: string;
  absencePosition?: string;
  absenceDepartment?: string;
  categoryId?: number;
  categoryName?: string;
  startDatetime?: string;
  endDatetime?: string;
  useFlag?: number;
  descr?: string;
}

export interface IApprovalDelegateInfoWithCheck
  extends ApprovalDelegateInfoEntity {
  isCheck?: boolean;
}

// Extended Row interface to include selection properties
export interface IExtendedRow<D extends object = {}> extends Row<D> {
  isSelected?: boolean;
  toggleRowSelected?: (set: boolean) => void;
}

// 대결자정보 샘플데이터 10개 생성
export const SAMPLE_DATA: IApprovalDelegateInfoWithCheck[] = Array.from(
  { length: 60 },
  (_, index) => ({
    approvalAuthorityIdx: index,
    employeeIdSubstitute: `sub_${index}`,
    substituteName: `Substitute ${index}`,
    substitutePosition: `Position ${index}`,
    substituteDepartment: `Department ${index}`,
    employeeIdAbsence: `abs_${index}`,
    absenceName: `Absence ${index}`,
    absencePosition: `Absence Position ${index}`,
    absenceDepartment: `가나다라마바사아자차카타파하 ${index}`,
    categoryId: index,
    categoryName: `Category ${index}`,
    startDatetime: `2024-01-01 00:00:00`,
    endDatetime: `2024-01-01 00:00:00`,
    useFlag: 1,
    descr: `Description ${index}`,
    isCheck: false
  })
);
