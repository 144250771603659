import { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TOKEN } from "../../apollo/apollo";
import {
  ApplicationApprovalMutationVariables,
  Approval_User_Type,
  DeleteApprovalRequestMutationVariables,
  IListOfApprovalLineUser,
  IReceiveAndReferenceForDepartment,
  IReceiveAndReferenceForUser,
  useAddApprovalTemporaryStorageMutation,
  useApplicationApprovalMutation,
  useDeleteApprovalRequestMutation,
  useDeleteApprovalTemporaryStorageMutation,
  useIsActiveApprovalPasswordLazyQuery
} from "../../generated/graphql";
import useConfirmDialog from "../../hooks/confirm-dialog-hook/use-confirm-dialog";
import useOpenToastMessage from "../../hooks/toast-message-hook/use-open-toast-message";
import useOpenDialog from "../../hooks/use-open-dialog";
import routes from "../../routes";
import EChannel from "../../Utils/EChannel/e-channel";
import { handleDecodedToken } from "../../Utils/tokenMaker";
import AsonicDialog from "../asonic-dialog/asonic-dialog";
import ConfirmDialog from "../confirm-dialog/confirm-dialog";
import Button from "../globalComponents/Button";
import ToastMessage, { MessageTypes } from "../toast-message/toast-message";
import ApprovalDeleteRequestDialog from "./approval-delete-request-dialog/approval-delete-request-dialog";
import { ApprovalType, IApprovalDetailMessage } from "./approval-detail-popup";
import ApprovalDetailPopupStore from "./approval-detail-popup-store.ctx";
import { IApprovalFormFieldValue } from "./approval-form/types";
import moment from "moment";
import { colors } from "../GlobalStyle/GlobalStyle";
import ApprovalPassword from "./approval-password/approval-password";

interface IProps {
  handleShowApprovalPopup?: (value: boolean) => void;
  approvalType?: ApprovalType;
  handleOpenDialog: (value: boolean) => void;
  closeWindow: () => void;
  isRequest?: boolean;
  handleSubmit: (handler: (data: IApprovalFormFieldValue) => void) => void;
  isActiveApplicationButton?: boolean;
  handleOpenSignAndRejectDialog?: (value: boolean) => void;
  handleIsSignOrReject?: (value: boolean) => void;
  isUserChecked?: boolean;
  isAutoApproval?: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.darkGrey};
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
`;

const ButtonLeftSideContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 5px;
`;

const ButtonRightSideContainer = styled(ButtonLeftSideContainer)`
  justify-content: flex-end;
`;

function ApprovalPopupButtons({
  handleShowApprovalPopup,
  approvalType,
  handleOpenDialog,
  closeWindow,
  isRequest,
  handleSubmit,
  isActiveApplicationButton,
  handleOpenSignAndRejectDialog,
  handleIsSignOrReject,
  isUserChecked,
  isAutoApproval
}: IProps) {
  const navigate = useNavigate();
  const bcForReset = useMemo(() => new BroadcastChannel(EChannel.RESET), []);
  const bcMoveToPage = useMemo(
    () => new BroadcastChannel(EChannel.MOVE_TO_PAGE),
    []
  );
  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    confirmTitle: confirmTitleForTemporaryStorage,
    confirmParagraph: confirmParagraphForTemporaryStorage,
    isOpen: isOpenForTemporaryStorage,
    handleIsOpen: handleIsOpenForTemporaryStorage,
    handleConfirmMessage: handleConfirmMessageForTemporaryStorage,
    confirmType: confirmTypeForTemporaryStorage
  } = useConfirmDialog();

  const {
    confirmTitle: confirmTitleForError,
    confirmParagraph: confirmParagraphForError,
    isOpen: isOpenForError,
    handleIsOpen: handleIsOpenForError,
    handleConfirmMessage: handleConfirmMessageForError,
    confirmType: confirmTypeForError
  } = useConfirmDialog();

  const {
    confirmTitle: confirmTitleForDeleteRequest,
    confirmParagraph: confirmParagraphForDeleteRequest,
    isOpen: isOpenForDeleteRequest,
    handleIsOpen: handleIsOpenForDeleteRequest,
    handleConfirmMessage: handleConfirmMessageForDeleteRequest,
    confirmType: confirmTypeForDeleteRequest
  } = useConfirmDialog();

  const {
    confirmTitle: confirmTitleForDeleteTemporaryStorage,
    confirmParagraph: confirmParagraphForDeleteTemporaryStorage,
    isOpen: isOpenForDeleteTemporaryStorage,
    handleIsOpen: handleIsOpenForDeleteTemporaryStorage,
    handleConfirmMessage: handleConfirmMessageForDeleteTemporaryStorage,
    confirmType: confirmTypeForDeleteTemporaryStorage
  } = useConfirmDialog();

  const employeeId = useMemo((): string => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      return decodedData.employee_id;
    }
    return "";
  }, []);

  const store = useContext(ApprovalDetailPopupStore);

  const { isOpen: isOpenDialog, handleOpenDialog: handleOpenPasswordDialog } =
    useOpenDialog();

  const {
    isOpen: isOpenDialogForDeleteRequest,
    handleOpenDialog: handleOpenDeleteRequestDialog
  } = useOpenDialog();
  const [isActiveApprovalPassword, { data: isActiveApprovalPasswordData }] =
    useIsActiveApprovalPasswordLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const [deleteApprovalTemporaryStorage, { client }] =
    useDeleteApprovalTemporaryStorageMutation({
      onError(error) {
        console.log(error);
        handleConfirmMessageForError({
          title: "임시 저장 양식 삭제 실패",
          p: "임시 저장 양식 삭제를 실패했습니다.",
          messageTypes: MessageTypes.ERROR
        });
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      update(_, { data }) {
        if (data?.deleteApprovalTemporaryStorage.ok) {
          const newMessage: IApprovalDetailMessage = {
            type: ApprovalType.RESET_CACHE
          };
          bcForReset.postMessage(newMessage);
          bcForReset.close();
          if (handleShowApprovalPopup) {
            handleShowApprovalPopup(false);
          } else {
            window.close();
          }
        } else if (data?.deleteApprovalTemporaryStorage.error) {
          handleConfirmMessageForError({
            title: "임시 저장 양식 삭제 실패",
            p: "임시 저장 양식 삭제를 실패했습니다.",
            messageTypes: MessageTypes.ERROR
          });
        }
      }
    });

  const [applicationApproval] = useApplicationApprovalMutation({
    onError(error) {
      handleConfirmMessageForError({
        title: "결재 상신 실패",
        p: "결재 상신에 실패했습니다.",
        messageTypes: MessageTypes.ERROR
      });
      handleIsOpenForError(true);
    },
    update(_, { data }) {
      if (data?.applicationApproval.ok) {
        if (store?.isTemporaryStorage && store?.approvalRequestIdx) {
          deleteApprovalTemporaryStorage({
            variables: {
              approvalRequestIdx: store.approvalRequestIdx
            }
          });
        } else {
          client.resetStore();
          if (handleShowApprovalPopup) {
            handleShowApprovalPopup(false);
          } else {
            window.close();
          }
        }
      } else if (data?.applicationApproval.error) {
        handleConfirmMessageForError({
          title: "결재 상신 실패",
          p: data?.applicationApproval.error,
          messageTypes: MessageTypes.ERROR
        });
        handleIsOpenForError(true);
      }
    }
  });

  const [addApprovalTemporaryStorage] = useAddApprovalTemporaryStorageMutation({
    onError(error) {
      console.log(error.message);
      handleConfirmMessageForError({
        title: "임시저장 실패",
        p: "임시저장에 실패했습니다.",
        messageTypes: MessageTypes.ERROR
      });
      handleIsOpenForError(true);
    },
    update(_, { data }) {
      if (data?.addApprovalTemporaryStorage.ok) {
        if (handleShowApprovalPopup) {
          handleShowApprovalPopup(false);
        } else {
          window.close();
        }
      } else if (data?.addApprovalTemporaryStorage.error) {
        handleConfirmMessageForError({
          title: "임시저장 실패",
          p: "임시저장에 실패했습니다.",
          messageTypes: MessageTypes.ERROR
        });
        handleIsOpenForError(true);
      }
    }
  });

  const [deleteApprovalRequest] = useDeleteApprovalRequestMutation({
    onError(error) {
      handleConfirmMessageForDeleteRequest({
        title: "결재회수 실패",
        p: "결재회수에 실패했습니다.",
        messageTypes: MessageTypes.ERROR
      });
      handleIsOpenForError(true);
    },
    update(_, { data }) {
      if (data?.deleteApprovalRequest.ok) {
        client.resetStore();
        handleConfirmMessageForDeleteRequest({
          title: "결재회수",
          p: `회수가 신청 되었습니다.
적용까지는 시간이 소요됩니다. 
잠시 후 확인해 주세요.

임시 저장함으로 이동됩니다.
`,
          messageTypes: MessageTypes.SUCCESS
        });
        handleIsOpenForDeleteRequest(true);
      } else if (data?.deleteApprovalRequest.error) {
        handleConfirmMessageForError({
          title: "결재회수 실패",
          p: "결재회수에 실패했습니다.",
          messageTypes: MessageTypes.ERROR
        });
        handleOpenDeleteRequestDialog(false);
        handleIsOpenForError(true);
      }
    }
  });

  const parseDateTime = useCallback(
    ({ date, time }: { date: string; time: string }) => {
      // moment 객체 생성
      return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
    },
    []
  );

  const handleApprovalTemporaryStorage = useCallback(
    ({
      title,
      startDate,
      endDate,
      startTime,
      endTime,
      description
    }: IApprovalFormFieldValue) => {
      if (store && store.formTemplateIdx) {
        const listOfApprovalLineUser: IListOfApprovalLineUser[] = [];
        // 기안자 추가
        listOfApprovalLineUser.push({
          employee_id: employeeId,
          order: 1,
          type: Approval_User_Type.Drafter
        });
        store.listOfUser.forEach((item, index) => {
          if (item.approvalType !== Approval_User_Type.Drafter) {
            const user = {
              employee_id: item.employeeId,
              order: index + 2,
              type: item.approvalType
            };
            listOfApprovalLineUser.push(user);
          }
        });

        const listOfReceiver: IReceiveAndReferenceForUser[] =
          store.listOfReceiveUser;
        const listOfReferencedPerson: IReceiveAndReferenceForUser[] =
          store.listOfReferenceUser;
        const listOfReceiveDepartment: IReceiveAndReferenceForDepartment[] =
          store.listOfReceiveDepartment;
        const listOfReferencedPersonDepartment: IReceiveAndReferenceForDepartment[] =
          store.listOfReferenceDepartment;
        const sDate = parseDateTime({ date: startDate, time: startTime });
        const eDate = parseDateTime({ date: endDate, time: endTime });
        const isEndDateAfterStartDate = eDate.isAfter(sDate);
        if (isEndDateAfterStartDate) {
          const payload: ApplicationApprovalMutationVariables = {
            employeeId,
            workingTemplateIdx: store.workingTemplateIdx,
            startDate,
            endDate,
            title,
            formTemplateIdx: store.formTemplateIdx,
            startTime,
            endTime,
            description,
            listOfApprovalLineUser,
            listOfReceiver,
            listOfReferencedPerson,
            listOfReceiveDepartment,
            listOfReferencedPersonDepartment
          };
          applicationApproval({
            variables: payload
          });
        } else {
          handleToast("신청 일시를 확인해 주세요.", MessageTypes.ERROR);
        }
      }
    },
    [addApprovalTemporaryStorage, store, employeeId, parseDateTime]
  );

  const handleDeleteRequest = useCallback(
    ({
      title,
      startDate,
      endDate,
      startTime,
      endTime,
      description
    }: IApprovalFormFieldValue) => {
      if (store && store.formTemplateIdx && store.approvalRequestIdx) {
        const listOfApprovalLineUser: IListOfApprovalLineUser[] = [];
        store.listOfUser.forEach((item, index) => {
          const user = {
            employee_id: item.employeeId,
            order: index + 1,
            type: item.approvalType
          };
          listOfApprovalLineUser.push(user);
        });

        const listOfReceiver: IReceiveAndReferenceForUser[] =
          store.listOfReceiveUser;
        const listOfReferencedPerson: IReceiveAndReferenceForUser[] =
          store.listOfReferenceUser;
        const listOfReceiveDepartment: IReceiveAndReferenceForDepartment[] =
          store.listOfReceiveDepartment;
        const listOfReferencedPersonDepartment: IReceiveAndReferenceForDepartment[] =
          store.listOfReferenceDepartment;
        const payload: DeleteApprovalRequestMutationVariables = {
          approvalRequestIdx: store.approvalRequestIdx,
          employeeId,
          workingTemplateIdx: store.workingTemplateIdx,
          startDate,
          endDate,
          title,
          formTemplateIdx: store.formTemplateIdx,
          startTime,
          endTime,
          description,
          listOfApprovalLineUser,
          listOfReceiver,
          listOfReferencedPerson,
          listOfReceiveDepartment,
          listOfReferencedPersonDepartment
        };

        deleteApprovalRequest({
          variables: payload
        });
      }
    },
    [deleteApprovalRequest, store, employeeId]
  );

  const handleApplicationApproval = useCallback(
    ({
      title,
      startDate,
      endDate,
      startTime,
      endTime,
      description
    }: IApprovalFormFieldValue) => {
      if (store && store.formTemplateIdx) {
        const listOfApprovalLineUser: IListOfApprovalLineUser[] = [];
        // 기안자 추가
        listOfApprovalLineUser.push({
          employee_id: employeeId,
          order: 1,
          type: Approval_User_Type.Drafter
        });
        store.listOfUser.forEach((item, index) => {
          if (item.approvalType !== Approval_User_Type.Drafter) {
            const user = {
              employee_id: item.employeeId,
              order: index + 2,
              type: item.approvalType
            };
            listOfApprovalLineUser.push(user);
          }
        });
        const listOfReceiver: IReceiveAndReferenceForUser[] =
          store.listOfReceiveUser;
        const listOfReferencedPerson: IReceiveAndReferenceForUser[] =
          store.listOfReferenceUser;
        const listOfReceiveDepartment: IReceiveAndReferenceForDepartment[] =
          store.listOfReceiveDepartment;
        const listOfReferencedPersonDepartment: IReceiveAndReferenceForDepartment[] =
          store.listOfReferenceDepartment;
        const sDate = parseDateTime({ date: startDate, time: startTime });
        const eDate = parseDateTime({ date: endDate, time: endTime });
        const isEndDateAfterStartDate = eDate.isAfter(sDate);
        if (isEndDateAfterStartDate) {
          const payload: ApplicationApprovalMutationVariables = {
            employeeId,
            workingTemplateIdx: store.workingTemplateIdx,
            startDate,
            endDate,
            title,
            formTemplateIdx: store.formTemplateIdx,
            startTime,
            endTime,
            description,
            listOfApprovalLineUser,
            listOfReceiver,
            listOfReferencedPerson,
            listOfReceiveDepartment,
            listOfReferencedPersonDepartment
          };
          applicationApproval({
            variables: payload
          });
        } else {
          handleToast("신청 일시를 확인해 주세요.", MessageTypes.ERROR);
        }
      }
    },
    [applicationApproval, store, employeeId, parseDateTime]
  );

  const handleConfirmForError = useCallback(() => {
    handleIsOpenForError(false);
  }, [handleIsOpenForError]);

  const handleConfirmForTemporaryStorage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleSubmit(handleApprovalTemporaryStorage);
      handleIsOpenForTemporaryStorage(false);
    },
    [handleSubmit, handleApprovalTemporaryStorage]
  );

  const handleConfirmForDeleteRequest = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const newMessage: IApprovalDetailMessage = {
        type: ApprovalType.MOVE_TO_TEMPORARY_STORAGE_PAGE
      };
      if (handleShowApprovalPopup) {
        handleShowApprovalPopup(false);
        navigate(routes.pageRoutes.approvalTemporaryDocument);
      } else if (bcMoveToPage) {
        bcMoveToPage.postMessage(newMessage);
        window.close();
      }
      bcMoveToPage.close();
    },
    [bcMoveToPage, handleShowApprovalPopup, navigate]
  );

  const deleteRequestController = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleSubmit(handleDeleteRequest);
    },
    [handleSubmit, handleDeleteRequest]
  );

  const handleConfirm = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleSubmit(handleApplicationApproval);
      handleIsOpen(false);
    },
    [handleIsOpen, handleApplicationApproval]
  );

  const handleDeleteApprovalTemporaryStorage = useCallback(() => {
    if (store?.approvalRequestIdx) {
      deleteApprovalTemporaryStorage({
        variables: {
          approvalRequestIdx: store.approvalRequestIdx
        }
      });
    }
  }, [store, deleteApprovalTemporaryStorage]);

  useEffect(() => {
    if (approvalType === ApprovalType.APPROVAL_WAITING_IN_LIST && !isRequest) {
      isActiveApprovalPassword();
    }
  }, [approvalType, isRequest, isActiveApprovalPassword]);

  return (
    <Container>
      <ButtonLeftSideContainer>
        {isRequest && (
          <Fragment>
            <Button
              onClick={() => {
                handleConfirmMessageForTemporaryStorage({
                  title: "결재 임시저장",
                  p: "임시저장 하시겠습니까?",
                  messageTypes: MessageTypes.INFO
                });
                handleIsOpenForTemporaryStorage(true);
              }}
            >
              임시저장
            </Button>
            {!isAutoApproval && (
              <Button
                onClick={() => {
                  const width = 915;
                  const height = 650;
                  const left = (window.screen.width - width) / 2;
                  const top = (window.screen.height - height) / 2;
                  window.open(
                    `${routes.pageRoutes.approvalProcess}${
                      store?.formTemplateIdx ? `:${store?.formTemplateIdx}` : ""
                    }`,
                    "",
                    `width=${width}, height=${height}, left=${left}, top=${top}, scrollbars=yes`
                  );
                }}
              >
                결재선
              </Button>
            )}
          </Fragment>
        )}
        {!isRequest && (
          <Button
            onClick={() => {
              handleOpenDialog(true);
            }}
          >
            결재이력
          </Button>
        )}
        {!isRequest &&
          !isUserChecked &&
          approvalType === ApprovalType.MY_APPROVAL && (
            <Button
              onClick={() => {
                handleOpenDeleteRequestDialog(true);
              }}
            >
              결재회수
            </Button>
          )}
      </ButtonLeftSideContainer>
      <ButtonRightSideContainer>
        {approvalType === ApprovalType.APPROVAL_WAITING_IN_LIST &&
          !isRequest && (
            <Fragment>
              <Button
                onClick={() => {
                  if (handleIsSignOrReject) {
                    handleIsSignOrReject(true);
                  }
                  if (
                    isActiveApprovalPasswordData?.isActiveApprovalPassword.ok
                  ) {
                    handleOpenPasswordDialog(true);
                  } else {
                    if (handleOpenSignAndRejectDialog) {
                      handleOpenSignAndRejectDialog(true);
                    }
                  }
                }}
              >
                승인
              </Button>
              <Button
                onClick={() => {
                  if (handleIsSignOrReject) {
                    handleIsSignOrReject(false);
                  }
                  if (
                    isActiveApprovalPasswordData?.isActiveApprovalPassword.ok
                  ) {
                    handleOpenPasswordDialog(true);
                  } else {
                    if (handleOpenSignAndRejectDialog) {
                      handleOpenSignAndRejectDialog(true);
                    }
                  }
                }}
              >
                반려
              </Button>
            </Fragment>
          )}
        {isRequest && (
          <Button
            disabled={isActiveApplicationButton}
            onClick={() => {
              handleConfirmMessage({
                title: "결재 상신",
                p: "상신 하시겠습니까?",
                messageTypes: MessageTypes.INFO
              });
              handleIsOpen(true);
            }}
          >
            상신
          </Button>
        )}
        {store?.isTemporaryStorage && store?.approvalRequestIdx && (
          <Button
            onClick={() => {
              handleConfirmMessageForDeleteTemporaryStorage({
                title: "임시 저장 결재 양식 삭제",
                p: `임시 저장 결재 양식 삭제하시겠습니까?`,
                messageTypes: MessageTypes.INFO
              });
              handleIsOpenForDeleteTemporaryStorage(true);
            }}
          >
            삭제
          </Button>
        )}
        {!handleShowApprovalPopup && (
          <Button
            onClick={closeWindow}
            backgroundColor={colors.darkRed}
            hoverBackgroundColor={colors.lightRed}
          >
            닫기
          </Button>
        )}
      </ButtonRightSideContainer>
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.INFO}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      {isOpenForError && (
        <ConfirmDialog
          confirmTitle={confirmTitleForError}
          confirmParagraph={confirmParagraphForError}
          confirmType={confirmTypeForError}
          messageTypes={MessageTypes.ERROR}
          handleIsOpen={handleIsOpenForError}
          handleConfirm={handleConfirmForError}
          isCancel={true}
        />
      )}
      {isOpenForTemporaryStorage && (
        <ConfirmDialog
          confirmTitle={confirmTitleForTemporaryStorage}
          confirmParagraph={confirmParagraphForTemporaryStorage}
          confirmType={confirmTypeForTemporaryStorage}
          messageTypes={MessageTypes.INFO}
          handleIsOpen={handleIsOpenForTemporaryStorage}
          handleConfirm={handleConfirmForTemporaryStorage}
        />
      )}
      {isOpenForDeleteRequest && (
        <ConfirmDialog
          confirmTitle={confirmTitleForDeleteRequest}
          confirmParagraph={confirmParagraphForDeleteRequest}
          confirmType={confirmTypeForDeleteRequest}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpenForDeleteRequest}
          handleConfirm={handleConfirmForDeleteRequest}
        />
      )}
      {isOpenForDeleteTemporaryStorage && (
        <ConfirmDialog
          confirmTitle={confirmTitleForDeleteTemporaryStorage}
          confirmParagraph={confirmParagraphForDeleteTemporaryStorage}
          confirmType={confirmTypeForDeleteTemporaryStorage}
          messageTypes={MessageTypes.SUCCESS}
          handleIsOpen={handleIsOpenForDeleteTemporaryStorage}
          handleConfirm={handleDeleteApprovalTemporaryStorage}
        />
      )}
      {isOpenDialogForDeleteRequest && (
        <AsonicDialog
          title={"결재회수"}
          handleClose={(value: boolean) => {
            handleOpenDeleteRequestDialog(value);
          }}
          width="350px"
          minWidth="350px"
          height="180px"
          minHeight="180px"
        >
          <ApprovalDeleteRequestDialog
            handleOpenDeleteRequestDialog={handleOpenDeleteRequestDialog}
            deleteRequestController={deleteRequestController}
          />
        </AsonicDialog>
      )}
      {isOpenDialog && (
        <AsonicDialog
          title={"결재 비밀번호"}
          handleClose={(value: boolean) => {
            handleOpenPasswordDialog(value);
          }}
          width="420px"
          minWidth="420px"
          height="200px"
          minHeight="200px"
        >
          <ApprovalPassword
            handleOpenPasswordDialog={handleOpenPasswordDialog}
            handleToast={handleToast}
            handleOpenSignAndRejectDialog={handleOpenSignAndRejectDialog}
          />
        </AsonicDialog>
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
        isPopup={!handleShowApprovalPopup}
      />
    </Container>
  );
}
export default ApprovalPopupButtons;
