import styled from "styled-components";

const StyleInput = styled.input<{
  minWidth?: string;
  maxWidth?: string;
  $width?: string;
}>`
  display: flex;
  min-width: ${props => props.minWidth ?? "163px"};
  max-width: ${props => props.maxWidth ?? "163px"};
  width: ${props => props.$width ?? "max-content"};
`;

export default StyleInput;
