import * as ReactTable from "react-table";
import styled from "styled-components";
import { colors } from "../GlobalStyle/GlobalStyle";

export interface ICompactRow<P extends {}> extends ReactTable.Row<P> {
  isSelected?: boolean;
  toggleRowSelected?: (set: boolean) => void;
}

export interface ICompactTableRowProps<P extends {}> {
  prepareRow: (row: ICompactRow<P>) => void;
  handleSelectRow?: (row?: ICompactRow<P>) => void;
  handleUnselectedRow?: (row?: ICompactRow<P>) => void;
  handleContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedRow?: ICompactRow<P>;
}

interface IRenderRow<P extends {}> {
  data: ICompactRow<P>[];
  index: number;
  style: object;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  :hover {
    background-color: ${props => props.theme.colors.auroraGreen};
  }
  :active {
    background-color: ${props => props.theme.colors.lightGreen};
  }
  cursor: pointer;
`;

const Content = styled.div`
  display: flex !important;
  text-align: center;
  justify-content: center;
  align-items: center;
  :last-child {
    flex: 1;
  }
`;

function CompactTableRenderRow<P extends {}>({
  prepareRow,
  handleSelectRow,
  handleUnselectedRow,
  handleContextMenu,
  selectedRow
}: ICompactTableRowProps<P>) {
  return ({ index, data, style }: IRenderRow<P>) => {
    const row: ICompactRow<P> = data[index];
    prepareRow(row);
    const backgroundColor = row.isSelected ? colors.transparentGreen : "none";

    const initStyle = { ...style, backgroundColor };
    const multiSelect = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const contextButton = 2;
      if (event.type === "contextmenu" || event.button === contextButton) {
        event.preventDefault();
      }
      if (row.toggleRowSelected) {
        if (
          row.isSelected &&
          (event.type !== "contextmenu" || event.button !== contextButton)
        ) {
          data.forEach(item => {
            if (item.isSelected && item.toggleRowSelected) {
              item.toggleRowSelected(false);
            }
          });
          row.toggleRowSelected(false);
          handleUnselectedRow && handleUnselectedRow(row);
        } else if (selectedRow && (event.metaKey || event.ctrlKey)) {
          row.toggleRowSelected(true);
          if (
            (event.type === "contextmenu" || event.button === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
          }
        } else if (selectedRow && event.shiftKey) {
          data.forEach(item => {
            if (
              selectedRow.id < item.id &&
              item.id <= row.id &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              } else if (handleSelectRow) {
                handleSelectRow(row);
              }
            }
            if (
              item.id < selectedRow.id &&
              row.id <= item.id &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              }
            }
            if (
              (event.type !== "contextmenu" ||
                event.buttons !== contextButton) &&
              handleSelectRow
            ) {
              handleSelectRow(row);
            }
          });
        } else {
          if (selectedRow) {
            data.forEach(item => {
              if (item.isSelected && item.toggleRowSelected) {
                item.toggleRowSelected(false);
              }
            });
          }
          if (
            (event.type !== "contextmenu" || event.buttons !== contextButton) &&
            handleSelectRow
          ) {
            if (event.target === event.currentTarget) {
              row.toggleRowSelected(true);
              handleSelectRow(row);
            }
          }
          if (
            (event.type === "contextmenu" || event.buttons === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
            row.toggleRowSelected(true);
          }
        }
      }
    };
    return (
      <Container {...row.getRowProps({ style: initStyle })}>
        {row.cells.map(cell => (
          <Content
            {...cell.getCellProps()}
            key={`${index} ${Math.random() * 10}`}
            id={`${data[index].id}-context-menu`}
            onContextMenu={multiSelect}
            onClick={multiSelect}
          >
            {cell.render("Cell")}
          </Content>
        ))}
      </Container>
    );
  };
}

export default CompactTableRenderRow;
