import Button from "../../../../../components/globalComponents/Button";
import { colors } from "../../../../../components/GlobalStyle/GlobalStyle";
import { MoveButtonContainer } from "../styles/container";
import { Name } from "../styles/title";
import { Icon } from "@iconify/react";
import ArrowLeftIcon from "@iconify-icons/cil/arrow-circle-left";
import ArrowRightIcon from "@iconify-icons/cil/arrow-circle-right";
import { EmployeeInformation } from "../../../../../generated/graphql";
import { ICompactRow } from "../../../../../components/compact-table/compact-table-render-row";

function AddDeleteButtons({
  handleDelegateUserChange,
  selectedUser,
  delegateUsers
}: {
  handleDelegateUserChange: (users: EmployeeInformation[]) => void;
  selectedUser?: EmployeeInformation;
  delegateUsers: EmployeeInformation[];
}) {
  const isEdit = delegateUsers.length > 0;
  return (
    <MoveButtonContainer>
      <Button
        backgroundColor={isEdit ? colors.darkGreen : colors.darkBlue}
        hoverBackgroundColor={isEdit ? colors.lightGreen : colors.skyBlue}
        disabled={!selectedUser}
        onClick={() => {
          const newUsers = selectedUser ? [selectedUser] : [];
          handleDelegateUserChange(newUsers);
        }}
      >
        <Icon icon={ArrowRightIcon} />
        <Name>{isEdit ? "변경" : "추가"}</Name>
      </Button>
      <Button
        backgroundColor={colors.darkRed}
        hoverBackgroundColor={colors.lightRed}
        disabled={delegateUsers.length === 0}
        onClick={() => {
          handleDelegateUserChange([]);
        }}
      >
        <Icon icon={ArrowLeftIcon} />
        <Name>삭제</Name>
      </Button>
    </MoveButtonContainer>
  );
}

export default AddDeleteButtons;
