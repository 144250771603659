import styled from "styled-components";
import { ApprovalAdditionalFieldEntity } from "../../../generated/graphql";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 20;
  padding: 10px;
`;

export const ApprovalListContainer = styled.div`
  display: grid;
  align-self: flex-end;
  grid-template-columns: 30px repeat(1, 1fr);
  grid-template-rows: 1fr;
  max-height: 550px;
  grid-auto-flow: column;
  border: 1px solid ${props => props.theme.colors.darkGrey};
`;

export const MainHead = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: bold;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;
  background-color: ${props => props.theme.colors.grey};
  text-align: center;
`;

export const Approver = styled.div`
  display: flex;
  flex: 1;
  border-left: 1px solid ${props => props.theme.colors.darkGrey};
  flex-direction: column;
`;

export const Head = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  font-size: 14px;
  font-weight: bold;
  min-height: 20px;
  max-height: 20px;
`;

export const Body = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  flex-direction: column;
  gap: 5px;
  min-height: 62px;
`;

export const StampImg = styled.img`
  display: flex;
  width: 40px;
`;

export const ApprovalTitle = styled.div`
  display: flex;
  align-self: center;
  flex: 1;
  h1 {
    font-size: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  border-left: 1px solid ${props => props.theme.colors.darkGrey};
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
  :nth-child(7) {
    grid-row: span 2;
  }
  :nth-child(15) {
    grid-column: span 3;
  }
  :last-child {
    grid-column: span 3;
  }
`;

export const MainSection = styled.div<{
  $annualInfo?: ApprovalAdditionalFieldEntity;
  $isRequest?: boolean;
}>`
  display: grid;
  flex: 10;
  border-top: 1px solid ${props => props.theme.colors.darkGrey};
  border-right: 1px solid ${props => props.theme.colors.darkGrey};
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
`;

export const Label = styled.label<{ isSpacing?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${props => props.theme.colors.darkGrey};
  font-size: 14px;
  font-weight: bold;
  background-color: ${props => props.theme.colors.grey};
  min-height: max-content;
  min-width: 100px;
  max-width: 100px;
  word-spacing: ${props => props.isSpacing && "20px"};
`;
export const Subscription = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  padding: 5px 10px;
  white-space: pre-line;
  white-space: pre-wrap;
  input {
    border: 1px solid ${props => props.theme.colors.darkGrey};
  }
`;

export const Description = styled(Subscription)`
  align-items: flex-start;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const DisplaySign = styled.span<{ isReturn?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${props =>
    props.isReturn ? props.theme.colors.darkRed : props.theme.colors.black};
  align-items: center;
  text-align: center;
  text-transform: lowercase;
  font-family: "궁서체";
  font-weight: bold;
`;

export const DisplayUsers = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  gap: 5px;
  padding-left: 10px;
`;

export const DisplayName = styled.span`
  display: flex;
  justify-content: center;
  width: 70px;
`;
